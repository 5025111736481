import React,{useState,useRef} from 'react'
import RecordRTC from 'recordrtc';

import { Button } from '..'

const StopRecordingPopup = ({setisScreenSharing,setRecordingStopPopUp}) => {
  // -----------------ScreenRecording-----------------------------------------------------------------
  const [recording, setRecording] = useState(false);
  const [stream, setStream] = useState(null);
  const [recordedBlob, setRecordedBlob] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
//   const videoRef = useRef(null);
//   const webcamRef = useRef();
  const recorderRef = useRef(null);
  const [log, setLog] = useState([]);

  const startRecording = async () => {
      // setRecordingStopPopUp(false)
      try {
        const audioStream = await navigator.mediaDevices.getUserMedia({ audio: true });
        const screenStream = await navigator.mediaDevices.getDisplayMedia({ video: true });
  
        if (
          screenStream.getVideoTracks()[0].getSettings().displaySurface === 'monitor' ||
          screenStream.getVideoTracks()[0].label === 'Primary Monitor'
        ) {
          const fullStream = new MediaStream([...screenStream.getTracks(), ...audioStream.getTracks()]);
          setStream(fullStream);
          const recorder = RecordRTC(fullStream, {
            type: 'video',
            mimeType: 'video/webm',
          });
          recorder.startRecording();
          recorderRef.current = recorder;
          setRecording(true);
          addToLog('Started recording');
        } else {
          setShowPopup(true);
          console.log('stoped')
        }
  
        screenStream.getVideoTracks()[0].onended = () => {
          stopRecording();
          setShowPopup(true);
          setisScreenSharing(false)
          console.log('stoped')
        };
      } catch (error) {
        console.error('Error starting recording:', error);
        alert('Failed to start recording. Please try again.');
      }
    };
  
    const stopRecording = () => {
        // setScreenRecordingPermissions(false)
      if (recorderRef.current) {
        recorderRef.current.stopRecording(() => {
          const blob = recorderRef.current.getBlob();
          setRecordedBlob(blob);
          setRecording(false);
          downloadRecording2(blob)
          addToLog('Stopped recording');
        });
        if (stream) {
          stream.getTracks().forEach((track) => track.stop());
        }
      }
    };
  
    const downloadRecording2 = (recordedBlob) => {
      if (recordedBlob) {
        const url = URL.createObjectURL(recordedBlob);
        const a = document.createElement('a');
        const t = Date.now();
        a.href = url;
        a.download = `screen_recording${t}.webm`;
        a.click();
        URL.revokeObjectURL(url);
      }
    };
  
    const downloadLog = () => {
      const logText = log.join('\n');
      const blob = new Blob([logText], { type: 'text/plain' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'log.txt';
      a.click();
      URL.revokeObjectURL(url);
    };
  
    const addToLog = (message) => {
      const timestamp = new Date().toLocaleString();
      const logEntry = `${timestamp}: ${message}`;
      setLog((prevLog) => [...prevLog, logEntry]);
    };
  
    const handlePopupButtonClick = () => {
      setShowPopup(false);
      startRecording();
    };
  return (
    <div className="card flex flex-col justify-center items-center p-[50px] relative text-center w-[40%]">
            <>
                <p className='font-semibold text-xl mb-5 text-[#EA4335]'> Screen Recording Stoped !</p>
                <p className='font-semibold text-lg mb-5'>You have  stoped the screen sharing.</p>    
                <p className='text-[#919397] w-[75%]'>You cannot proceed without sharing your screen throughout the assessment.</p>

                <div className='flex mt-8 gap-5'>
                    <Button
                        text="Share Screen"
                        handleClick={startRecording}
                    />
                </div>
            </>        
    </div>
  )
}

export default StopRecordingPopup