export const ClassOptions = [
  { id: 1, label: "Class 1st", value: 1 },
  { id: 2, label: "Class 2nd", value: 2 },
  { id: 3, label: "Class 3rd", value: 3 },
  { id: 4, label: "Class 4th", value: 4 },
  { id: 5, label: "Class 5th", value: 5 },
  { id: 6, label: "Class 6th", value: 6 },
  { id: 7, label: "Class 7th", value: 7 },
  { id: 8, label: "Class 8th", value: 8 },
];

export const subjectsOptions = [
  { id: 1, label: "Maths", value: "maths", icon: "/img/math-icon.png" },
  { id: 2, label: "English", value: "english", icon: "/img/eng-icon.png" },
  {
    id: 3,
    label: "Science",
    value: "science",
    icon: "/img/science-icon.png",
  },
];
