export const deployment = "staging";

const envConfig = {
  //app
  appName: String(process.env.REACT_APP_NAME),
  appFullName: String(process.env.REACT_APP_FULL_NAME),
  appEmail: String(process.env.REACT_APP_EMAIL),
  appLink:
    deployment === "production"
      ? String(process.env.REACT_APP_URL_PRODUCTION)
      : deployment === "staging"
      ? String(process.env.REACT_APP_URL_STAGING)
      : String(process.env.REACT_APP_URL_LOCAL),

  //support
  // supportPhone: String(process.env.REACT_APP_SUPPORT_PHONE),
  supportEmail: String(process.env.REACT_APP_SUPPORT_EMAIL),

  // social links
  facebookLink: String(process.env.REACT_APP_FACEBOOK_LINK),
  twitterLink: String(process.env.REACT_APP_TWITTER_LINK),
  instagramLink: String(process.env.REACT_APP_INSTAGRAM_LINK),
  youtubeLink: String(process.env.REACT_APP_YOUTUBE_LINK),
  whatsappLink: String(process.env.REACT_APP_WHATSAPP_LINK),

  // store links
  // appStoreLink: String(process.env.REACT_APP_APP_STORE_LINK),
  googlePlayLink: String(process.env.REACT_APP_GOOGLE_PLAY_LINK),

  appUrl:
    deployment === "production"
      ? String(process.env.REACT_APP_URL_PRODUCTION)
      : deployment === "staging"
      ? String(process.env.REACT_APP_URL_STAGING)
      : String(process.env.REACT_APP_URL_LOCAL),

  // Backeng url - render
  backendUrl:
    deployment === "production"
      ? String(process.env.REACT_APP_BACKEND_URL_PRODUCTION)
      : deployment === "staging"
      ? String(process.env.REACT_APP_BACKEND_URL_STAGING)
      : String(process.env.REACT_APP_BACKEND_URL_LOCAL),

  ipInfoKey:
    deployment === "production"
      ? String(process.env.REACT_APP_IPINFO_KEY_LOCAL)
      : deployment === "staging"
      ? String(process.env.REACT_APP_IPINFO_KEY_STAGING)
      : String(process.env.REACT_APP_IPINFO_KEY_PRODUCTION),

  examDuration: Number(50),

  questionSheetId: String(process.env.REACT_APP_QUESTIONS_SHEET_ID), //sheets
  fetchQuestionsApi: String(process.env.REACT_APP_FETCH_QUESTIONS), //appscript
  emailApi: String(process.env.REACT_APP_EMAIL_API), //appscript
};

export default envConfig;
