import { Country, State, City } from "country-state-city";

export const allCountries = Country.getAllCountries().map((country) => ({
  id: country.isoCode,
  label: country.name,
  value: country.name,
}));

export const allStates = (country) =>
  State.getStatesOfCountry(country).map((state) => ({
    id: state.isoCode,
    label: state.name,
    value: state.name,
  }));

export const allCities = (country, state) =>
  City.getCitiesOfState(country, state).map((city) => ({
    id: city.name,
    label: city.name,
    value: city.name,
    stateCode: city.stateCode,
    countryCode: city.countryCode,
  }));
