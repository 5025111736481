export const participantDetailsfeilds = [
  {
    name: "name",
    label: "Participant Name",
    type: "text",
    placeholder: "Enter Participant Name",
    required: true,
  },
  {
    name: "dateOfBirth",
    label: "Date of Birth",
    type: "date",
    required: true,
  },
];

export const homeDetailsFields = [
  {
    name: "homeCountry",
    label: "Home Country",
    type: "text",
    required: true,
    placeholder: "eg. : India",
  },
  {
    name: "homeState",
    label: "Home State",
    type: "text",
    required: true,
    placeholder: "eg. : New Delhi",
  },
  {
    name: "homeCity",
    label: "Home City",
    type: "text",
    required: true,
    placeholder: "eg. : New Delhi",
  },
  {
    name: "homeAddress",
    label: "Home Address",
    type: "text",
    required: true,
    placeholder: "eg. : #123, Main Road",
  },
  {
    name: "homePincode",
    label: "Home Pincode",
    type: "text",
    required: true,
    placeholder: "eg. : 110001",
  },
];

export const schoolDetailsFields = [
  {
    name: "schoolId",
    label: "School ID",
    type: "text",
    placeholder: "eg. : NPS_7921_1fci",
  },
  {
    name: "schoolName",
    label: "School Name",
    type: "text",
    placeholder: "eg. : National Public School",
  },

  {
    name: "schoolCountry",
    label: "School Country",
    type: "text",
    placeholder: "eg. : India",
  },
  {
    name: "schoolState",
    label: "School State",
    type: "text",
    placeholder: "eg. : New Delhi",
  },
  {
    name: "schoolCity",
    label: "School City",
    type: "text",
    placeholder: "eg. : New Delhi",
  },
  {
    name: "schoolAddress",
    label: "School Address",
    type: "text",
    placeholder: "eg. : #123, Main Road",
  },
  {
    name: "schoolPincode",
    label: "School Pincode",
    type: "text",
    placeholder: "eg. : 110001",
  },
];
