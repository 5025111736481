import React, { useState } from "react";
import "./TextInput.css";
import { IoIosSearch } from "react-icons/io";

const TextInput = ({
  type,
  placeholderText,
  innerLabel,
  width,
  idName,
  inputName,
  inputValue,
  handleChange,
  inputRef,
  isRequired,
  selectOptions,
  textRows,
  accept,
  defaultValue,
  error,
}) => {
  const [passType, setPassType] = useState("password");
  return (
    <>
      {type === "select" ? (
        <select
          className={`${error} selectInput`}
          id={idName}
          name={inputName}
          required={isRequired}
          value={inputValue}
          onChange={handleChange}
          ref={inputRef}
          defaultValue={defaultValue}
        >
          <option className="text-[#C4C4C4] text-sm" value="" disabled>
            {placeholderText}
          </option>
          {selectOptions.map((selectOption) => {
            return (
              <option
                className="font-medium text-sm capitalize text-[#666]"
                key={selectOption}
                value={selectOption}
              >
                {selectOption}
              </option>
            );
          })}
        </select>
      ) : type === "textbox" ? (
        <textarea
          className={`${error} textArea`}
          rows={textRows}
          placeholder={placeholderText}
          required={isRequired}
          value={inputValue}
          onChange={handleChange}
          ref={inputRef}
        />
      ) : type === "file" ? (
        <div className="fileInputContainer">
          <label className="fileInputPlaceholder">{placeholderText}</label>
          <input
            className={`${error} fileInput`}
            name={inputName}
            type="file"
            style={{ width: `${width}` }}
            value={inputValue}
            onChange={handleChange}
            required={isRequired}
            accept={accept}
            ref={inputRef}
          />
        </div>
      ) : type === "password" ? (
        <div className="relative flex items-center">
          <input
            className={`${error} textInput`}
            name={inputName}
            type={passType}
            style={{ width: `${width}` }}
            placeholder={placeholderText}
            value={inputValue}
            onChange={handleChange}
            required={isRequired}
            defaultValue={defaultValue}
            ref={inputRef}
          />
          <img
            src={`/img/eye-${passType === "text" ? "open" : "close"}.png`}
            alt="eye"
            className="absolute w-[1.5rem] right-5 z-10 cursor-pointer"
            onClick={() =>
              passType === "password"
                ? setPassType("text")
                : setPassType("password")
            }
          />
        </div>
      ) : type === "search" ? (
        <div className="relative flex items-center">
          <input
            className={`${error} textInput pr-10`}
            name={inputName}
            type="text"
            style={{ width: `${width}` }}
            placeholder={placeholderText}
            value={inputValue}
            onChange={handleChange}
            required={isRequired}
            defaultValue={defaultValue}
            ref={inputRef}
          />
          <IoIosSearch className="absolute w-6 h-6 right-3 text-gray-600" />
        </div>
      ) : (
        <div
          className={`flex items-center w-full ${innerLabel ? "gap-1 bg-[#F5F5F5] rounded-lg px-3 text-sm" : ""
            }`}
        >
          {innerLabel}
          <input
            className={`${error} textInput`}
            name={inputName}
            type={type}
            style={{ width: `${width}` }}
            placeholder={placeholderText}
            value={inputValue}
            onChange={handleChange}
            required={isRequired}
            defaultValue={defaultValue}
            ref={inputRef}
          />
        </div>
      )}
      <div className="text-xs text-[#f00] mt-1">{error}</div>
    </>
  );
};

TextInput.defaultProps = {
  type: "text",
  idName: "",
  inputName: "",
  placeholderText: "",
  width: "100%",
  handleChange: () => { },
  isRequired: false,
  selectOptions: [],
};

export default TextInput;
