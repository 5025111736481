export const individualRegisterFormFields = [
  {
    name: "parentName",
    label: "Parent Name",
    type: "text",
    required: true,
    placeholder: "eg. : Sam Deo",
    colSpan: 2,
  },
  {
    name: "parentEmail",
    label: "Parent Email Id",
    type: "email",
    required: true,
    placeholder: "eg. : example123@mail.con",
    colSpan: 2,
  },
  {
    name: "parentPhone",
    label: "Parent Phone Number",
    type: "tel",
    required: true,
    placeholder: "eg. : 9876543210",
    colSpan: 2,
  },
  {
    name: "password",
    label: "Password",
    type: "password",
    required: true,
    placeholder: "Password",
    colSpan: 2,
  },
];
