import React from 'react'

const Logo = ({width}) => {
  return (
    <div className="logo flex justify-center items-center">
      <div className='py-2 px-6 bg-black text-white text-2xl font-semibold rounded-md'>
        Logo
      </div>
        {/* <img className={`w-[${width}]`} src="./img/logo_full.png" alt="logo" /> */}
    </div>
  )
}
Logo.defaultProps = {
  width:"10rem"
}

export default Logo
