export const customStyles = {
  control: (styles) => ({
    ...styles,
    width: "100%",
    borderRadius: "8px",
    border: "1px solid #c2c2c2",
    padding: "0 2px",
    fontSize: "14px",
    backgroundColor: "#F5F5F5",
    cursor: "pointer",
    ":hover": {
      outline: "none",
      // border: "none",
      boxShadow: "none",
    },
    ":active": {
      outline: "none",
      border: "none",
      boxShadow: "none",
    },
    ":focus": {
      outline: "none",
      border: "none",
      boxShadow: "none",
    },
  }),

  option: (styles, { isDisabled }) => {
    return {
      ...styles,
      color: "#292D32",
      fontSize: "14px",
      background: isDisabled ? "#fbc4ca" : "#fff",
      ":hover": {
        backgroundColor: isDisabled ? "#fbc4ca" : "#ddd",
        color: "#000",
        cursor: isDisabled ? "not-allowed" : "pointer",
      },
    };
  },

  menu: (styles) => {
    return {
      ...styles,
      width: "100%",
      borderRadius: "8px",
    };
  },

  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: "#929292",
      fontSize: "14px",
    };
  },
};
