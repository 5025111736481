import React from "react";
const Terms = () => {
  return (
    <>
      <div className="privacy-container mt-20">
        <div className="container">
          <h3>Terms and Conditions</h3>
          <p>
            Please read these terms and conditions carefully before using{" "}
            <b>Olympiad practice Application.</b> Using this app, you agree to
            be bound by these terms and conditions.
          </p>
          <h4>Use of the App</h4>
          <p>
            The Olympiad practice Application is designed for personal and
            non-commercial use only. You may not use the app for any illegal or
            unauthorized purpose. Using the app, you represent and warrant that
            you comply with all applicable laws and regulations.
          </p>
          <h4>User Content</h4>
          <p>
            The Olympiad practice Application allows users to take quizzes on
            already created content, including quiz questions and answers. By
            taking the quiz, you grant us a non-exclusive, royalty-free,
            transferable, sub-licensable, worldwide license to use, modify,
            reproduce, distribute, and display the content in connection with
            the app. You represent and warrant that you have the necessary
            rights to grant this license.
          </p>
          <h4>Intellectual Property</h4>
          <p>
            All content and materials included in the Olympiad practice
            Application, including but not limited to text, graphics, logos,
            images, and software, are the property of the Olympiad practice
            Application or its licensors and are protected by copyright and
            other intellectual property laws. You may not use, copy, modify,
            distribute, or reproduce any content or materials from the app
            without our prior written consent.
          </p>
          <h4>Disclaimer of Warranties</h4>
          <p>
            The Olympiad practice Application is provided on an "as is" and "as
            available" basis, without warranties of any kind, express or
            implied. We make no warranty that the app will meet your
            requirements or that it will be uninterrupted, timely, secure, or
            error-free. We are not responsible for any damage to your device or
            loss of data that results from using the app.
          </p>
          <h4>Limitation of Liability</h4>
          <p>
            To the extent permitted by law, we are not liable for any direct,
            indirect, incidental, special, consequential, or punitive damages
            arising from or related to the use of the Olympiad practice
            Application. This includes damages for loss of profits, goodwill,
            use, data, or other intangible losses.
          </p>
          <h4>Governing Law</h4>
          <p>
            These terms and conditions are governed by and constructed in
            accordance with the laws of India, without giving effect to any
            principles of conflicts of law.
          </p>
          <h4>Changes to These Terms and Conditions</h4>
          <p>
            We reserve the right to modify these terms and conditions at any
            time, without prior notice. Your continued use of the Olympiad
            practice Application following any changes to these terms and
            conditions constitutes your acceptance of the changes.
          </p>
          <h4>Termination</h4>
          <p>
            We reserve the right to terminate your use of the Olympiad practice
            Application at any time, without prior notice, for any reason,
            including but not limited to violating these terms and conditions.
          </p>
          <h4>Contact Us</h4>
          <p>
            If you have any questions or concerns about these terms and
            conditions, please contact us at
            <a href="mailto:olympiadpractice@naskay.in">
              olympiadpractice@naskay.in
            </a>
            .
          </p>
        </div>
      </div>
    </>
  );
};

export default Terms;
