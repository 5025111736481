import React from "react";
import "./checkbox.css";

const Checkbox = ({
  checkId,
  checkName,
  checked,
  checkValue,
  handleChange,
  labelText,
  isrequired,
}) => {
  return (
    <div className="checkBtn">
      <input
        className="hidden"
        type="checkbox"
        id={checkId}
        name={checkName}
        value={checkValue}
        onChange={handleChange}
        required={isrequired}
        checked={checked}
      />
      <label
        htmlFor={checkId}
        className="checkLabel font-bold text-base cursor-pointer"
      >
        {labelText}
      </label>
    </div>
  );
};

export default Checkbox;
