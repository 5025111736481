import Header from "../../landingPage/Header";
import Footer from "../../landingPage/Footer";
import { Outlet } from "react-router-dom";
import { useEffect } from "react";

const SchoolDashboardLayout = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <div>
      <Header placement="dashboard" dashboard="school" />
      <div className="mt-40">
        <Outlet />
      </div>
      <Footer />
    </div>
  );
};

export default SchoolDashboardLayout;
