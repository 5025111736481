import React, { useEffect, useRef } from "react";
import ContactForm from "./ContactForm";

const Examlanding = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const contactFormRef = useRef(null);
  const setFormRef = (ref) => {
    contactFormRef.current = ref;
  };

  return (
    <section className="exam-date-section ribbon-right">
      <div className="container exam-container">
        <h3 className="mb-0">
          Practido Online Olympiad Exam Dates, 2024{" "}
          <span>Academic Year 2024-25</span>
        </h3>
        <div className="d-flex pt-0 table-container">
          <div className="table-header-row">
            <div>Olympiad Exam</div>
            <div>Demo Test</div>
            <div>Exam Dates</div>
            <div>Exam Slots</div>
          </div>
          <div className="table-data-row">
            <div>PEO (Practido English Olympiad)</div>
            <div>Available 24x7</div>
            <div>Will be Announced in October, 2024</div>
            <div>--</div>
          </div>
          <div className="table-data-row">
            <div>PMO (Practido Mathematics Olympiad)</div>
            <div>Available 24x7</div>
            <div>Will be Announced in October, 2024</div>
            <div>--</div>
          </div>
          <div className="table-data-row">
            <div>PSO (Practido Science Olympiad)</div>
            <div>Available 24x7</div>
            <div>Will be Announced in October, 2024</div>
            <div>--</div>
          </div>
        </div>
      </div>

      <ContactForm contactFormRef={setFormRef} />
    </section>
  );
};

export default Examlanding;
