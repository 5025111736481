import React, { useEffect, useState } from "react";
import RichTextEditor from "rich-text-editor-for-react";
import useRichTextEditor from "rich-text-editor-for-react/hook";

// const toolbarAvailableOptions = [
//   "word_count",
//   "clear_format",
//   "undo",
//   "redo",
//   "font",
//   "header",
//   "bold",
//   "italic",
//   "underline",
//   "strikethrough",
//   "sub_script",
//   "super_script",
//   "text_color",
//   "highlight_color",
//   "numbered_list",
//   "bulleted_list",
//   "align",
//   "decrease_indent",
//   "increase_indent",
//   "direction",
//   "blockquote",
//   "code_block",
//   "link",
//   "image_base64",
//   "embed_video",
//   "format_media",
// ];

const RichTextInput = ({ toolbarOptions, setOut, defaultValue }) => {
  const { output, fetchOutput } = useRichTextEditor();

  // const [out, setOut] = useState("");
  useEffect(() => {
    setOut && setOut(output);
  }, [output, setOut]);

  return (
    <div className="rounded-[10px] overflow-hidden border-[1px] border-[#c2c2c2]">
      <RichTextEditor
        toolbarOptions={toolbarOptions}
        customizeUI={{
          backgroundColor: "#F5F5F5",
          primaryColor: "#666",
          iconColor: "#555",
          stickyToolbarOnScroll: false,
          defaultVisibleToolbarOptions: {
            phone: 5,
            tablet: 7,
            laptop: 10,
          },
        }}
        fetchOutput={fetchOutput}
        initialValue={defaultValue}
      />
    </div>
  );
};

export default RichTextInput;
