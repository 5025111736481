import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Header from "../../landingPage/Header";
import CommonBanner from "./CommonBanner";
import Footer from "../../landingPage/Footer";
import { useEffect, useState } from "react";
import { doc, getDoc, onSnapshot, query, where } from "firebase/firestore";
import {
  auth,
  parentsCollectionRef,
  schoolCollectionRef,
} from "../../../config/firebaseConfig";
import { onAuthStateChanged } from "firebase/auth";
const Onboarding = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const step = location.pathname.split("/")[2];

  const [parentDetails, setParentDetails] = useState(null);
  const [schoolDetails, setSchoolDetails] = useState(null);

  useEffect(() => {
    const fetchParentDetails = async () => {
      const uid = auth.currentUser.uid;
      const parentQuery = query(parentsCollectionRef, where("uid", "==", uid));
      try {
        onSnapshot(parentQuery, (snap) => {
          let data = snap.docs.map((doc) => {
            return { ...doc.data(), id: doc.id };
          });
          if (snap.empty) return navigate("/login");
          setParentDetails(data[0]);
        });
      } catch (error) {
        console.log(error);
      }
    };
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        fetchParentDetails();
      } else {
        navigate("/login");
      }
    });

    return () => unsubscribe(); // Cleanup when component unmounts
  }, [navigate]);

  useEffect(() => {
    const schoolId = parentDetails?.schoolId;
    const fetchSchoolData = async () => {
      const schoolData = await getDoc(doc(schoolCollectionRef, schoolId));
      setSchoolDetails({
        schoolId: schoolData.id,
        schoolName: schoolData.data().schoolName,
        schoolAddress: schoolData.data().schoolAddress,
        schoolLogoUrl: schoolData.data().schoolLogoUrl,
      });
    };

    schoolId && fetchSchoolData();
  }, [parentDetails]);

  return (
    <div>
      <Header placement="dashboard" dashboard="parent" />
      <CommonBanner />
      <section className="common-form px-3 mt-0">
        {schoolDetails && (
          <div
            className={`logo-container mb-4 ${step !== "step1" ? "flex" : ""}`}
          >
            <div className="school-logo-section">
              <div className="d-flex align-items-center gap-4">
                <div className="w-20 h-20 bg-gray-300 overflow-hidden flex items-center justify-center rounded-full">
                  {schoolDetails?.schoolLogoUrl ? (
                    <img
                      src={schoolDetails?.schoolLogoUrl}
                      alt="schoolLogo"
                      className="h-full w-full"
                    />
                  ) : (
                    <div className="w-full h-full bg-gray-600 flex items-center justify-center">
                      <span className="text-5xl font-bold text-white capitalize">
                        {schoolDetails?.schoolName[0]}
                      </span>
                    </div>
                  )}
                </div>
                <div className="school-details">
                  <div className="school-name">{schoolDetails?.schoolName}</div>
                  <div className="school-address">
                    {schoolDetails?.schoolAddress.address}
                    {", "}
                    {schoolDetails?.schoolAddress.city}
                    {", "}
                    {schoolDetails?.schoolAddress.state}
                    {", "}
                    {schoolDetails?.schoolAddress.country}{" "}
                    {schoolDetails?.schoolAddress.pin}
                  </div>
                </div>
              </div>
              <div>
                {/* <img width="200px" src="/img/new-offer.png" alt="" /> */}
              </div>
            </div>
          </div>
        )}
        <div className="">
          <div className="row onboarding-head mr-md-5">
            <div className="col-sm-12 item-left">
              {step !== "success" && (
                <>
                  <button
                    type="button"
                    className={`btn step-btn ${step === "step1" && "active"}`}
                  >
                    <span>1</span> Step 1
                  </button>
                  <img src="/img/less-then.png" alt="" />
                  <button
                    type="button"
                    className={`btn step-btn ${step === "step2" && "active"}`}
                  >
                    <span>2</span> Step 2
                  </button>
                </>
              )}
            </div>
          </div>
          <div>
            <Outlet />
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Onboarding;
