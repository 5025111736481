import React, { useEffect, useState } from "react";
import { Button, TextInput } from "../../../components";
import { Link } from "react-router-dom";
import {
  validateEmail,
  validatePassword,
  validatePhone,
} from "../../../assets/js/validation";
import {
  EmailAuthProvider,
  fetchSignInMethodsForEmail,
  linkWithCredential,
  sendPasswordResetEmail,
} from "firebase/auth";
import { auth, schoolCollectionRef } from "../../../config/firebaseConfig";
import envConfig from "../../../config/envConfig";
import { getDocs, query, where } from "firebase/firestore";
import { GAEventTrack } from "../../../hooks/GA_events";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [loading, setLoading] = useState(null);
  const [error, setError] = useState(null);
  const [emailSent, setEmailSent] = useState(false);
  const [emailNotFound, setEmailNotFound] = useState(false);
  const [usePhone, setUsePhone] = useState(false);
  const [showPasswordModule, setShowPasswordModule] = useState(false);

  const sendForgotPasswordEmail = async ({ resend }) => {
    try {
      setLoading((prev) => ({ ...prev, sendForgotPassEmail: true }));

      // Check if the email exists in Firebase authentication
      const userCredential = await fetchSignInMethodsForEmail(auth, email);
      if (userCredential.length === 0) {
        setError((prev) => ({
          ...prev,
          email: "User not found, Enter a valid email id.",
        }));
        setEmailNotFound(true);
        return;
      }

      // If the email exists, send a password reset email
      const continueAction = {
        url: `${envConfig?.appUrl}login`,
      };
      await sendPasswordResetEmail(auth, email, continueAction);
      //GA event tracking
      GAEventTrack({
        event: resend ? "pass_reset_email_resent" : "pass_reset_email_sent",
        data: {
          email: email,
        },
      });
      setEmailSent(true);
    } catch (error) {
      console.log(error);
      setError((prev) => ({ ...prev, other: error.message }));
    } finally {
      setLoading((prev) => ({ ...prev, sendForgotPassEmail: false }));
    }
  };

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    if (!email || !validateEmail(email)) {
      setError((prev) => ({ ...prev, email: "Please enter a valid email" }));
      return;
    }
    //GA event tracking
    GAEventTrack({
      event: "forgot_pass_submit",
      data: { email: email },
    });

    await sendForgotPasswordEmail({ resend: false });
  };

  const handlePhoneSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    if (!phone || !validatePhone(phone)) {
      setError((prev) => ({ ...prev, phone: "Please enter a valid phone" }));
      return;
    }
    const phoneNumber = `91${phone}`;

    //1.  find user with this phone number
    const q = query(
      schoolCollectionRef,
      where("schoolPhone", "==", phoneNumber)
    );

    let userData;
    setLoading((prev) => ({ ...prev, phoneSubmit: true }));
    const querySnapshot = await getDocs(q);
    if (querySnapshot.empty) {
      setError((prev) => ({
        ...prev,
        phone: "User not found, Enter a valid phone number.",
      }));
      return;
    } else {
      querySnapshot.docs.forEach((doc) => {
        userData = doc.data();
      });
    }
    //2. find email id of the user
    setEmail(userData?.schoolEmail);

    // 3. show set password module
    setShowPasswordModule(true);
    setLoading((prev) => ({ ...prev, phoneSubmit: false }));
  };
  const handleSetPassword = async (e) => {
    e.preventDefault();
    setError(null);
    const formData = e.target;
    const password = formData.pass.value;
    const rePassword = formData.repass.value;

    // validate password - length, match
    if (!validatePassword(password)) {
      setError((prev) => ({
        ...prev,
        password: "Please fill a strong password with the below conditions",
      }));
      console.log("password error");
      return;
    }
    if (password !== rePassword) {
      return setError((prev) => ({
        ...prev,
        repassword: "Passwords do not match",
      }));
    }

    try {
      setLoading((prev) => ({ ...prev, passwordSubmit: true }));

      // 1. create credential with email and password
      const emailCredential = EmailAuthProvider.credential(email, password);
      // 2. Link the phone authentication credential to the email/password account
      await linkWithCredential(auth.currentUser, emailCredential);
      //
    } catch (error) {
      console.log(error);
      setError((prev) => ({
        ...prev,
        other: error,
      }));
    } finally {
      setLoading((prev) => ({ ...prev, passwordSubmit: false }));
    }
  };

  useEffect(() => {
    setError(null);
  }, [usePhone]);

  return (
    <div className="form-data step-1">
      <h3>Forgot Password</h3>

      {emailSent ? (
        <div className="space-y-10">
          <div className="flex items-center justify-center gap-4 w-full bg-green-100 p-2 rounded-xl">
            <img src="/img/success.png" alt="" className="w-12 h-12" />

            <p className="!font-semibold !text-lg text-center">Email sent.</p>
          </div>
          <div className="space-y-6">
            <h1 className="text-2xl font-bold">Reset your password</h1>
            <div className="space-y-2">
              <p>
                We have sent a password reset link to your email. <br />
                You can reset your password by clicking on the link in the email
                we sent to : <br />
                <span className="!font-semibold">
                  {email.split("@")[0]?.slice(0, 3)}
                  {"•".repeat(email.split("@")[0].length - 3)}@
                  {email.split("@")[1]}
                </span>
              </p>
            </div>

            <Button
              version="secondary"
              text="Resend Email"
              width="200px"
              handleClick={() => sendForgotPasswordEmail({ resend: true })}
              loading={loading?.sendForgotPassEmail}
            />
            <p>
              Not the correct email?{" "}
              <span
                className="text-[#009DFF] font-semibold cursor-pointer hover:underline"
                onClick={() => setEmailSent(false) && setEmailNotFound(false)}
              >
                Go Back
              </span>
            </p>
          </div>
        </div>
      ) : showPasswordModule ? (
        <form onSubmit={(e) => handleSetPassword(e)} className="w-full mt-10">
          <div>
            <label className="text-sm font-semibold" htmlFor="email">
              Email
            </label>
            <span className="block font-bold text-base">{email}</span>
          </div>
          <div>
            <label className="text-sm font-semibold" htmlFor="pass">
              Enter your Password*
            </label>
            <TextInput
              type="password"
              idName="pass"
              inputName="pass"
              placeholderText="• • • • • • • •"
              error={error?.password}
              isRequired={true}
            />
          </div>
          <div>
            <label className="text-sm font-semibold" htmlFor="repass">
              Confirm your Password*
            </label>
            <TextInput
              type="password"
              idName="repass"
              inputName="repass"
              placeholderText="• • • • • • • •"
              error={error?.repassword}
              isRequired={true}
            />
          </div>

          {/* {error && <div className="text-red-500">{error?.other}</div>} */}
          <Button
            version="secondary"
            text="Submit"
            type="submit"
            loading={loading?.passwordSubmit}
          />
        </form>
      ) : (
        <form
          onSubmit={usePhone ? handlePhoneSubmit : handleEmailSubmit}
          className="w-full mt-10"
        >
          {emailNotFound && (
            <div className="flex items-center justify-center gap-4 w-full bg-red-100/80 p-3 rounded-xl mb-4">
              <img src="/img/notworking.png" alt="" className="w-10 h-10" />
              <div>
                <p className="!font-semibold text-center !text-red-500">
                  User not found, Please Enter a valid email id.
                </p>
                {/*   */}
              </div>
            </div>
          )}
          {usePhone ? (
            <div className="form-group">
              <label htmlFor="phone">
                Phone Number <span> * </span>
              </label>
              <TextInput
                type="tel"
                placeholderText="Enter Phone No."
                inputName="phone"
                value={phone}
                handleChange={(e) => setPhone(e.target.value)}
                error={error?.phone}
              />
              <div
                onClick={() => setUsePhone(false)}
                className="text-[#009DFF] hover:text-[#009DFF] font-bold cursor-pointer text-right"
              >
                Find with Email Id
              </div>
            </div>
          ) : (
            <div className="form-group">
              <label htmlFor="email">
                Email Id <span> * </span>
              </label>
              <TextInput
                type="email"
                placeholderText="Enter Email Id"
                inputName="email"
                value={email}
                handleChange={(e) => setEmail(e.target.value)}
                error={error?.email}
              />
            </div>
          )}
          {error?.other && (
            <div className="text-[#f00] text-xs mb-1 ml-4">{error?.other}</div>
          )}

          <div className="flex flex-col gap-4 col-md-12">
            <div className="">
              <Button
                version="secondary"
                text="Submit"
                type="submit"
                loading={
                  usePhone ? loading?.phoneSubmit : loading?.sendForgotPassEmail
                }
              />
            </div>

            <Link
              to="/login"
              className="mt-4 text-lg font-bold hover:underline cursor-pointer text-[#009DFF] hover:text-[#009DFF] w-fit"
              onClick={() =>
                GAEventTrack({
                  event: "forgot_pass_back_btn",
                })
              }
            >
              Back to Login
            </Link>
          </div>
        </form>
      )}
    </div>
  );
};

export default ForgotPassword;
