import React, { forwardRef } from 'react';

const MointerStudent = forwardRef((props, ref) => {

  return (
    <section ref={ref} className="moniter-time">
      <div className="container">
        <h2>Monitor Student Performance in Real-Time <span className="header-text">Track student progress with our intuitive dashboard and detailed analysis reports, allowing for timely interventions and support.</span>
        </h2>
        <div className="inner-container">
          <div className="text-center img-box">
            <img src="/img/school-home/innter-center.png" alt="monitiring" />
          </div>
          <div className='col-md-12 card-row'>
            <div className="card-animation" data-aos="fade-right" data-aos-delay="100">
              <div className="innter-card card-1">
                <div className="innter-box">
                  <p><span>International Standings</span>: Understand how your students perform on international level with competitive ranking features.</p>
                </div>
              </div>
            </div>

            <div className="card-animation" data-aos="fade-left" data-aos-delay="150">
              <div className="innter-card right-side-card">
                <div className="innter-box">
                  <p><span>Intuitive Dashboard</span>: Monitor student enrollments and track their progress effortlessly with a user-friendly interface.</p>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-12 card-row row-margin'>
            <div className="card-animation" data-aos="fade-right" data-aos-delay="200">
              <div className="innter-card bottom-left-card">
                <div className="innter-box">
                  <p><span>International Standings</span>: Understand how your students perform on international level with competitive ranking features.</p>
                </div>
              </div>
            </div>
            <div className="card-animation" data-aos="fade-left" data-aos-delay="250">
              <div className="innter-card bottom-right-card">
                <div className="innter-box">
                  <p><span>Intuitive Dashboard</span>: Monitor student enrollments and track their progress effortlessly with a user-friendly interface.</p>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </section>

  )
});

export default MointerStudent