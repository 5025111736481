import React, { useEffect, useRef } from "react";
import ContactForm from "./ContactForm";

const Awardslanding = () => {
  const contactFormRef = useRef(null);
  const setFormRef = (ref) => {
    contactFormRef.current = ref;
  };

  const studentsAwardsData = [
    {
      rank: "1st Rank holders",
      grades: "1-8",
      awards:
        "10,000 gift + Gold Medal + Physical and e-certificate + E-report.",
    },
    {
      rank: "2nd Rank holders",
      grades: "1-8",
      awards:
        "5,000 gift + Silver Medal + Physical and e-certificate + E-report",
    },
    {
      rank: "3rd Rank holders",
      grades: "1-8",
      awards:
        "3,000 gift + Bronze Medal + Physical and e-certificate + E-report",
    },
    {
      rank: "Top 5% students",
      grades: "1-8",
      awards:
        "Medal of Excellence + Physical and E-certificate of Excellence + E-report",
    },
    {
      rank: "All participants",
      grades: "1-8",
      awards:
        "All participants will be awarded Participation E-Certificates + E-report",
    },
  ];

  const schoolAwardsData = [
    {
      rank: "Top 5 Principals",
      awards:
        "Cash Prize of 10,000 + Trophy & Certificate of Excellence with frame",
    },
    {
      rank: "Top 5 Schools",
      awards:
        "Cash Prize of <amount> + Trophy & Certificate of Excellence with frame",
    },
  ];

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <section className="exam-date-section ribbon-right ribbon-left">
      <div className="container exam-container">
        <h3>
          Key Pointers for Practido Online Olympiad 2024 Awards{" "}
          <span>Starting Academic Year 2024-25</span>
        </h3>
        <p className="big">
          For participants appearing for PMO, PSO, PEO (classes 1 to 8), the
          awards and recognition are as follows:
        </p>
        <ul className="bullet-list-data">
          <li>
            Overall Top 3 participants in each subject from classes 1 to 8 will
            be awarded with Cash prize, Medals, Certificate and E-report.
          </li>
          <li>
            Top 5% participants will be awarded with a Medal, Certificate of
            Excellence, and E-report.
          </li>
          <li>
            All participants will be awarded with the Participation
            E-Certificates, and E-report.
          </li>
          <li>Schools receive a Trophy for Academic Excellence.</li>
          <li>
            Top 5 Principals and teachers are awarded cash prizes and
            appreciation awards.
          </li>
        </ul>
        <h3 className="left">
          Practido Online Olympiads 2024 Awards for Students
          <span>Awards for Students Appearing Online</span>
        </h3>
        <div className="d-flex pt-0 table-container">
          <div className="table-header-row">
            <div>Sr. No.</div>
            <div>Ranking / Score</div>
            <div>Grades</div>
            <div>Awards</div>
          </div>
          {studentsAwardsData?.map((item, index) => (
            <div className="table-data-row" key={index}>
              <div>{index + 1}</div>
              <div>{item?.rank}</div>
              <div>Grades {item?.grades}</div>
              <div>{item?.awards}</div>
            </div>
          ))}
        </div>
        <h3 className="left">
          Practido Online Olympiads 2024 Awards for Schools
          <span>
            These benefits only be given if at least 50 students signup from
            particular school
          </span>
        </h3>
        <div className="d-flex pt-0 table-container">
          <div className="table-header-row">
            <div>Sr. No.</div>
            <div>Ranking / Score</div>
            <div>Awards</div>
          </div>
          {schoolAwardsData?.map((item, index) => (
            <div className="table-data-row">
              <div>index</div>
              <div>{item?.rank}</div>
              <div>{item?.awards}</div>
            </div>
          ))}
        </div>
        <h3 className="left">
          Ranking Criteria for Practido Online Olympiads 2024{" "}
          <span>
            Ranking Criteria for Practido Online Olympiads for classes 2 to 8 is
            as follows:
          </span>
        </h3>
        <p>The ranks will be given on the following criteria:</p>
        <ul className="bullet-list-data">
          <li>Total marks obtained in the exam.</li>
          <li>
            In case of a tie, the student completing the test in lesser time
            will be ranked higher.
          </li>
          <li>
            <span className="red">
              In case of a tie again, the younger student based on Date of Birth
              will be ranked higher.
            </span>
          </li>
          <li>
            Since Practido Online Olympiads will be held on different days and
            sessions with different sets of question papers, final ranks will be
            announced based on normalization of the ranks for each session.
          </li>
          <li>
            In case two or more students score same marks under the criteria
            above, they will be awarded the same rank.
          </li>
          <li>
            In case of any confusion, decision of the Academic Council will be
            final & binding.
          </li>
        </ul>
        <h3 className="left">
          <span>
            Ranking Criteria for Practido Online Olympiads for classes 1 is as
            follows:
          </span>
        </h3>
        <p>
          As Practido Online Olympiads for class 1 is a stepping stone to
          further competitive exams for the little stars, we are keeping this
          exam parent/teacher assisted where they can guide the child in
          understanding the question based on which the child can answer it.
          Since it is a parent/teacher assisted exam, there would be no ranks
          given to the children. Although, the students who achieve more than
          60% in the exam will receive a Merit Certificate and Medal delivered
          to his/her home/school address. Also, all the students will get a
          digital Participation Certificate, which can be downloaded from the
          Results section on the dashboard.
        </p>
      </div>

      <ContactForm contactFormRef={setFormRef} />
    </section>
  );
};

export default Awardslanding;
