import React from "react";
import { Link } from "react-router-dom";

const PageNotFound = () => {
  return (
    <div className="h-screen w-full flex flex-col gap-10 items-center justify-center">
      <img src="/img/error.png" alt="404" />
      <div className="space-y-2 text-center text-gray-600">
        <h1 className="text-4xl font-bold">404 - Page Not Found</h1>
        <p className=" text-base">
          The page you are looking for does not exist.
        </p>
      </div>
      <Link to="/" className="text-base font-bold text-[#009DFF]">
        Back to Home
      </Link>
    </div>
  );
};

export default PageNotFound;
