import React from "react";
// import { motion } from 'framer-motion'
import { LuLoader2 } from "react-icons/lu";

import "./Button.css";

const Button = ({
  className,
  text,
  children,
  type,
  isDisabled,
  version,
  handleClick,
  inputId,
  display,
  width,
  loading,
}) => {
  return (
    <button
      className={`btn btn-${version} ${className} space-x-1`}
      type={type}
      disabled={isDisabled || loading}
      onClick={handleClick}
      style={{ display: `${display}`, minWidth: `${width}` }}
      id={inputId}
    >
      {text && <span>{text}</span>} {children && <span>{children}</span>}
      {loading && (
        <span>
          <LuLoader2 className="animate-spin" />
        </span>
      )}
    </button>
  );
};

Button.defaultProps = {
  text: "",
  version: "primary",
  type: "button",
  isDisabled: false,
  inputId: "",
  handleClick: () => {},
  display: "flex",
  width: "fit-contact",
  loading: false,
};

export default Button;
