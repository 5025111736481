import React, { useEffect, useState } from "react";
import { Button } from "../../../components";
import { Link, useNavigate } from "react-router-dom";
import {
  auth,
  parentsCollectionRef,
  participantsCollectionRef,
  schoolCollectionRef,
} from "../../../config/firebaseConfig";
import { doc, getDoc, onSnapshot, query, where } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import StudentCard from "./StudentCard";
import SchoolLogo from "../school/SchoolLogo";
import { IoWarningOutline } from "react-icons/io5";
import { sendVerificationEmail } from "../dashboardFunctions";
import { LuLoader2 } from "react-icons/lu";
import DashboardInstructions from "./DashboardIns";
import { PDFDownloadLink } from "@react-pdf/renderer";
import PDFContentParent from "../pdf/PDFContentParent";
import { GAEventTrack } from "../../../hooks/GA_events";
import StudentLoadingCard from "./StudentLoadingCard";

const ParentDashboard = () => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const [emailVerified, setEmailVerified] = useState();
  const [parentsDetails, setParentsDetails] = useState(null);
  const [students, setStudents] = useState(null);
  const [schoolDetails, setSchoolDetails] = useState(null);
  const [loading, setLoading] = useState(null);
  const [dataLoading, setDataLoading] = useState(null);
  const [verificationEmailSent, setVerificationEmailSent] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchParentDetails = async () => {
      const uid = auth.currentUser.uid;
      const parentQuery = query(parentsCollectionRef, where("uid", "==", uid));
      const participantsQuery = query(
        participantsCollectionRef,
        where("parentUid", "==", uid)
      );
      try {
        setDataLoading((prev) => ({ ...prev, parent: true }));
        onSnapshot(parentQuery, (snap) => {
          if (snap.empty) return navigate("/login");
          let data = snap.docs.map((doc) => {
            return { ...doc.data(), id: doc.id };
          });
          setParentsDetails(data[0]);
        });
      } catch (error) {
        console.log(error);
        setError((prev) => ({ ...prev, details: error }));
      } finally {
        setDataLoading((prev) => ({ ...prev, parent: false }));
      }

      try {
        setDataLoading((prev) => ({ ...prev, student: true }));
        onSnapshot(participantsQuery, (snap) => {
          let data = snap.docs.map((doc) => {
            return { ...doc.data(), id: doc.id };
          });
          setStudents(data);
        });
      } catch (error) {
        console.log(error);
      } finally {
        setDataLoading((prev) => ({ ...prev, student: false }));
      }
    };

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserData(user);
        user?.emailVerified
          ? setVerificationEmailSent(true)
          : setEmailVerified(false);
        fetchParentDetails();
      } else {
        navigate("/login");
      }
    });

    return () => unsubscribe(); // Cleanup when component unmounts
  }, [navigate]);

  useEffect(() => {
    const schoolId = parentsDetails?.schoolId;
    const fetchSchooolDetails = async () => {
      const schoolData = await getDoc(doc(schoolCollectionRef, schoolId));
      setSchoolDetails({
        schoolId: schoolData.id,
        ...schoolData.data(),
      });
    };

    schoolId && fetchSchooolDetails();
  }, [parentsDetails]);

  return (
    <div className="container py-12 space-y-6">
      {emailVerified === false && (
        <div className="bg-[#FFDBD2] text-[#FF0707] p-3 flex gap-2 items-center justify-center rounded-xl">
          <IoWarningOutline className="w-5 h-5 " />
          <span className="text-base">
            Please verify your email address to unlock more features and
            services. We've sent a verification link to your registered email.
          </span>
          {verificationEmailSent ? (
            <span className="font-bold">✓ Sent</span>
          ) : (
            <button
              className="font-extrabold hover:underline flex items-center gap-2"
              onClick={() => {
                sendVerificationEmail({
                  actionUrl: "parentDashboard",
                  setLoading: setLoading,
                  setVerificationEmailSent: setVerificationEmailSent,
                });
                //GA event tracking
                GAEventTrack({
                  event: "parent_dashboard_verif_email_sent",
                  data: {
                    uid: auth?.currentUser?.uid,
                    email: auth?.currentUser?.email,
                  },
                });
              }}
            >
              Resend Link
              {loading?.resend && (
                <span>
                  <LuLoader2 className="animate-spin" />
                </span>
              )}
            </button>
          )}
        </div>
      )}
      <div className="parents-dashbaord-content space-y-4">
        {!userData || dataLoading.parent ? (
          <div className="col-span-2 flex items-center justify-center gap-5">
            <div className="bg-gray-400 w-20 h-20 overflow-hidden flex items-center justify-center rounded-full flex-shrink-0">
              <div className="w-full h-full bg-gray-400 animate-pulse flex items-center justify-center"></div>
            </div>

            <div className="flex flex-col gap-1">
              <div className="w-48 h-8 bg-gray-300 rounded-lg animate-pulse" />
              <div className="w-32 h-4 bg-gray-300 rounded-lg animate-pulse" />
              <div className="w-40 h-4 bg-gray-300 rounded-lg animate-pulse" />
            </div>
          </div>
        ) : (
          parentsDetails?.schoolId && (
            <div>
              <SchoolLogo schoolDetails={schoolDetails} />
            </div>
          )
        )}

        <h3>PRACTIDO Online Olympiad 2024 Participant Information</h3>
        <div className="flex gap-4 flex-wrap">
          {!userData || dataLoading.parent || dataLoading.student ? (
            <>
              <StudentLoadingCard />
              <StudentLoadingCard />
            </>
          ) : (
            students?.map((student) => (
              <StudentCard student={student} key={student.id} />
            ))
          )}
        </div>
        <div>
          <Button
            text={`Add ${students?.length ? "More" : ""} Participants`}
            version="secondary"
            isDisabled={emailVerified === false}
            handleClick={() => {
              navigate("/onboarding/step1");
              //GA event tracking
              GAEventTrack({
                event: "add_participant_btn",
                data: {
                  uid: auth?.currentUser?.uid,
                  email: auth?.currentUser?.email,
                },
              });
            }}
          />
          {emailVerified === false && (
            <p className="text-sm text-[#FF0707] p-2">
              Verify your email id to add participants.
            </p>
          )}
        </div>
      </div>
      <div className="dash-blue-card gap-4">
        <div className="col-md-6 w-full bg-[#DBF2F7] rounded-xl p-4 flex flex-col gap-1 justify-center">
          <h3 className="text-lg font-bold">Mark Your Calendars!</h3>
          <p className="font-medium">
            The Practido Online Olympiad exam is happening on{" "}
            <strong>[Date of Exam]</strong>.
          </p>
          <Link
            to="/examlanding"
            // target="_blank"
            className="bg-white p-2 px-4 rounded-full font-semibold w-fit hover:no-underline"
            style={{ boxShadow: "0px 3px 0px #DFDFDF" }}
          >
            Preview Exam Details
          </Link>
        </div>
        <div className="col-md-6 w-full bg-[#DBF2F7] rounded-xl p-4 flex flex-col gap-1 justify-center">
          <p className="text-base font-medium">
            Ensure a smooth Olympiad experience! Download the Information PDF
            for your child's competition details.
          </p>

          <button
            className="bg-white p-2 px-4 rounded-full font-semibold w-fit"
            style={{ boxShadow: "0px 3px 0px #DFDFDF" }}
            onClick={() => {
              //GA event tracking
              GAEventTrack({
                event: "parent_dshbrd_download_pdf",
                data: {
                  uid: auth?.currentUser?.uid,
                  email: auth?.currentUser?.email,
                },
              });
            }}
          >
            <PDFDownloadLink
              document={
                <PDFContentParent schoolDetails={schoolDetails || null} />
              }
              fileName="Practido-Olympiad-Instructions.pdf"
            >
              {({ blob, url, loading, error }) =>
                loading ? "Loading ..." : "Click here to download the PDF"
              }
            </PDFDownloadLink>
          </button>
        </div>
      </div>

      <>
        <DashboardInstructions placement="parent" />
      </>
    </div>
  );
};

export default ParentDashboard;
