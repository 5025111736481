import React, { useEffect, useState } from "react";
import Success from "./PaymentSuccess";
import CountDown from "./CountDown";
// import Instructions from "./Instructions";
// import { onSnapshot, query, where } from "firebase/firestore";
// import {
//   auth,
//   participantsCollectionRef,
// } from "../../../config/firebaseConfig";
import { Button } from "../../../components";
import { useNavigate } from "react-router-dom";
// import { useAuthState } from "react-firebase-hooks/auth";

const Step4 = () => {
  // const [userAuth] = useAuthState(auth);
  // const [participants, setParticipants] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  // useEffect(() => {
  // const fetchParticipants = async () => {
  //   const currentUserParticipants = query(
  //     participantsCollectionRef,
  //     where("parentUid", "==", userAuth.uid)
  //   );
  //   try {
  //     onSnapshot(currentUserParticipants, (snap) => {
  //       let data = snap.docs.map((doc) => {
  //         return { ...doc.data(), id: doc.id };
  //       });
  //       const transformedArray = data.reduce((acc, curr) => {
  //         const existingEntry = acc.find((item) => item?.name === curr?.name);

  //         if (existingEntry) {
  //           existingEntry.subject.push(curr.subject);
  //           existingEntry.ids.push(curr.id);
  //         } else {
  //           acc.push({
  //             name: curr?.name,
  //             class: curr.class,
  //             subject: [curr.subject],
  //             ids: [curr.id],
  //             paymentDetails: curr.paymentDetails,
  //           });
  //         }
  //         return acc;
  //       }, []);
  //       setParticipants(transformedArray);
  //     });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  // userAuth && fetchParticipants();
  // }, [userAuth]);
  return (
    <div className="flex flex-col gap-8">
      <div className="container">
        <Success type="details" />
      </div>
      <div className="flex items-center justify-center">
        <Button
          version="secondary"
          text="Done"
          handleClick={() => navigate("/parentDashboard")}
        />
      </div>

      <div className="detail-section instructions-section">
        {/* <CountDown date={"October 1, 2024 10:00:00"} /> */}
        {/* <Instructions /> */}
      </div>
    </div>
  );
};

export default Step4;
