import React, { useState, useRef, useCallback } from "react";
import Webcam from "react-webcam";
import { auth, storage } from "../../../config/firebaseConfig";
import { getDownloadURL, ref, uploadString } from "firebase/storage";
import { GAEventTrack } from "../../../hooks/GA_events";
import { Button } from "../../../components";
import { FiCamera } from "react-icons/fi";

const ImageCapture = ({
  setImageCaptureModule,
  imageCaptureModule,
  participantModuleIndex,
  updateUrl,
  updateRef,
  setUploadImage,
  setLoading,
  participantName,
}) => {
  const webcamRef = useRef(null);
  const [error, setError] = useState("");

  const capturePhoto = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    imageCaptureModule !== "idProof" && setUploadImage(imageSrc);
    setImageCaptureModule(false);
    uploadImageHandler(imageSrc);
  }, [webcamRef]);

  const uploadImageHandler = async (imageSrc) => {
    const uniqueId = (Math.random() + 1).toString(36).substring(4);
    if (imageSrc === null) return;
    try {
      setLoading(true);
      const imageRef =
        imageCaptureModule === "idProof"
          ? ref(
              storage,
              `participant_files/${participantName.replace(
                " ",
                "_"
              )}_idProof_${uniqueId}`
            )
          : ref(
              storage,
              `participant_files/${participantName.replace(
                " ",
                "_"
              )}_displayPicture_${uniqueId}`
            );

      imageCaptureModule === "idProof"
        ? updateRef("idProof", imageRef, participantModuleIndex)
        : updateRef("image", imageRef, participantModuleIndex);

      const snapshot = await uploadString(imageRef, imageSrc, "data_url");
      const url = await getDownloadURL(snapshot.ref);
      imageCaptureModule === "idProof"
        ? updateUrl("idProof", url, participantModuleIndex)
        : updateUrl("image", url, participantModuleIndex);

      //GA event tracking
      GAEventTrack({
        event:
          imageCaptureModule === "idProof"
            ? "idproof_capture"
            : "image_capture",
        data: {
          uid: auth?.currentUser?.uid,
          email: auth?.currentUser?.email,
        },
      });
    } catch (error) {
      setError(error.message);
    }
    setLoading(false);
  };

  return (
    <div className="img-capture-modal relative max-w-1/3 bg-white rounded-2xl p-4 px-8 flex flex-col gap-5">
      <div
        className="absolute top-2 right-4 flex items-center justify-center cursor-pointer text-2xl font-bold"
        onClick={() => setImageCaptureModule(false)}
      >
        ×
      </div>
      <div className="text-center">
        <p className=" font-semibold text-lg">
          {imageCaptureModule === "idProof"
            ? "Capture ID Proof"
            : "Capture Image"}
        </p>
        {participantModuleIndex ? (
          <p className="text-sm">
            ( For Participant {participantModuleIndex + 1} )
          </p>
        ) : null}
      </div>
      <div className="capture-body w-[400px] h-[400px] rounded-xl overflow-hidden">
        <div className="relative">
          <div className="camera-body absolute z-0 w-[400px] h-[400px] top-0 left-0 flex flex-col gap-4 items-center justify-center bg-[#222]">
            <FiCamera className="w-32 h-32 text-[#444]" />
          </div>
          <div className="absolute z-10">
            <Webcam
              audio={false}
              ref={webcamRef}
              screenshotFormat="image/png"
              videoConstraints={{
                height: "400",
                width: "400",
                facingMode: "user",
              }}
            />
          </div>
        </div>
      </div>

      <div className="flex justify-center">
        <Button version="secondary" handleClick={capturePhoto}>
          <FiCamera className="w-8 h-8" />
        </Button>
      </div>
    </div>
  );
};

export default ImageCapture;
