export const loginFormFields = [
  {
    name: "email",
    label: "Email Id",
    type: "email",
    required: true,
    placeholder: "example123@mail.con",
  },

  {
    name: "password",
    label: "Password",
    type: "password",
    required: true,
    placeholder: "• • • • • • • •",
  },
];
