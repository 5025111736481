import axios from "axios";
import envConfig from "../config/envConfig";

export const sendEmail = async ({ emailId, subject, emailBody }) => {
  const data = JSON.stringify([emailId, subject, emailBody]);
  const mailApi = envConfig.emailApi;
  try {
    await axios.post(mailApi, data);
    return "email sent";
  } catch (error) {
    throw error;
  }
};
