import React from "react";

const StudentLoadingCard = () => {
  return (
    <div
      className="bg-white p-6 rounded-xl mb-4 w-[320px]"
      style={{ boxShadow: "0px 3px 0px #DFDFDF" }}
    >
      <div className="flex gap-3 border-b-2 border-gray-300 pb-3">
        <img
          src="/img/profile.png"
          alt=""
          className="w-16 h-16 rounded-xl animate-pulse"
        />
        <div className="flex flex-col justify-evenly text-base">
          <div className="w-40 h-5 bg-gray-300 rounded-lg animate-pulse" />
          <div className="w-32 h-5 bg-gray-300 rounded-lg animate-pulse" />
        </div>
      </div>
      <div className="pt-4 text-base space-y-3">
        <div className="flex flex-col gap-2">
          <div className="w-24  h-5 bg-gray-300 rounded-lg animate-pulse" />
          <div className="w-32 h-5 bg-gray-300 rounded-lg animate-pulse" />
        </div>
        <div className="flex flex-col gap-2">
          <div className="w-32  h-5 bg-gray-300 rounded-lg animate-pulse" />
          <div className="w-40 h-5 bg-gray-300 rounded-lg animate-pulse" />
        </div>
      </div>
    </div>
  );
};

export default StudentLoadingCard;
