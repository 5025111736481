import React, { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import { Button } from "../../../components";

const OtpModule = ({ phone, handleSubmit, sendOtp, loading, otpError }) => {
  const [otp, setOtp] = useState("");

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const [timer, setTimer] = useState(60);
  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((prev) => prev > 0 && prev - 1);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const handleResend = async () => {
    try {
      await sendOtp(phone, "resend");
      setTimer(60);
    } catch (error) {
      console.log("Failed to resend OTP", error?.message);
    }
  };

  return (
    <form
      onSubmit={(e) => handleSubmit(e, otp)}
      className="otp-wrapper wid-100 relative flex flex-col gap-3"
    >
      <h3>Please Enter the One Time Password to Verify your Account</h3>
      <p>The code has been sent to *******{phone?.slice(-4)}</p>
      <div className="w-full otp-main-card">
        <div className="flex-1 form-group otp-card">
          <OtpInput
            value={otp}
            onChange={setOtp}
            numInputs={6}
            renderSeparator={<span>&nbsp;&nbsp;&nbsp;</span>}
            shouldAutoFocus
            className=""
            renderInput={(props) => <input className="!px-5" {...props} />}
          />
          <div className="text-xs text-[#f00]">{otpError}</div>
        </div>
      </div>
      <div>
        <Button
          version="secondary"
          text="Submit"
          type="submit"
          loading={loading}
        />
      </div>
      <p className="get-link flex items-center gap-1">
        <span>Didn’t receive OTP code?</span>
        <span
          className={`resend-code ${
            timer > 0 ? "!text-gray-500 cursor-not-allowed" : "cursor-pointer"
          }`}
          onClick={handleResend}
        >
          Resend Code
        </span>
        {timer > 0 && <span>in {timer} seconds</span>}
      </p>
    </form>
  );
};

export default OtpModule;
