export const BROWSER_CHROME = "chrome";
export const BROWSER_FIREFOX = "firefox";
export const BROWSER_SAFARI = "safari";
export const BROWSER_EDGE = "edge";
export const BROWSER_OTHER = "other";

// linked in details
export const LINKEDIN_CLIENT_ID = "77a9vgfzycowx9";
const domain = "http://localhost:3000";
// const domainStaging = "https://staginginterview2.naskay.in"
export const REDIRECT_URI = `${domain}/auth/linkedin/callback`;
export const SCOPE = ["r_liteprofile", "r_emailaddress", "w_member_social"];

//github
export const REDIRECT_URI_GITHUB = domain;

// server apis
export const constgetLinkedInUserInfoFromAccessCode =
  "https://3.127.9.55:9095/api/v1/getLinkedInUserInfoFromAccessCode"; // linked in
export const constLinkedInPostJob = "https://3.127.9.55:9095/api/v1/postJob"; // github
export const constgetGitHubUserInfoFromAccessCode =
  "https://3.127.9.55:9095/api/v1/getGitHubUserInfoFromAccessCode"; // github

export const examDuration = 50;
export const candidateLoginWindow = -48 * 60 * 60 * 1000;
