import React, { useEffect, useState } from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Link,
} from "@react-pdf/renderer";
import QRCode from "qrcode";
import { pdfStyles } from "./pdfStyles";
import { examDuration } from "../../../constant/Constants";

const styles = StyleSheet.create(pdfStyles);

const Footer = () => (
  <View style={styles.footer}>
    <Image
      style={styles.footerIcon}
      //src="https://img.icons8.com/ios-filled/50/ffffff/globe.png" // Globe icon
      src="/img/logo.png"
    />
    <Text style={styles.footerText}>www.practido.com</Text>
  </View>
);

const PDFContent = ({ registrationCode, schoolDetails }) => {
  const [qrCodeDataUrl, setQrCodeDataUrl] = useState("");

  const {
    schoolName = "",
    schoolLogoUrl = "",
    schoolAddress = {
      address: "",
      city: "",
      state: "",
      country: "",
      pin: "",
    },
  } = schoolDetails || {};

  const registrationUrl = registrationCode || "";

  useEffect(() => {
    QRCode.toDataURL(registrationUrl, { type: "png" })
      .then((url) => {
        setQrCodeDataUrl(url);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [registrationUrl]);

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Image
          src="/img/pdf-frame.png"
          style={{
            width: "100%",
            height: "230px",
            position: "absolute",
            left: "0",
            top: "0",
          }}
        />
        <View style={styles.whitecontainer}>
          <View style={styles.container}>
            <View style={styles.schoolLogo}>
              <View
                style={styles.schoolLogo_img}
                className="w-24 h-24 overflow-hidden flex items-center justify-center rounded-full"
              >
                {schoolLogoUrl && (
                  <Image
                    src={schoolLogoUrl}
                    style={{ width: 80, height: 80 }}
                  />
                )}
              </View>
              <View
                style={{
                  width: 40,
                  height: 40,
                  borderRadius: "50%",
                  backgroundColor: "#aaa",
                }}
              />

              <View>
                <Text style={styles.schoolName}>{schoolName}</Text>
                <Text style={styles.schoolAddress}>
                  {schoolAddress?.address}, {schoolAddress?.city},{" "}
                  {schoolAddress?.state}, {schoolAddress?.country},{" "}
                  {schoolAddress?.pin}
                </Text>
              </View>
            </View>
          </View>

          {registrationUrl && (
            <View style={styles.qrContainer}>
              <View style={styles.registrationUrlBox}>
                <Text>
                  <Text style={styles.boldUrlText}>
                    Give your child a head start!
                  </Text>{" "}
                  Enroll them in the Olympiad now. Scan the QR code or use the
                  link to register.
                </Text>

                <View
                  style={{
                    borderRadius: "5",
                    marginTop: "8",
                    padding: "5 8",
                    backgroundColor: "#BDDEE5",
                    lineHight: "normal",
                    width: "100%",
                  }}
                >
                  <Link src={registrationUrl}>
                    {registrationUrl.slice(0, 60)}
                    {registrationUrl.slice(60)}
                  </Link>
                </View>
                {/* <View
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                    justifyContent: "flexStart",
                    width: "100%",
                    marginTop: "8",
                  }}
                >
                  <Text
                    style={{
                      color: "rgba(0, 30, 59, 0.7)",
                      display: "inlineBlock",
                      marginRight: "8",
                    }}
                  >
                    or
                  </Text>
                  <Text
                    style={{
                      cursor: "pointer",
                      borderRadius: "8",
                      width: "90",
                      padding: "4 8",
                      backgroundColor: "white",
                      boxShadow: "0px 4px 0px 0px #BDC3C7",
                    }}
                  >
                    <Text style={{ color: "#001E3B", fontWeight: "bold" }}>
                      Share the QR Code
                    </Text>
                  </Text>
                </View> */}
              </View>
              {qrCodeDataUrl && (
                <View style={styles.qrFrameBox}>
                  <Image src="/img/pdf-scan-layer.png" style={styles.qrFrame} />
                  <Image src={qrCodeDataUrl} style={styles.qrCodeImage} />
                </View>
              )}
            </View>
          )}

          <View style={styles.content}>
            <Text style={styles.title}>Why Practido Online Olympiad?</Text>
            <Text style={styles.paragraph}>
              Understanding the benefits of the Practido Online Olympiad is
              essential before offering these exams to your students. Here's why
              it’s a valuable opportunity:
            </Text>
            <Text style={styles.title}></Text>
            <Text style={styles.paragraph}>
              <Text style={styles.boldText}>Expert-Driven Assessment: </Text>The
              Practido Online Olympiad is crafted by leading education experts,
              focusing on enhancing students' practical knowledge and their
              ability to apply skills across various subjects.{"\n"}
              <Text style={styles.boldText}>
                First-of-Its-Kind Online Format:{" "}
              </Text>
              We are one of the pioneers in offering a fully online proctored
              Olympiad, allowing students to participate from the safety and
              comfort of their homes, ensuring a convenient and secure
              examination process.{"\n"}
              <Text style={styles.boldText}>
                No Special Preparation Material Needed:{" "}
              </Text>
              While we do not provide specific preparation materials or sample
              papers, students can effectively prepare using their school
              textbooks and our dedicated Olympiad Practice App.
              <Text style={styles.boldText}>Download the app here</Text> from
              the Play Store.{"\n"}
              <Text style={styles.boldText}>Subject Diversity: </Text>
              Our Olympiads cover three key subjects:
              <Text style={styles.boldText}> English, Maths, and Science,</Text>
              catering to a broad range of academic interests and strengths.
              {"\n"}
              <Text style={styles.boldText}>International Participation:</Text>
              The Olympiad provides a platform for students to compete on an
              international level, expanding their horizons and exposure to a
              diverse set of competitors.
            </Text>
            <Text style={styles.title}>Important Dates:</Text>
            <Text style={styles.paragraph}>
              The Practido Online Olympiad dates will be announced in{" "}
              <Text style={styles.boldText}>October 2024 </Text>
              onwards. {"\n"} Registration starts from{" "}
              <Text style={styles.boldText}>August 2024</Text> to{" "}
              <Text style={styles.boldText}>October 2024.</Text> {"\n"} Student
              Registration Deadline:{" "}
              <Text style={styles.boldText}> [Date]</Text>
            </Text>
            {registrationUrl && (
              <View>
                <Text style={styles.title}>Registration</Text>
                <Text style={styles.paragraph}>
                  <Text style={styles.boldText}>Receive URL : </Text>Your School
                  will provide you with a unique registration URL.
                </Text>
                <Text style={styles.paragraph}>
                  <Text style={styles.boldText}>Click the Link : </Text>{" "}
                  Students and parents should click on the provided URL.
                </Text>
                <Text style={styles.paragraph}>
                  <Text style={styles.boldText}>Complete the Form : </Text> Fill
                  out the registration from with the required details.
                </Text>
                <Text style={styles.paragraph}>
                  <Text style={styles.boldText}>Submit : </Text> Submit the form
                  to complete the registration process.
                </Text>
              </View>
            )}
            <Text style={styles.title}>Participation</Text>
            <Text style={styles.paragraph}>
              <Text style={styles.boldText}>Open to Classes 1 to 8: </Text>
              The Olympiad is open to all students from{" "}
              <Text style={styles.boldText}>Class 1 to 8,</Text> enabling
              participation from the comfort of their homes.{"\n"}
              <Text style={styles.boldText}>Technical Requirement:</Text>{" "}
              Participants need access to a{" "}
              <Text style={styles.boldText}>laptop</Text>,or{" "}
              <Text style={styles.boldText}>desktop</Text> equipped with a{" "}
              <Text style={styles.boldText}>web camera,microphone</Text> and a
              stable internet connection.{"\n"}
              <Text style={styles.boldText}>Accurate Information:</Text>
              It is crucial to provide accurate details such as the school name
              and Participant's name,as these will appear on the certificates.
            </Text>
            <Text style={styles.title}>Fees and Payment Details</Text>{" "}
            <Text style={styles.paragraph}>
              <Text style={styles.boldText}>Participation Fee:</Text>
              {"\n"}
              <Text style={styles.boldText}>Rs.99</Text> for one subject or{" "}
              <Text style={styles.boldText}>Rs.199</Text> for all three subjects{" "}
              <Text style={styles.boldText}>(Math,Science,English)</Text> for
              Indian students.{"\n"}
              <Text style={styles.boldText}>$4.99</Text> for one subject or{" "}
              <Text style={styles.boldText}>$9.99</Text> for all three subjects{" "}
              <Text style={styles.boldText}>(Math,Science,English)</Text> for
              international students.{"\n"}
              <Text style={styles.boldText}>Participation Policy:</Text>
              <Text>The fee is non-refundable once paid.</Text>
            </Text>
          </View>
        </View>

        <Footer />
      </Page>

      <Page size="A4" style={styles.page}>
        <Image
          src="/img/pdf-frame.png"
          style={{
            width: "100%",
            height: "230px",
            position: "absolute",
            left: "0",
            top: "0",
          }}
        />
        <View style={styles.whitecontainer}>
          <Text style={styles.title}>Exam Instructions</Text>
          <Text style={styles.paragraph}>
            <Text style={styles.title}> Webcam:</Text>
            {"\n"}• Keep the Camera ON and working during the competition.
            {"\n"}• Please refrain from Stepping away from your laptop/Dasktop
            during the competition.
            {"\n"}• Please sit in front of proper lighting so that the camera
            can detect your face and background.
            {"\n"}• The competitor will get disqualified if the webcam is turned
            off at any time while giving the competition.
          </Text>
          <Text style={styles.title}>Don't Refresh Or Exit Full Screen:</Text>
          <Text style={styles.paragraph}>
            • Do not switch/refresh the tab or try to open any other window.
            {"\n"}• Do not open the development or any other tool for help.
            {"\n"}• Do not move the cursor out of the competition tab.
            {"\n"}• Do not Press the 'Back Button' while taking the competition.
            {"\n"}• Do not exit 'Full-Screen' mode while taking the competition.
          </Text>
          <Text style={styles.title}>Microphone:</Text>
          <Text style={styles.paragraph}>
            Keep your microphone ON.
            {"\n"}Please do not mute or disconnect the microphone during the
            competition.
            {"\n"}Please make sure that no background noise is detected.
          </Text>
          <Text style={styles.title}>Challenge Instructions:</Text>
          <Text style={styles.paragraph}>
            • Please read all the instructions carefully before you start.
            {"\n"}• Join in 15 min before Challenge Start time.
            {"\n"}• Attempt maximum questions with correct answers in{" "}
            {examDuration} mins to become a winner.
            {"\n"}• E-Certificate to all participants.
            {"\n"}• This is a skill-based Competition.
            {"\n"}• The participants will have {examDuration} mins to complete
            the test and can attempt as many questions as they want.
            {"\n"}• The winner will be judged based on who provides the maximum
            number of correct answers in the minimum time.
            {"\n"}• You will get 3 warnings, violating these warnings may result
            in the Disqualification of the test directly.
            {"\n"}• You should have a stable internet connection.
            {"\n"}• The microphone should remain Unmuted and there should be no
            Background Noise.
            {"\n"}• The web camera should be ON and working at all times while
            taking the competition.
            {"\n"}• Violating the given instructions will result in
            disqualification from the competition.
          </Text>

          <View style={styles.content}>
            <Text style={styles.title}>
              Remember, a warning will be triggered if you try the following:
            </Text>
            <Text style={styles.paragraph}>
              • Exiting the full-screen mode of the browser.
              {"\n"}• Moving the Cursor outside the window or hovering the
              cursor on other Tabs.
              {"\n"}• Switching the Tab or opening other windows.
              {"\n"}• Opening Browsers developer / Inspect tools.
              {"\n"}• Minimizing or resizing the window.
              {"\n"}• Recording or taking Screenshots during the competition.
              {"\n"}• Copy paste is not allowed.
            </Text>

            <Text style={styles.title}>Date:</Text>
            <Text style={styles.paragraph}>December, 2024 Onwards</Text>
            <Text style={styles.paragraph}>
              Please contact us for any help on our customer support email us to{" "}
              <Link src="mailto:olympiadpractice@naskay.in">
                olympiadpractice@naskay.in
              </Link>
            </Text>
          </View>
        </View>
        <View style={styles.footer2}>
          <Image
            style={styles.footerdownload}
            src="/img/footer_download_img.png"
          />
          <Text style={styles.paragraph2}>
            Practice Maths,Science and English. Unlock the Potential of
            Practido's Mobile App!.{"\n"}
            <Text style={styles.boldText2}>• Topic-Based Practice Test: </Text>
            Sharpen your skills with targeted practice tests.
            {"\n"}
            <Text style={styles.boldText2}>• Timed Test: </Text>
            Improve speed and accuracy under exam conditions.
            {"\n"}
            <Text style={styles.boldText2}>• Instant Results: </Text>
            Get immediate feedback and insights into strengths and areas for
            improvement.
            {"\n"}
            <Text style={styles.boldText2}>• Test History: </Text>
            Track progess over time with comprehensive test history and
            performance trends.
            {"\n"}
            <Text style={styles.boldText2}>• My World: </Text>
            Earn points, coins, and trophies, compete for a spot in the Hall of
            Fame.
            {"\n"}
            <Text style={styles.boldText2}>• Leaderboard: </Text>
            Engage in friendly competition and track your progress among top
            performance.
          </Text>
          <Image
            style={styles.footerQrScanner}
            src="/img/footer_QR_Scanner.png"
          />
        </View>
        <Footer />
      </Page>
    </Document>
  );
};

export default PDFContent;
