import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import SchoolRegisterForm from "./school/SchoolRegisterForm";
import IndividualRegisterForm from "./individual/IndividualRegisterForm";
import SchoolRegistrationInstructions from "./school/SchoolRegistrationInstructions";
import IndividualRegistrationInstructions from "./individual/IndividualRegistrationInstructions";
import PasswordModule from "./school/PasswordModule";

const Register = () => {
  const location = useLocation();

  const [tab, setTab] = useState("individual");
  const tabName = location.pathname.split("/")[2];
  const setPasswordModule = location.pathname.split("/")[3] === "setPassword";

  const [showTabs, setShowTabs] = useState();

  useEffect(() => {
    tabName && setTab(tabName);
  }, [tabName]);

  useEffect(() => {
    setPasswordModule && setShowTabs(false);
  }, [setPasswordModule]);

  return (
    <section className="school-registration">
      <div className="form-container">
        <div className="container-fluid">
          <div className="row order-change">
            <>
              <div className="col-md-12 col-lg-6 left-item">
                <div className="form-data step-1">
                  <div className={`mobile-header ${!showTabs && "hidden"}`}>
                    <h3>Registration Form 2024-25</h3>
                    <div className="button-group">
                      {[
                        {
                          name: "individual",
                          label: "Register as Individual",
                        },
                        { name: "school", label: "Register as School" },
                      ].map(({ name, label }) => (
                        <Link
                          key={name}
                          to={`/register/${name}`}
                          className={`btn primary-btn ${
                            tab === name ? "active" : "not-active"
                          }`}
                        >
                          {label}
                        </Link>
                      ))}
                    </div>
                  </div>
                  <div className="flex flex-col">
                    {tab === "school" ? (
                      setPasswordModule ? (
                        <PasswordModule />
                      ) : (
                        <SchoolRegisterForm setShowTabs={setShowTabs} />
                      )
                    ) : (
                      <IndividualRegisterForm setShowTabs={setShowTabs} />
                    )}
                  </div>
                </div>
              </div>
            </>
            <div className="col-md-12 col-lg-6 right-item">
              {tab === "school" ? (
                <SchoolRegistrationInstructions />
              ) : (
                <IndividualRegistrationInstructions />
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Register;
