import React, { useEffect, useRef, useState } from "react";
import { Button, Checkbox, SelectInput, TextInput } from "../../../components";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  serverTimestamp,
  setDoc,
  Timestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import {
  participantsCollectionRef,
  auth,
  parentsCollectionRef,
  schoolCollectionRef,
  db,
} from "../../../config/firebaseConfig";
import { useAuthState } from "react-firebase-hooks/auth";
import Cart from "./Cart";
import { MdDelete } from "react-icons/md";
import { ClassOptions, subjectsOptions } from "../constants/options";
import { FaMinus, FaPlus } from "react-icons/fa6";
import Step1Instructions from "./Step1Instructions";
import envConfig from "../../../config/envConfig";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { ImSpinner2 } from "react-icons/im";
import { GAEventTrack } from "../../../hooks/GA_events";
import { scrollToView } from "../../../constant/scrollToView";

const Step1 = () => {
  const [loading, setLoading] = useState(false);
  const [userAuth] = useAuthState(auth);
  const [participantArray, setParticipantArray] = useState([
    {
      name: "",
      dateOfBirth: "",
      subjects: ["maths", "science", "english"],
      class: "",
    },
  ]);

  const [parentDetails, setParentDetails] = useState(null);
  const [schoolDetails, setSchoolDetails] = useState(null);
  const [country, setCountry] = useState("IN");
  const [acknowledgeChecked, setAcknowledgeChecked] = useState(false);

  useEffect(() => {
    const fetchCountry = async () => {
      try {
        const response = await fetch(
          `https://ipinfo.io/json?token=${envConfig?.ipInfoKey}`
        );
        const data = await response.json();
        const country = data.country;
        setCountry(country);
      } catch (error) {
        console.log("Error fetching the IP address:", error);
        setCountry("IN");
      }
    };

    fetchCountry();
  }, []);

  useEffect(() => {
    const fetchParentDetails = async () => {
      const q = query(parentsCollectionRef, where("uid", "==", userAuth?.uid));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        setParentDetails({ ...doc.data(), id: doc.id });
      });
    };
    userAuth && fetchParentDetails();
  }, [userAuth]);

  useEffect(() => {
    const schoolId = parentDetails?.schoolId;
    const fetchSchoolData = async () => {
      const schoolData = await getDoc(doc(schoolCollectionRef, schoolId));
      setSchoolDetails({
        schoolId: schoolData.id,
        schoolName: schoolData.data().schoolName,
        schoolAddress: schoolData.data().schoolAddress,
        schoolLogoUrl: schoolData.data().schoolLogoUrl,
      });
    };

    schoolId && fetchSchoolData();
  }, [parentDetails]);

  const uploadParticipant = async (participant) => {
    const enrollmentNo = `${participant?.name?.split(" ")[0]}_${
      participant?.class
    }_${participant?.dateOfBirth
      ?.split("-")
      ?.reverse()
      ?.join("")
      ?.toUpperCase()}_${uuidv4().substring(0, 4)}`;
    const participantData = {
      name: participant.name,
      dateOfBirth: participant.dateOfBirth,
      className: participant.class,
      enrollmentNo: enrollmentNo,
      parentUid: userAuth.uid,
      parentDocId: parentDetails?.id,
      schoolDetails: schoolDetails,
      homeAddress: null,
      paymentDetails: null,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
      subjects: participant.subjects?.map((subject) => {
        const examCode = `P${subject?.slice(0, 1)}O-${uuidv4().substring(
          0,
          5
        )}`?.toUpperCase();
        return {
          subject: subject,
          examCode: examCode,
        };
      }),
    };
    try {
      await setDoc(
        doc(participantsCollectionRef, enrollmentNo),
        participantData
      );
      return participantData;
    } catch (error) {
      throw error;
    }
  };

  const handleFieldChange = (fieldValue, index, fieldName) => {
    const newParticipants = [...participantArray];
    newParticipants[index][fieldName] = fieldValue;
    setParticipantArray(newParticipants);
  };

  const [error, setError] = useState(null);
  const [loadingModule, setLoadingModule] = useState(false);
  const [retryModule, setRetryModule] = useState(false);
  const acknowledgeErrorRef = useRef(null);
  const submitErrorRef = useRef();

  const [amount, setAmount] = useState(0);

  const handleSaveAndPay = async (e) => {
    e.preventDefault();
    setError(null);

    // form validations
    let dobError = [];
    let subjectError = [];

    participantArray?.forEach((participant, index) => {
      if (
        new Date(participant?.dateOfBirth) >
        new Date().setFullYear(new Date().getFullYear() - 5)
      ) {
        dobError.push({
          index: index,
          errorMessage: "candidate must be at lease 5 years old",
        });
      } else {
        dobError.push({
          index: index,
          errorMessage: "",
        });
      }
      if (participant?.subjects?.length === 0) {
        subjectError.push({
          index: index,
          errorMessage: "Please select atleast one subject",
        });
      } else {
        subjectError.push({
          index: index,
          errorMessage: "",
        });
      }
    });

    setError((prev) => ({
      ...prev,
      dob: dobError,
    }));

    setError((prev) => ({
      ...prev,
      subject: subjectError,
    }));
    if (
      subjectError?.some((error) => error?.errorMessage) ||
      dobError?.some((error) => error?.errorMessage)
    ) {
      // todo - scroll to top error
      return;
    }

    if (!acknowledgeChecked) {
      setError((prev) => ({
        ...prev,
        acknowledgement: "Please acknowledge the terms and conditions",
      }));
      scrollToView(acknowledgeErrorRef?.current);
      return;
    }

    setLoading(true);
    //GA event tracking
    GAEventTrack({
      event: "step1_save_and_pay",
      data: {
        uid: auth?.currentUser?.uid,
        email: auth?.currentUser?.email,
      },
    });
    try {
      const participantsAdded = await Promise.all(
        participantArray?.map(
          async (participant) => await uploadParticipant(participant)
        )
      );

      const paymentFor = participantsAdded?.map((participant) => ({
        participantId: participant.enrollmentNo,
        subjects: participant.subjects.map((subject) => subject.subject),
      }));
      const participantIds = participantsAdded?.map(
        (participant) => participant.enrollmentNo
      );

      localStorage.setItem(
        "practido-step2-participantIds",
        JSON.stringify(participantIds)
      );

      await handlePayment(amount, paymentFor);
    } catch (error) {
      setError((prev) => ({ ...prev, submit: error?.message }));
      scrollToView(submitErrorRef?.current);
      setLoadingModule(false);
    } finally {
      setLoading(false);
    }
  };

  const handlePayment = async (amount, paymentFor) => {
    const backendUrl = `${envConfig.backendUrl}`;
    const currency = country === "IN" ? "INR" : "USD";

    try {
      const {
        data: { key },
      } = await axios.get(`${backendUrl}?action=getkey`);

      const {
        data: { order },
      } = await axios.post(`${backendUrl}?action=checkout`, {
        amount,
        currency,
        userId: auth?.currentUser?.uid,
        userEmail: auth?.currentUser?.email,
        childCount: participantArray?.length,
      });

      const options = {
        key,
        order_id: order.id,
        amount: order.amount,
        currency: order.currency,
        name: envConfig.appFullName,
        description: envConfig.appFullName,
        image: "/img/appLogo.png",
        prefill: {
          name: auth?.currentUser?.displayName,
          email: auth?.currentUser?.email,
          contact: parentDetails?.phone,
        },
        notes: {
          uid: auth?.currentUser?.uid,
        },
        theme: {
          color: "#E74C3C",
        },
        handler: async (response) => {
          try {
            await paymentSuccessHandler(
              response,
              backendUrl,
              order,
              paymentFor
            );
          } catch {
            setError((prev) => ({
              ...prev,
              submit: error?.message,
            }));
          }
        },
        modal: {
          ondismiss: async () => await onPaymentModalDismiss(backendUrl, order),
        },
      };

      const razor = new window.Razorpay(options);

      // Handle payment failure
      razor.on("payment.failed", async function (response) {
        console.log(response);
        // const res = await axios.post(`${backendUrl}?action=payementFailed`, {
        // razorpay_payment_id: "paymentId_123",
        // razorpay_order_id: "orderId_123",
        // userId: auth?.currentUser?.uid,
        // userEmail: auth?.currentUser?.email,
        // receipt: "receipt_123",
        // amount: 199,
        // currency: "INR",
        // childCount: 1,
        // paymentStatus: "failed",
        // reason: "failure reason",
        // verificationStatus: null,
        // });

        //GA event tracking
        GAEventTrack({
          event: "step1_payment_failed",
          data: {
            uid: auth?.currentUser?.uid,
            email: auth?.currentUser?.email,
          },
        });
      });

      razor.open();
      setLoadingModule(true);
    } catch (error) {
      // GA event tracking
      GAEventTrack({
        event: "step1_payment_init_failed",
        data: {
          uid: auth?.currentUser?.uid,
          email: auth?.currentUser?.email,
          error: error.code,
        },
      });
      throw new Error(
        "Something went wrong, while initiating your payment. Please try again"
      );
    }
  };

  const onPaymentModalDismiss = async (backendUrl, order) => {
    await axios.post(`${backendUrl}?action=orderDismissed`, {
      orderId: order.id,
    });
    setLoadingModule(false);
    // GA event tracking
    GAEventTrack({
      event: "step1_payment_dismissed",
      data: {
        uid: auth?.currentUser?.uid,
        email: auth?.currentUser?.email,
      },
    });
  };
  const paymentSuccessHandler = async (
    response,
    backendUrl,
    order,
    paymentFor
  ) => {
    console.log("handler -- ", response);

    // Payment verification
    try {
      const verificationResponse = await axios.post(
        `${backendUrl}?action=paymentVerification`,
        {
          razorpay_order_id: response.razorpay_order_id,
          razorpay_payment_id: response.razorpay_payment_id,
          razorpay_signature: response.razorpay_signature,
          userId: auth?.currentUser?.uid,
          userEmail: auth?.currentUser?.email,
          receipt: order?.receipt,
          amount: order?.amount,
          currency: order?.currency,
          childCount: participantArray?.length,
        }
      );

      if (verificationResponse?.data?.status === "success") {
        console.log("Payment verification successful");
        try {
          await savePaymentDetails(response, order, paymentFor);
          window.location.href = `/onboarding/step2?ref=${response.razorpay_payment_id}`;
        } catch (error) {
          console.log("Error saving payment details:", error);
          setLoadingModule(false);
          localStorage.setItem(
            "practido-step1-retryData",
            JSON.stringify({
              response,
              order,
              paymentFor,
            })
          );
          setRetryModule(true);
          //GA event tracking
          GAEventTrack({
            event: "step1_payment_data__saving_failed",
            data: {
              uid: auth?.currentUser?.uid,
              email: auth?.currentUser?.email,
            },
          });
        }
      } else {
        console.log("Payment verification failed");
        //GA event tracking
        GAEventTrack({
          event: "step1_payment_verification_failed",
          data: {
            uid: auth?.currentUser?.uid,
            email: auth?.currentUser?.email,
          },
        });
        throw new Error("Payment verification failed");
      }
    } catch (error) {
      console.log(error);
      GAEventTrack({
        event: "step1_paymentVerification_serverError",
        data: {
          uid: auth?.currentUser?.uid,
          email: auth?.currentUser?.email,
        },
      });
      throw new Error(
        ("server error - Somethig went wrong while verifiying your payment.",
        error)
      );
    }
  };

  const savePaymentDetails = async (response, order, paymentFor) => {
    // payment details for payment collection
    const paymentDetails = {
      orderId: response.razorpay_order_id,
      amount: order.amount,
      currency: order.currency,
      paymentAt: Timestamp.fromDate(new Date()),
      paymentId: response.razorpay_payment_id,
      paymentSignature: response.razorpay_signature,
      paymentFor: paymentFor,
      schoolId: schoolDetails?.schoolId || null,
    };

    // Add payment details to Firebase Firestore
    try {
      const paymentRef = await addDoc(
        collection(db, "payments"),
        paymentDetails
      );

      // payment details for parent collection
      const paymentDetailsParentDoc = {
        paymentId: response.razorpay_payment_id,
        amount: order.amount,
        currency: order.currency,
        paymentAt: Timestamp.fromDate(new Date()),
        paymentRef: paymentRef.id,
        paymentFor: paymentFor,
      };

      // update data to parent db
      const parentDocRef = doc(db, "parents", parentDetails?.id);
      await updateDoc(parentDocRef, {
        paymentDetails: [
          ...(parentDetails?.paymentDetails || []),
          paymentDetailsParentDoc,
        ],
      });

      // update payment status to participtant db - add in subjects array
      const ids = paymentFor.map((participant) => participant.participantId);
      await Promise.all(
        ids?.map(async (id) => {
          try {
            const participantDocRef = doc(db, "participants", id);
            const docSnap = await getDoc(participantDocRef);
            if (docSnap.exists()) {
              let subjects = docSnap.data().subjects;
              subjects = subjects.map((subject) => ({
                ...subject,
                payment: {
                  status: "paid",
                  paymentRef: paymentRef.id,
                },
              }));
              await updateDoc(participantDocRef, {
                subjects: subjects,
              });
            } else {
              console.log("No such document!");
            }
          } catch (error) {
            throw error;
          }
        })
      );

      //GA event tracking
      GAEventTrack({
        event: "step1_payment_data_saved",
        data: {
          uid: auth?.currentUser?.uid,
          email: auth?.currentUser?.email,
        },
      });
    } catch (error) {
      console.log(error);
      // setError((prev) => ({ ...prev, submit: error }));
      // submitErrorRef?.current?.scrollIntoView({ behavior: "smooth" });
      // setLoadingModule(false);
      throw error;
    }
  };

  const retryUpdatingPaymentData = async () => {
    const data = JSON.parse(localStorage.getItem("practido-step1-retryData"));
    if (data) {
      const response = data.response;
      const order = data.order;
      const paymentFor = data.paymentFor;
      try {
        await savePaymentDetails(response, order, paymentFor);
        setRetryModule(false);
        window.location.href = `/onboarding/step2?ref=${response.razorpay_payment_id}`;
      } catch (error) {
        console.log("Error saving payment details:", error);
        localStorage.setItem(
          "practido-step1-retryData",
          JSON.stringify({
            response,
            order,
            paymentFor,
          })
        );
        setRetryModule(true);
      }
    }
  };

  const [cartPositon, setCartPosition] = useState("absolute top-10");
  const cartRef = useRef();

  useEffect(() => {
    document.addEventListener("scroll", () => {
      const cart = cartRef.current;

      if (cart && window.scrollY > 260) {
        if (
          document.body.scrollHeight - window.scrollY >
          700 + cart.offsetHeight
        ) {
          setCartPosition("fixed top-24");
        } else {
          setCartPosition("absolute bottom-0");
        }
      } else {
        setCartPosition("absolute top-10");
      }
    });
  }, []);

  return (
    <form className="relative px-3" onSubmit={handleSaveAndPay}>
      {loadingModule && (
        <div className="fixed top-0 left-0 w-full h-full z-50 bg-black/20 backdrop-blur-sm flex items-center justify-center">
          <div className=" p-20 py-10 bg-white shadow-md rounded-xl text-gray-500 flex flex-col items-center gap-4">
            <ImSpinner2 className="animate-spin w-8 h-8" />
            <p className="text-2xl font-bold ">Please Wait</p>
          </div>
        </div>
      )}
      {retryModule && (
        <div className="fixed top-0 left-0 w-full h-full z-50 bg-black/20 backdrop-blur-sm flex items-center justify-center">
          <div className="p-10 mx-10 w-[32rem] bg-white shadow-md rounded-xl flex flex-col items-center gap-4">
            <p className="text-xl font-bold text-center text-gray-600 ">
              Payment was successful, but we encountered an error while saving
              payment details.
            </p>
            <Button
              version="secondary"
              text="Try again"
              handleClick={retryUpdatingPaymentData}
            />
          </div>
        </div>
      )}
      <div className="onboarding-step max-w-[990px] mt-0 mb-[50px] mx-auto">
        <div
          className={`summary-card for-mobile ${cartPositon} w-[450px] space-y-4`}
          ref={cartRef}
        >
          <Cart
            country={country}
            participantDetailArray={participantArray}
            amount={amount}
            setAmount={setAmount}
          />
        </div>

        <h1 className="text-2xl font-bold ">Participant Details</h1>
        <div className="row mt-4">
          <div className="col-md-3">
            <div className="form-group">
              <label>
                Select No. of Participants<sup>*</sup>
              </label>
              <div className="bg-white border-[1px] border-gray-300 rounded-xl flex items-center justify-between p-2 w-[250px]">
                <button
                  className="p-2 bg-[#E74C3C]/10 text-[#E74C3C] rounded-lg disabled:bg-gray-300 disabled:text-gray-500"
                  disabled={participantArray?.length < 2}
                  onClick={(e) => {
                    e.preventDefault();
                    setParticipantArray((prev) => prev.slice(0, -1));
                  }}
                >
                  <FaMinus />
                </button>
                <span className="text-base font-bold">
                  {participantArray?.length?.toString().padStart(2, "0")}
                </span>
                <button
                  className="p-2 bg-[#00C707]/10 text-[#00C707] rounded-lg"
                  onClick={(e) => {
                    e.preventDefault();
                    setParticipantArray((prev) => [
                      ...prev,
                      {
                        name: "",
                        dateOfBirth: "",
                        subjects: ["maths", "science", "english"],
                        class: "",
                      },
                    ]);
                  }}
                >
                  <FaPlus />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="onboarding-second-step">
          {participantArray?.map((participant, index) => (
            <div key={index}>
              <div className="flex gap-4 items-center mb-4 row">
                <div className="col-md-6 d-flex align-items-center justify-content-between">
                  <h2 className="text-xl font-bold ">
                    Participant {index + 1} Details
                  </h2>
                  {participantArray.length > 1 && (
                    <>
                      <MdDelete
                        title="Remove"
                        className="text-red-500 cursor-pointer w-6 h-6"
                        onClick={() => {
                          const updatedArray = participantArray.filter(
                            (_, i) => i !== index
                          );
                          setParticipantArray(updatedArray);
                        }}
                      />
                    </>
                  )}
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 mb-2">
                  <div className="form-group">
                    <label>
                      Participant Name<sup>*</sup>
                    </label>
                    <TextInput
                      placeholderText="Participant Name"
                      inputValue={participant.name}
                      inputName="participantName"
                      // error={errorArray[index].nameError}
                      isRequired={true}
                      handleChange={(e) => {
                        const fieldValue = e.target.value;
                        handleFieldChange(fieldValue, index, "name");
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 mb-2">
                  <div className="form-group">
                    <label>
                      Date Of Birth<sup>*</sup>
                    </label>
                    <TextInput
                      type="date"
                      placeholderText="Date Of Birth"
                      inputValue={participant.dateOfBirth}
                      inputName="dob"
                      error={error?.dob[index]?.errorMessage}
                      isRequired={true}
                      handleChange={(e) => {
                        const fieldValue = e.target.value;
                        handleFieldChange(fieldValue, index, "dateOfBirth");
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="mb-6">
                    <label className="font-bold">
                      Class<sup>*</sup>
                    </label>
                    <div className="select-wrapper">
                      <SelectInput
                        placeholderText="Participant Class"
                        selectOptions={ClassOptions}
                        isRequired={true}
                        // error={errorArray[index].classError}
                        handleChange={(selectedOption) => {
                          const fieldValue = selectedOption.value;
                          handleFieldChange(fieldValue, index, "class");
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <div className="flex gap-4">
                      <label className="">
                        Select Subject<sup>*</sup>
                      </label>
                    </div>
                    <div className="flex justify-start gap-5">
                      {subjectsOptions.map((sub) => (
                        <div className="relative" key={sub.id}>
                          <div className="absolute top-[-2rem] right-[-18px]">
                            <Checkbox
                              checkId={`p${index + 1}${sub.value}`}
                              checkName={sub.value}
                              checkValue={sub.value}
                              checked={participant?.subjects?.includes(
                                sub?.value
                              )}
                              handleChange={() => {
                                const newParticipants = [...participantArray];
                                const newSubjects = [...participant?.subjects];
                                participant?.subjects?.includes(sub?.value)
                                  ? newSubjects.splice(
                                      participant?.subjects?.indexOf(
                                        sub?.value
                                      ),
                                      1
                                    )
                                  : newSubjects.push(sub?.value);
                                newParticipants[index].subjects = newSubjects;
                                setParticipantArray(newParticipants);
                              }}
                            />
                          </div>
                          <label
                            htmlFor={`p${index + 1}${sub.value}`}
                            className="flex flex-col items-center justify-center gap-4 cursor-pointer"
                          >
                            <div
                              className={`w-[126px] h-[126px] rounded-2xl flex items-center justify-center subject-card
                                ${
                                  participant?.subjects?.includes(sub.value)
                                    ? "bg-[#F2B010] shadow-[0px_4px_0px_0px_#B88815]"
                                    : "bg-[#ECF0F1] shadow-[0px_4px_0px_0px_#A9A9A9] hover:bg-[#E5E5F1] "
                                }
                              `}
                            >
                              <img src={sub.icon} alt={sub.label} />
                            </div>
                            <div className="font-bold text-[16px] text-[#34495E]">
                              {sub.label}
                            </div>
                          </label>
                        </div>
                      ))}
                    </div>
                    <div className="text-red-500">
                      {error?.subject[index]?.errorMessage}
                    </div>
                  </div>
                </div>
              </div>

              {index !== participantArray.length - 1 && (
                <div className="h-[1px] bg-gray-300 w-full mt-2 mb-8" />
              )}
            </div>
          ))}

          <div className="mt-10">
            <div className="text-red-500 mb-2" ref={submitErrorRef}>
              {error?.submit || null}
            </div>

            <div className="flex gap-2 items-center">
              {/* <Button
                loading={loading}
                text="Next"
                version="secondary"
                type="submit"
              /> */}
            </div>
          </div>
        </div>
        <div className="my-8 imp-inst">
          <Step1Instructions
            checked={acknowledgeChecked}
            setChecked={setAcknowledgeChecked}
            acknowledgeErrRef={acknowledgeErrorRef}
            error={error?.acknowledgement}
          />
        </div>
      </div>
      <div
        className={`summary-card desktop-view w-[450px] z-40 right-[10%] -top-10 h-full absolute `}
      >
        <div
          className={`summary-card ${cartPositon} w-[450px] space-y-4`}
          ref={cartRef}
        >
          <Cart
            country={country}
            participantDetailArray={participantArray}
            amount={amount}
            setAmount={setAmount}
          />
          <Button
            loading={loading}
            type="submit"
            text="Save and Pay"
            version="secondary"
            // handleClick={handleSaveAndPay}
          />
        </div>
      </div>
    </form>
  );
};

export default Step1;
