import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { analytics } from "../config/firebaseConfig";
import { logEvent } from "firebase/analytics";

const usePageViews = () => {
  const location = useLocation();

  useEffect(() => {
    logEvent(analytics, "page_view", {
      page_path: location.pathname,
    });
  }, [location]);
};

export default usePageViews;
