import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { auth, storage } from "../../../config/firebaseConfig";
import { Card, Typography } from "@material-tailwind/react";
import { Button } from "../../../components";
import { MdEditSquare } from "react-icons/md";
import { getMetadata, ref } from "firebase/storage";
import { GAEventTrack } from "../../../hooks/GA_events";

const StudentView = ({ studentDetails, accountType }) => {
  const searchQuery = useSearchParams()[0];
  const referenceNumber = searchQuery.get("ref");
  const [tableHead, setTableHead] = useState([]);

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [successProgress, setSuccessProgress] = useState(100);
  const [progressPause, setProgressPause] = useState(false);
  useEffect(() => {
    if (studentDetails) {
      if (referenceNumber && successProgress > 0) {
        setShowSuccessMessage(true);
      } else {
        setShowSuccessMessage(false);
      }
    }
  }, [studentDetails, referenceNumber, successProgress]);
  useEffect(() => {
    let progressInterval;
    if (showSuccessMessage) {
      if (!progressPause) {
        progressInterval = setInterval(() => {
          setSuccessProgress((prev) => prev - 1);
        }, 100);
      }
    }
    return () => {
      if (progressInterval) {
        clearInterval(progressInterval);
      }
    };
  }, [showSuccessMessage, progressPause, setSuccessProgress]);

  useEffect(() => {
    const head =
      accountType === "school"
        ? [
            "Olympiad Exam",
            "Exam Dates",
            "Exam Slots",
            "Exam Starts in",
            "Action",
          ]
        : accountType === "parent"
        ? [
            "Olympiad Exam",
            "Exam Dates",
            "Exam Slots",
            "Exam Starts in",
            "Exam Code",
            "Action",
          ]
        : [];

    setTableHead(head);
  }, [accountType]);

  const examNames = {
    maths: "PMO (Practido Mathematics Olympiad)",
    english: "PEO  (Practido English Olympiad)",
    science: "PSO (Practido Science Olympiad)",
  };

  const navigate = useNavigate();

  const [fileMeta, setFileMeta] = useState();
  useEffect(() => {
    const fileUrl = studentDetails?.idProofUrl;
    if (fileUrl) {
      const storageRef = ref(storage, fileUrl);
      getMetadata(storageRef)
        .then((metadata) => {
          setFileMeta(metadata);
        })
        .catch((error) => {
          console.error(`Error getting file metadata: ${error}`);
        });
    }
  }, [studentDetails]);

  return (
    <div className="step3 space-y-10">
      {showSuccessMessage && (
        <div
          className="payment-successful-card relative overflow-hidden"
          onMouseEnter={() => setProgressPause(true)}
          onMouseLeave={() => setProgressPause(false)}
        >
          <div className="col-md-8 center-card space-y-2">
            <img
              className="banner-cloud"
              src="/img/success-icon.png"
              alt="successful"
            />
            <h4>Payment Successful</h4>
            <p className="!text-sm !font-thin">
              <strong>Payment Id : {referenceNumber}</strong>
            </p>
            <p>
              Your subjects have been added successfully for the{" "}
              <strong>Practido Online Olympiad 2024</strong> <br />
              Please check{" "}
              <span className="text-[#009DFF]">
                {auth?.currentUser?.email}
              </span>{" "}
              for the details.
            </p>
          </div>
          <div
            className="absolute border-b-4 border-green-500 bottom-0 left-0 transition-all duration-100"
            style={{ width: `${successProgress}%` }}
          />
        </div>
      )}
      <div className="student-detail-row">
        <div className="d-flex pt-0 table-container">
          <div className="table-header-row">
            <div>Participant Name :</div>
            <div>Class :</div>
            <div>Enrollment No. :</div>
          </div>
          <div className="table-data-row">
            <div className="capitalize">{studentDetails?.name}</div>
            <div>
              {studentDetails?.className}
              <span>
                {studentDetails?.className === 1
                  ? "st"
                  : studentDetails?.className === 2
                  ? "nd"
                  : studentDetails?.className === 3
                  ? "rd"
                  : "th"}
              </span>
            </div>
            <div>
              <span className="capitalize">{studentDetails?.enrollmentNo}</span>
            </div>
          </div>
        </div>
      </div>

      <div className="add-subject-row">
        <h3>Exam Sheet of Olympiad Exam</h3>
        {accountType === "parent" && studentDetails?.subjects?.length < 3 && (
          <Button
            version="secondary blue-btn"
            handleClick={() => {
              //GA event tracking
              GAEventTrack({
                event: "parent_dshbrd_add_subject_btn",
                data: {
                  uid: auth?.currentUser?.uid,
                  email: auth?.currentUser?.email,
                  studentId: studentDetails?.id,
                },
              });
              navigate("addSubjects");
            }}
          >
            <div className="flex items-center justify-center gap-2">
              <strong className="text-2xl">+</strong>
              <span>Participate in More Subjects</span>
            </div>
          </Button>
        )}
      </div>

      <Card className="table-wrapper w-full rounded-xl">
        <table className=" w-full h-full min-w-max table-auto text-left">
          <thead className="">
            <tr>
              {tableHead.map((head, index) => (
                <th
                  key={head}
                  className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                >
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal leading-none opacity-70"
                  >
                    {head}
                  </Typography>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {studentDetails?.subjects?.map(({ subject, examCode }, index) => (
              <>
                <tr key={subject} className="even:bg-blue-gray-50/50">
                  <td className="p-4">
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal capitalize"
                    >
                      {examNames[subject]}
                    </Typography>
                  </td>
                  <td className="p-4">
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                      {"Not Announced"}
                    </Typography>
                  </td>
                  <td className="p-4">
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal capitalize"
                    >
                      {"Not Announced"}
                    </Typography>
                  </td>
                  <td className="p-4">
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal capitalize"
                    >
                      {"-"}
                    </Typography>
                  </td>

                  {accountType === "parent" && (
                    <td className="p-4">
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal capitalize"
                      >
                        {examCode}
                      </Typography>
                    </td>
                  )}

                  <td className="p-4">
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal capitalize"
                    >
                      <strong className="text-gray-500 cursor-default">
                        Result
                      </strong>
                    </Typography>
                  </td>
                </tr>
              </>
            ))}
          </tbody>
        </table>
      </Card>
      <div className="student-detail-card p-6 px-12">
        <div className="flex items-center justify-between">
          <h2 className="text-xl font-bold">Students details</h2>
          {accountType === "parent" && (
            <span
              className="flex items-center gap-2 cursor-pointer hover:text-[#009DFF] text-base"
              onClick={() => {
                navigate("edit");
                //GA event tracking
                GAEventTrack({
                  event: "parent_dshbrd_student_edit_btn",
                  data: {
                    uid: auth?.currentUser?.uid,
                    email: auth?.currentUser?.email,
                    studentId: studentDetails?.id,
                  },
                });
              }}
            >
              <MdEditSquare className="w-5 h-5" /> Edit Details
            </span>
          )}
        </div>
        <div className="row text-base items-start">
          <div className="col-md-4 flex flex-col my-3">
            <strong>Date of Birth:</strong>
            <span>
              {new Date(studentDetails?.dateOfBirth)?.toLocaleDateString(
                "en-IN",
                {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                }
              )}
            </span>
          </div>
          <div className="col-md-4 flex flex-col my-3">
            <strong>Parent's Email Id :</strong>
            <span>{studentDetails?.parent?.email}</span>
          </div>
          <div className="col-md-4 flex flex-col my-3">
            <strong>Student Id Proof : </strong>
            {studentDetails?.idProofUrl ? (
              <a
                href={studentDetails?.idProofUrl || "#"}
                target="_blank"
                rel="noreferrer"
                className="text-[#0080FF] font-semibold"
              >
                {fileMeta?.name + "." + fileMeta?.contentType.split("/")[1] ||
                  "Not Uploaded"}
              </a>
            ) : (
              "Not Uploaded"
            )}
          </div>
          <div className="col-md-4 flex flex-col my-3">
            <strong>Home Address :</strong>
            <div className="flex flex-col">
              {studentDetails?.homeAddress ? (
                <>
                  <span>{studentDetails?.homeAddress?.address}</span>
                  <span>
                    {studentDetails?.homeAddress?.city}
                    {", "}
                    {studentDetails?.homeAddress?.state}
                  </span>
                  <span>
                    {studentDetails?.homeAddress?.country}
                    {", "}
                    {studentDetails?.homeAddress?.pin}
                  </span>
                </>
              ) : (
                "Not Added"
              )}
            </div>
          </div>
          <div className="col-md-4 flex flex-col my-3">
            <strong>Parent's Phone No.</strong>
            <span>{studentDetails?.parent?.phone}</span>
          </div>
          <div className="col-md-4 flex flex-col my-3">
            <strong>Participant Photo</strong>
            <a
              href={studentDetails?.imageUrl || "#"}
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={studentDetails?.imageUrl || "/img/profile.png"}
                alt=""
                className="w-16 h-16 rounded-lg "
              />
            </a>
          </div>
          {accountType === "parent" && !studentDetails?.parent?.schoolId && (
            <>
              <div className="col-md-4 flex flex-col my-3">
                <strong>School Name</strong>
                <span>
                  {studentDetails?.schoolDetails?.schoolName || "Not Available"}
                </span>
              </div>
              <div className="col-md-4 flex flex-col my-3">
                <strong>School Addresss</strong>
                <address>
                  {studentDetails?.schoolDetails?.schoolAddress ? (
                    <div className="flex flex-wrap gap-2 w-2/3">
                      <span>
                        {studentDetails?.schoolDetails?.schoolAddress?.address},
                      </span>
                      <span>
                        {studentDetails?.schoolDetails?.schoolAddress?.city},
                      </span>
                      <span>
                        {studentDetails?.schoolDetails?.schoolAddress?.state},
                      </span>
                      <span>
                        {studentDetails?.schoolDetails?.schoolAddress?.country},
                      </span>
                      <span>
                        {studentDetails?.schoolDetails?.schoolAddress?.pin}
                      </span>
                    </div>
                  ) : (
                    "Not Available"
                  )}
                </address>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default StudentView;
