import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card, Typography } from "@material-tailwind/react";
import { FaChevronRight } from "react-icons/fa";
import DisplayTheOutput from "rich-text-editor-for-react/display-output";
import { FiEdit } from "react-icons/fi";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { Button } from "../../../components";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../../config/firebaseConfig";

const QuestionTable = ({ questions }) => {
  const opt = ["A", "B", "C", "D"];
  const tableHead = [
    "",
    "Question Name",
    "Class",
    "Subject",
    "Difficulty",
    "Action",
  ];

  const navigate = useNavigate();
  const [openQuestion, setOpenQuestion] = useState(false);
  const [confirmDeleteModule, setConfirmDeleteModule] = useState(false);

  const openEditModule = (e, id) => {
    e.stopPropagation();
    navigate("edit/" + id);
  };
  const openConfirmDeletModule = (e, id) => {
    e.stopPropagation();
    setConfirmDeleteModule(id);
  };

  const deleteQuestion = async (id) => {
    const questionRef = doc(db, "questions", id);
    try {
      await updateDoc(questionRef, {
        isDeleted: true,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setConfirmDeleteModule(false);
    }
  };

  return (
    <>
      <Card className="w-full overflow-y-scroll scrollable">
        <table className="w-full h-full min-w-max table-auto text-left">
          <thead className="">
            <tr>
              {tableHead.map((head, index) => (
                <th
                  key={index}
                  className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                >
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal leading-none opacity-70"
                  >
                    {head}
                  </Typography>
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="">
            {questions?.map(
              ({
                id,
                questionName,
                className,
                subject,
                difficulty,
                statement,
                options,
                correct,
                topics,
                subTopics,
                answerExplanation,
              }) => (
                <>
                  <tr
                    key={questionName}
                    className={`relative even:bg-blue-gray-50/50 cursor-pointer ${
                      openQuestion === id
                        ? "border-[2px] border-b-0 border-gray-300 bg-blue-gray-50/50"
                        : ""
                    }`}
                    onClick={() => {
                      openQuestion === id
                        ? setOpenQuestion(false)
                        : setOpenQuestion(id);
                    }}
                  >
                    <td className="p-4">
                      <FaChevronRight
                        className={`w-4 h-4 transition-all ${
                          openQuestion === id && "rotate-90"
                        }`}
                      />
                    </td>
                    <td className="p-4">
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        {questionName?.slice(0, 80)}
                        {questionName?.length > 80 ? "..." : ""}
                      </Typography>
                    </td>
                    <td className="p-4">
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        {className}
                        {className === 1
                          ? "st"
                          : className === 2
                          ? "nd"
                          : className === 3
                          ? "rd"
                          : "th"}
                      </Typography>
                    </td>
                    <td className="p-4">
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal capitalize"
                      >
                        {subject}
                      </Typography>
                    </td>
                    <td className="p-4">
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal capitalize"
                      >
                        {difficulty}
                      </Typography>
                    </td>
                    {/* <td className="p-4">
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        <span className="font-semibold">
                          {duration > 0 && `${Math.floor(duration)} min`}{" "}
                          {Math.round((duration * 60) % 60) !== 0 &&
                            `${Math.round((duration * 60) % 60)} sec`}
                        </span>
                      </Typography>
                    </td> */}
                    <td className="h-full flex gap-3 justify-center items-center">
                      {/* <span>
                        <FiEdit
                          className="w-7 h-7 p-1"
                          onClick={(e) => openEditModule(e, id)}
                        />
                      </span> */}
                      <span className="relative">
                        <MdOutlineDeleteOutline
                          className="w-8 h-8 text-red-500 p-1"
                          onClick={(e) => openConfirmDeletModule(e, id)}
                        />
                        {confirmDeleteModule === id && (
                          <div className="absolute -top-10 right-12 z-10 bg-white border-[1px] border-gray-300 rounded-xl shadow-md p-4 flex flex-col gap-2 justify-center items-center">
                            <div className="absolute top-[46px] -right-[10px] w-5 h-5 bg-white rotate-45 border-t-[2px] border-r-[2px] border-gray-300"></div>
                            <p className="text-center text-base">
                              Are you sure you want to delete this question?
                            </p>
                            <div className="flex gap-2 items-center">
                              <Button
                                version="gray"
                                text="Cancel"
                                width="120px"
                                handleClick={(e) => {
                                  e.stopPropagation();
                                  setConfirmDeleteModule(false);
                                }}
                              />
                              <Button
                                version="secondary"
                                text="Delete"
                                width="120px"
                                handleClick={(e) => {
                                  e.stopPropagation();
                                  deleteQuestion(id);
                                }}
                              />
                            </div>
                          </div>
                        )}
                      </span>
                    </td>
                  </tr>
                  {openQuestion === id && (
                    <tr className="border-2 border-gray-300 border-t-0">
                      <td
                        colSpan={tableHead.length}
                        className="py-2 px-10 space-y-2"
                      >
                        <div className="flex flex-wrap  gap-2 items-center">
                          <span className="text-base font-semibold">
                            Topics :
                          </span>
                          {topics?.map((topic) => (
                            <span
                              key={topic}
                              className="capitalize p-1 px-4 bg-gray-100 border-2 border-gray-200  rounded-full"
                            >
                              {topic}
                            </span>
                          ))}
                        </div>
                        <div className="flex flex-wrap  gap-2 items-center">
                          <span className="text-base font-semibold">
                            Sub-topics :
                          </span>
                          {subTopics?.map((topic) => (
                            <span
                              key={topic}
                              className="capitalize p-1 px-4 bg-gray-100 border-2 border-gray-200  rounded-full"
                            >
                              {topic}
                            </span>
                          ))}
                        </div>
                        <div className="rounded-xl overflow-hidden bg-[#eee] flex gap-2 pl-3 items-center">
                          <DisplayTheOutput
                            html={statement}
                            backgroundColor="#eee"
                            primaryColor="#20464b"
                          />
                        </div>
                        <div className="grid grid-cols-2 gap-2">
                          {options.map((option, index) => {
                            return (
                              <div
                                key={index}
                                className={`rounded-xl overflow-hidden flex gap-2 pl-3 items-center ${
                                  opt[correct - 1] === opt[index]
                                    ? "bg-[#cfd]"
                                    : "bg-[#eee]"
                                }`}
                              >
                                <span className="text-lg font-semibold">
                                  {opt[index]}.{" "}
                                </span>
                                <DisplayTheOutput
                                  html={
                                    option === "<p><br></p>" ||
                                    option === "<p></p>"
                                      ? `<p>Option ${opt[index]} </p>`
                                      : option
                                  }
                                  backgroundColor={
                                    opt[correct - 1] === opt[index]
                                      ? "#cfd"
                                      : "#eee"
                                  }
                                  primaryColor="#20464b"
                                />
                              </div>
                            );
                          })}
                        </div>
                        <div className="rounded-xl overflow-hidden bg-[#eee] flex flex-col p-3">
                          <p className="text-base font-semibold">
                            Answer Explanation :
                          </p>
                          <DisplayTheOutput
                            html={
                              answerExplanation === "<p><br></p>" ||
                              answerExplanation === "<p></p>"
                                ? "<p>Answer Explanation</p>"
                                : answerExplanation
                            }
                            backgroundColor="#eee"
                            primaryColor="#20464b"
                          />
                        </div>
                      </td>
                    </tr>
                  )}
                </>
              )
            )}
          </tbody>
        </table>
      </Card>
    </>
  );
};

export default QuestionTable;
