import React, { useEffect, useRef } from "react";
import ContactForm from "./ContactForm";
import AccordionPage from "../faq-fulldata";

const Faqlanding = () => {
  const contactFormRef = useRef(null);
  const setFormRef = (ref) => {
    contactFormRef.current = ref;
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <section className="faq-main-section">
      <div className="faq-data">
        <div className="container">
          <h3>Frequently Asked Questions - Practido Online Olympiad</h3>
          <p className="big">Why Practido Online Olympiad?</p>
          <p className="mgt10">
            Understanding the benefits of the Practido Online Olympiad is
            essential before offering these exams to your students. Here’s why
            it’s a valuable opportunity:
          </p>
          <ol className="order-list-data">
            <li>
              <span className="bold">Expert-Driven Assessment:</span> The
              Practido Online Olympiad is crafted by leading education experts,
              focusing on enhancing students' practical knowledge and their
              ability to apply skills across various subjects.
            </li>
            <li>
              <span className="bold">First-of-Its-Kind Online Format:</span> We
              are one of the pioneers in offering a fully online proctored
              Olympiad, allowing students to participate from the safety and
              comfort of their homes, ensuring a convenient and secure
              examination process.
            </li>
            <li>
              <span className="bold">
                No Special Preparation Material Needed:
              </span>{" "}
              While we do not provide specific preparation materials or sample
              papers, students can effectively prepare using their school
              textbooks.
            </li>
            <li>
              <span className="bold">Flexible Scheduling:</span> Unlike
              traditional Olympiads, students have the flexibility to choose
              their preferred time slot, making it easier to accommodate
              different schedules and time zones.
            </li>
            <li>
              <span className="bold">Subject Diversity:</span> Our Olympiads
              cover three key subjects: English, Maths, and Science, catering to
              a broad range of academic interests and strengths.
            </li>
            <li>
              <span className="bold">International Participation:</span> The
              Olympiad provides a platform for students to compete on an
              international level, expanding their horizons and exposure to a
              diverse set of competitors.
            </li>
          </ol>
          <p className="big">Registration and Participation</p>
          <div className="update-faq">
            <AccordionPage></AccordionPage>
          </div>
        </div>
      </div>
      <ContactForm contactFormRef={setFormRef} />
    </section>
  );
};

export default Faqlanding;
