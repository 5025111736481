import { sendEmailVerification } from "firebase/auth";
import { auth } from "../../config/firebaseConfig";
import envConfig from "../../config/envConfig";

export const sendVerificationEmail = async ({
  actionUrl,
  setLoading,
  setVerificationEmailSent,
}) => {
  const actionSettings = {
    url: `${envConfig?.appUrl}${actionUrl}`,
    handleCodeInApp: true,
  };
  setLoading((prev) => ({ ...prev, resend: true }));
  sendEmailVerification(auth.currentUser, actionSettings)
    .then(() => {
      setVerificationEmailSent(true);
    })
    .catch((error) => {
      console.log(error.message);
      throw error;
    })
    .finally(() => setLoading((prev) => ({ ...prev, resend: false })));
};
