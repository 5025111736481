import React from 'react'
import { SyllabusTopics } from '../../constant/SyllabusTopic'

const SyllabusSection = () => {
    return (
        <section className="topic-section syllabus-section" id="subjects">
            <div className="container aos-animate" data-aos="fade-up">
                <div className="head" data-aos="fade-up" data-aos-delay="150" data-aos-duration="500">
                    <h3>Syllabus - Practido Online Olympiad, 2024</h3>
                </div>
                <div className="tabs-pills subject-tab-main">
                    <div className="scrolling-tabs-container wrapper">
                        <ul className="scrolling-tabs nav nav-pills mb-3 list" id="pills-tab" role="tablist">
                            {
                                SyllabusTopics?.map((subject, index) => (
                                    <li
                                        key={subject.subjectId}
                                        data-aos="zoom-in"
                                        data-aos-delay={`${((index + 1) * 100)}`}
                                        data-aos-duration={`${500 + ((index) * 100)}`}
                                        className="nav-item"
                                        role="presentation"
                                    >
                                        <button
                                            className={`nav-link ${index === 0 && 'active'}`}
                                            id={`subject-tab-${index + 1}`}
                                            data-toggle="pill"
                                            data-target={`#${subject.subjectName}-sub`}
                                            type="button"
                                            role="tab"
                                            aria-controls={`${subject.subjectName}-sub`}
                                            aria-selected="true"
                                        >
                                            <span className='capitalize'>
                                                {subject.subjectName}
                                            </span>
                                        </button>
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                </div>

                <div className="tab-content" id="pills-sub-tab">
                    {
                        SyllabusTopics?.map((subject, index) => (
                            <div
                                key={subject.subjectId}
                                className={`tab-pane fade show ${index === 0 && 'active'}`}
                                id={`${subject.subjectName}-sub`}
                                role="tabpanel"
                                aria-labelledby={`${subject.subjectName}-sub`}
                            >
                                <div className="tabs-pills">
                                    <div className="scrolling-tabs-container wrapper">
                                        <ul className="scrolling-tabs nav nav-pills mb-3 list" id="pills-tab" role="tablist">
                                            {
                                                subject.classTopics?.map((classes, index) => (
                                                    <li
                                                        key={classes.classId}
                                                        data-aos="zoom-in"
                                                        data-aos-delay={`${((index + 1) * 100)}`}
                                                        data-aos-duration={`${500 + ((index) * 100)}`}
                                                        className="nav-item"
                                                        role="presentation"
                                                    >
                                                        <button
                                                            className={`nav-link ${index === 0 && 'active'}`}
                                                            id={`m-pills-tab-${index + 1}`}
                                                            data-toggle="pill"
                                                            data-target={`#${subject.subjectName}-tab-${index + 1}`}
                                                            type="button"
                                                            role="tab"
                                                            aria-controls={`${subject.subjectName}-tab-${index + 1}`}
                                                            aria-selected="true"
                                                        >
                                                            <span className='capitalize'>
                                                                {classes.class}
                                                            </span>
                                                        </button>
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                    </div>
                                    <div className="button-wrapper">
                                        <button type="button" className="btn btn-secondary move-tab" id="prevButton" data-direction="left"><img src="/img/arrow.png" alt="tab-left-arrow" /></button>
                                        <button type="button" className="btn btn-secondary move-tab" id="nextButton" data-direction="right"><img src="/img/arrow.png" alt="tab-right-arrow" /></button>
                                    </div>
                                </div>

                                <div className="tab-content" id="pills-tabContent">
                                    {
                                        subject?.classTopics?.map((classes, index) => (
                                            <div
                                                key={classes.classId}
                                                className={`tab-pane fade show ${index === 0 && 'active'}`}
                                                id={`${subject.subjectName}-tab-${index + 1}`}
                                                role="tabpanel"
                                                aria-labelledby={`pill-tab-${index + 1}`}
                                            >
                                                {classes?.topics.length > 0 && <ul className="list-group">
                                                    {classes?.topics.length > 0 &&
                                                        classes?.topics?.map((topic, index) => (
                                                            <li key={topic.id} className="list-group-item">
                                                                <div className="img-box">
                                                                    <img src={`${topic.img}`} alt={topic} />
                                                                </div>
                                                                <span>
                                                                    {topic.name}
                                                                </span>
                                                            </li>
                                                        ))
                                                    }
                                                </ul>}
                                                <div>{classes.content}</div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </section>
    )
}

export default SyllabusSection