import React, { useEffect, useRef } from "react";
import ContactForm from "./ContactForm";
import SyllabusSection from "./Syllabus";

const Syllabuslanding = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const contactFormRef = useRef(null);
  const setFormRef = (ref) => {
    contactFormRef.current = ref;
  };

  return (
    <section className="syllabus-section">
      <SyllabusSection />
      <ContactForm contactFormRef={setFormRef} />
    </section>
  );
};

export default Syllabuslanding;
