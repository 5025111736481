import React, { useEffect, useRef } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Header from "../../landingPage/Header";
import Footer from "../../landingPage/Footer";
import ContactForm from "../../landingPage/ContactForm";
import SliderRating from "../../landingPage/SliderRating";
import OlympiadExams from "../OlympiadExams";
import { Button } from "../../../components";

const LoginLayout = () => {
  const navigate = useNavigate();
  const contactFormRef = useRef(null);
  const setFormRef = (ref) => {
    contactFormRef.current = ref;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Header placement="register" />
      <section className="school-registration sign-in-account">
        <div className="form-container">
          <div className="container-fluid">
            <div className="row order-change">
              <div className="col-md-12 col-lg-6 left-item">
                <Outlet />
              </div>
              <div className="col-md-12 col-lg-6 right-item flex flex-col gap-4 items-center justify-center">
                <img
                  className="banner-cloud"
                  src="/img/new-home/cloud-banner-common.png"
                  alt="banner"
                />
                <div>
                  <Button
                    version="red-register-btn primary-btn"
                    text="Register Now"
                    width="200px"
                    handleClick={() => navigate("/register")}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <OlympiadExams />
      <SliderRating contactFormRef={contactFormRef} />
      <ContactForm contactFormRef={setFormRef} />
      <Footer />
    </div>
  );
};

export default LoginLayout;
