import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom'
import AboutSection from './AboutSection'
import ContactForm from './ContactForm'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


//new about page

const StudentLandingPage = () => {

  const navigate = useNavigate();

  const contactFormRef = useRef(null);
  const setFormRef = (ref) => {
    contactFormRef.current = ref;
  };

  const [activeSection, setActiveSection] = useState('');

  useEffect(() => {
    const handleScroll = () => {
      const curPos = window.scrollY;
      const sections = document.querySelectorAll('.feature-wrapper');
      const navLinks = document.querySelectorAll('nav a');

      sections.forEach(section => {
        const top = section.offsetTop;
        const bottom = top + section.offsetHeight;

        if (curPos >= top && curPos <= bottom) {
          setActiveSection(section.id);
        }
      });

      navLinks.forEach(link => {
        const sectionId = link.getAttribute('href').slice(1);

        if (sectionId === activeSection) {
          link.classList.add('active');
        } else {
          link.classList.remove('active');
        }
      });

    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [activeSection]);

  const scrollToSection = id => {
    const section = document.querySelector(id);

    window.scrollTo({
      top: section.offsetTop,
      behavior: 'smooth',
    });
  };

  return (
    <>

      <section className="banner-section home-banner-section about-banner">
        <div className='container'>
          <div className='row'>
            <div className='col-md-12 col-lg-6 order-change left-item' data-aos="fade-right" data-aos-delay="1000">
              <h3>Let's Make Learning Awesome Together!</h3>
              <p>Discover Practido: Where Learning Becomes an Adventure! Engage, Learn, and Succeed with our innovative approach to practice and learning</p>
              <h4>Free Learning Starts Now!</h4>
              <p>Download App:</p>
              <div className='btn-inline'>
                <a target="_blank" href="https://play.google.com/store/apps/details?id=com.naskay.olympiadpractice" type="button" className="btn btn-link">
                  <img
                    src="/img/student-home/google-play.png"
                    alt="google-play"
                  />
                </a>
              </div>
            </div>
            <div className='col-md-12 col-lg-6' data-aos="fade-left" data-aos-delay="1000">
              <img className="about-banner-img" src="/img/new-home/about-banner.png" alt="banner" />
            </div>
          </div>
        </div>

      </section>

      <AboutSection />

      <section className='student-vision'>
        <div className='container'>
          <h3>Our Vision</h3>
          <p>Our vision at Practido is straightforward yet transformative: to make practice fun! We envision a future where every student embraces learning as a thrilling journey, not a mundane obligation. By focusing on practical skills and personalized learning experiences, we aim to empower students to excel academically and beyond. Practido is committed to redefining education by fostering a love for learning and equipping students with the tools they need to succeed in an ever-changing world.</p>
        </div>
      </section>

      <ContactForm contactFormRef={setFormRef} />

    </>
  )
}

export default StudentLandingPage