export const pdfStyles = {
  page: {
    // padding: 20,
    fontFamily: "Helvetica",
    position: "relative",
    // backgroundColor: "#1C214D",
  },
  whitecontainer: {
    padding: "20",
    width: "73%",
    marginTop: "10",
    marginBottom: "60",
    position: "relative",
  },
  schoolLogoSection: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flexStart",
    padding: 10,
  },

  schoolLogo: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flexStart",
    gap: 10,
    marginTop: "15",
    marginBottom: "0",
    position: "relative",
    marginLeft: "-50px",
  },
  schoolLogo_img: {
    width: 40,
    height: 40,
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
  },
  schoolDetails: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  schoolName: {
    fontSize: "14",
    fontWeight: "bold",
  },

  schoolAddress: {
    fontSize: 8,
  },
  qrContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 5,
    marginBottom: "0",
    marginTop: "15",
  },
  qrFrameBox: {
    position: "relative",
    left: "-4",
    width: 150,
    height: 150,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  qrCodeImage: {
    width: "120",
    height: "120",
    position: "relative",
    top: "-7",
  },
  qrFrame: {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    zIndex: "1",
  },
  content: {
    marginTop: 4,
    padding: 5,
    fontSize: 10,
    lineHeight: 1,
  },
  title: {
    fontSize: 9,
    fontWeight: "bold",
    marginTop: 7,
    marginBottom: 3,
    color: "#34495E",
  },
  logo: {
    width: 150,
    height: 100,
    position: "absolute",
    right: 0,
    top: 0,
  },
  registrationUrlBox: {
    fontSize: 8,
    width: 300,
    height: "70",
    color: "rgba(0, 30, 59, 0.7)",
    backgroundColor: "#DBF2F7",
    padding: "10",
    borderRadius: 5,
    display: "flex",
    alignItems: "flex-start",
  },
  paragraph: {
    fontSize: 7,
    lineHeight: "1.6",
    textAlign: "left", // Optional: adjust based on your layout
    color: "rgba(52, 73, 94, 0.8)",
  },
  boldUrlText: {
    fontWeight: "bold",
    color: "#001E3B",
  },
  linkBox: {
    backgroundColor: "#000",
    display: "inine-block",
    marginTop: "10",
    wordBreak: "breakAll",
    padding: "20",
    height: "40",
    borderRadius: "5",
  },
  linkColor: {
    color: "#000",
    padding: "10 10",
    backgroundColor: "red",
    textDecorationLine: "none",
    display: "block",
    height: "40",
    width: "100%",
  },
  boldText: {
    fontWeight: "bold",
    color: "#34495E",
    fontSize: 7,
  },
  footer: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "#1C214D", // Blue background color
    color: "#fff", // White text color
    padding: "6 10 6 10",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  footerImage: {
    width: 50,
    height: 30,
  },
  footerIcon: {
    width: 55,
    height: "auto",
    marginRight: 5,
    //backgroundColor: "#90f5eb",
    //borderRadius: 50,
  },
  footerContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  footerContent: {
    flexDirection: "row",
    alignItems: "center",
  },
  footerText: {
    fontSize: 10,
    color: "#fff",
  },
  footerdownload: {
    width: 160,
    height: 150,
    marginBottom: 30,
    marginRight: 0,
    position: "relative",
    left: "-15px",
  },
  footerQrScanner: {
    position: "absolute",
    top: -40,
    right: 10,
    width: 130,
    height: 185,
  },
  schoolLogoImage: {
    width: 80,
    height: 80,
    borderRadius: "50%",
  },
  qrCode: {
    width: 50,
    height: 50,
  },
  footer2: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    width: "100%",
    backgroundColor: "#6B50E4",
    color: "#fff",
    padding: 20,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flexStart",
    paddingTop: 20,
  },
  paragraph2: {
    fontSize: 7,
    lineHeight: 1.5,
    color: "rgba(255, 255, 255, 0.8)",
    paddingBottom: 30,
    marginRight: 40,
    width: "260px",
    fontWeight: "normal",
  },
  boldText2: {
    fontWeight: "bold",
    color: "white",
    fontSize: 8,
  },
};
