import React, { useRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ContactForm from './ContactForm'
import MointerStudent from "./MointerStudent";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { Button, TextInput, Checkbox } from "../../components";
import PhoneInput from "react-phone-input-2";

import gsap from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const ParentLandingPage = () => {
  const [checked, setChecked] = useState(false);
  const navigate = useNavigate();
  const devMode = false;



  const contactFormRef = useRef(null);
  const setFormRef = (ref) => {
    contactFormRef.current = ref;
  };

  useEffect(() => {
    gsap.to("#boy", {
      duration: 1,
      y: "-=12",
      ease: "Power1.easeInOut",
      yoyo: true,
      repeat: -1,
      delay: 0.5,
    });
  }, []);


  // back to top button set
  useEffect(() => {
    const handleScroll = () => {
      const scrollToTopBtn = document.querySelector(".scrollToTopBtn");
      const rootElement = document.documentElement;

      if (rootElement.scrollTop >= 300) {
        scrollToTopBtn.classList.add("showBtn");
      } else {
        scrollToTopBtn.classList.remove("showBtn");
      }
    };

    const scrollToTop = () => {
      document.documentElement.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };

    const scrollToTopBtn = document.querySelector(".scrollToTopBtn");
    scrollToTopBtn.addEventListener("click", scrollToTop);
    document.addEventListener("scroll", handleScroll);

    return () => {
      scrollToTopBtn.removeEventListener("click", scrollToTop);
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>

      <div className="back-to-top">
        <button className="scrollToTopBtn">
          <img src="/img/new-home/back-to-top.png" alt="back-to-top" />
        </button>
      </div>

      <section className='parent-banner educator-back'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12 col-lg-6 order-change' data-aos="fade-right" data-aos-delay="50" data-aos-duration="500">
              <h3>Making Life Easier for Teachers</h3>
              <p>With Practido, teachers can focus on productive and innovative work instead of repetitive tasks, allowing them to concentrate on students who need the most attention. This enhances creativity in teaching and improves the ability to explain concepts effectively.</p>
              <div className='btn-inline'>
                <a href="https://calendly.com/shilpi-naskay/30min?month=2023-05" target="_blank" type='btn' className='btn primary-btn'>Book an Appointment</a>
              </div>
            </div>
            <div className='col-md-12 col-lg-6' data-aos="fade-left" data-aos-delay="50" data-aos-duration="500">
              <div className="educator-img">
                <img className="educator-banner" src="/img/educator-home/educator-banner.png" alt="banner" />
                <img id="boy" className="boy" src="/img/educator-home/boy.png" alt="banner-boy" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="parent-our-feature educatour-feature" id="features">
        <div className="container" data-aos="fade-up">
          <div className="container top-head" data-aos="fade-up" data-aos-delay="150" data-aos-duration="500">
            <h3>We Designed our Platform to Simplify Teaching Tasks</h3>
          </div>
          <div className="row aos-init aos-animate" data-aos="fade-up">
            <div className="col-md-6 aos-init" data-aos="fade-right" data-aos-delay="150">
              <div className="img-box"><img src="/img/educator-home/educator-feature-1.png" alt="images" /></div>
            </div>
            <div className="col-md-6 aos-init" data-aos="fade-left" data-aos-delay="150">
              <div className="content">
                <h3>Create and Customize Your Content</h3>
                <ul className="bullet-list-data">
                  <li>Share your expertise with videos and documents.</li>
                  <li>Create materials from scratch.</li>
                  <li>Build custom assessments to meet your teaching goals.</li>
                </ul>
              </div>
            </div>
          </div>
          {/* row */}
          <div className="row order-change aos-init aos-animate" data-aos="fade-up">
            <div className="col-md-6 aos-init" data-aos="fade-right" data-aos-delay="150">
              <div className="content">
                <h3>Expanded Question Library</h3>
                <ul className="bullet-list-data">
                  <li>Access many subjects and topics.</li>
                  <li>Utilize a wide range of pre-built assessments.</li>
                  <li>Create personalized assessments easily.</li>
                  <li>Simplify test creation.</li>
                  <li>Boost classroom engagement.</li>
                </ul>
              </div>
            </div>
            <div className="col-md-6 aos-init" data-aos="fade-left" data-aos-delay="150">
              <div className="img-box"><img src="/img/educator-home/educator-feature-2.png" alt="images" /></div>
            </div>
          </div>
          {/* row */}
          <div className="row aos-init aos-animate" data-aos="fade-up">
            <div className="col-md-6 aos-init" data-aos="fade-right" data-aos-delay="150">
              <div className="img-box"><img src="/img/educator-home/educator-feature-3.png" alt="images" /></div>
            </div>
            <div className="col-md-6 aos-init" data-aos="fade-left" data-aos-delay="150">
              <div className="content">
                <h3>Teacher Dashboard</h3>
                <ul className="bullet-list-data">
                  <li>Easily monitor student enrollments.</li>
                  <li>Track student progress.</li>
                  <li>Simplify class management.</li>
                  <li>Focus on helping students learn.</li>
                </ul>
              </div>
            </div>
          </div>
          {/* row */}
          <div className="row order-change aos-init aos-animate" data-aos="fade-up">
            <div className="col-md-6 aos-init" data-aos="fade-right" data-aos-delay="150">
              <div className="content">
                <h3>Use AI to Streamline Your Work</h3>
                <ul className="bullet-list-data">
                  <li>Upload pictures, PDFs, or documents for AI to create questions.</li>
                  <li>Make one question and let AI generate similar ones.</li>
                  <li>Set question difficulty to easy, medium, or hard.</li>
                </ul>
              </div>
            </div>
            <div className="col-md-6 aos-init" data-aos="fade-left" data-aos-delay="150">
              <div className="img-box"><img src="/img/educator-home/educator-feature-4.png" alt="images" /></div>
            </div>
          </div>



        </div>
      </section>


      <MointerStudent />


      <ContactForm contactFormRef={setFormRef} />


      {/* Book Appointment Modal */}
      <div className="modal fade" id="book-appointment" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog book-appointment">
          <div className="modal-content">
            <div className="modal-body">
              <h5 className="modal-title" id="exampleModalLabel">Book an Appointment</h5>
              <button type="button" className="close btn-close" data-dismiss="modal" aria-label="Close">
                <img src="/img/close-icon.png" alt="close" />
              </button>
              <div className="container">
                <div className="col-offset-1 col-md-10">
                  <form className="flex flex-col">
                    <div className="flex flex-col md:flex-col">
                      <div className="form-group">
                        <label htmlFor="Name">
                          Name<sup>*</sup>
                        </label>
                        <TextInput
                          type="text"
                          placeholderText="Name"
                          inputName="Name"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="email">
                          Email<sup>*</sup>
                        </label>
                        <TextInput
                          type="email"
                          placeholderText="Email"
                          inputName="email"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="phone">
                          Phone Number<sup>*</sup>
                        </label>
                        <PhoneInput
                          country="in"
                          defaultCountry=""
                          placeholder="Enter Phone Number"
                        />
                        <div className="text-[#f00] text-xs h-3 mb-1">
                          {/* {phoneError} */}
                        </div>
                      </div>
                      <div className="form-group checkbox-row">
                        <label htmlFor="Subject">
                          Subject<sup>*</sup>
                        </label>
                        <div className="checkbox-wrapper flex flex-col flex-row flex-wrap">
                          <div className="col-md-12 col-lg-4 flex items-center">
                            <Checkbox
                              checkId="Maths"
                              handleChange={() => setChecked(!checked)}
                              labelText=""
                            />
                            <label
                              htmlFor="Maths"
                              className="font-bold text-lg cursor-pointer pl-[5px] text-[#F68B29]"
                            >
                              Maths
                            </label>
                          </div>
                          <div className="col-md-12 col-lg-4 flex items-center">
                            <Checkbox
                              checkId="Science"
                              handleChange={() => setChecked(!checked)}
                              labelText=""
                            />
                            <label
                              htmlFor="Science"
                              className="font-bold text-lg cursor-pointer pl-[5px] text-[#F68B29]"
                            >
                              Science
                            </label>
                          </div>
                          <div className="col-md-12 col-lg-4 flex items-center">
                            <Checkbox
                              checkId="English"
                              handleChange={() => setChecked(!checked)}
                              labelText=""
                            />
                            <label
                              htmlFor="English"
                              className="font-bold text-lg cursor-pointer pl-[5px] text-[#F68B29]"
                            >
                              English
                            </label>
                          </div>
                        </div>

                        <div className="checkbox-wrapper flex flex-col flex-row flex-wrap">
                          <div className="col-md-12 col-lg-4 flex items-center">
                            <Checkbox
                              checkId="Computers"
                              handleChange={() => setChecked(!checked)}
                              labelText=""
                            />
                            <label
                              htmlFor="Computers"
                              className="font-bold text-lg cursor-pointer pl-[5px] text-[#F68B29]"
                            >
                              Computers
                            </label>
                          </div>
                          <div className="col-md-12 col-lg-4 flex items-center">
                            <Checkbox
                              checkId="AI"
                              handleChange={() => setChecked(!checked)}
                              labelText=""
                            />
                            <label
                              htmlFor="AI"
                              className="font-bold text-lg cursor-pointer pl-[5px] text-[#F68B29]"
                            >
                              AI
                            </label>
                          </div>
                          <div className="col-md-12 col-lg-4 flex items-center">
                            <Checkbox
                              checkId="Robotics"
                              handleChange={() => setChecked(!checked)}
                              labelText=""
                            />
                            <label
                              htmlFor="Robotics"
                              className="font-bold text-lg cursor-pointer pl-[5px] text-[#F68B29]"
                            >
                              Robotics
                            </label>
                          </div>
                        </div>

                        <div className="checkbox-wrapper flex flex-col flex-row flex-wrap">
                          <div className="col-md-12 col-lg-4 flex items-center">
                            <Checkbox
                              checkId="Graphic"
                              handleChange={() => setChecked(!checked)}
                              labelText=""
                            />
                            <label
                              htmlFor="Graphic"
                              className="font-bold text-lg cursor-pointer pl-[5px] text-[#F68B29]"
                            >
                              Graphic Design
                            </label>
                          </div>
                          <div className="col-md-12 col-lg-4 flex items-center">
                            <Checkbox
                              checkId="UIUX"
                              handleChange={() => setChecked(!checked)}
                              labelText=""
                            />
                            <label
                              htmlFor="UIUX"
                              className="font-bold text-lg cursor-pointer pl-[5px] text-[#F68B29]"
                            >
                              UI UX
                            </label>
                          </div>
                          <div className="col-md-12 col-lg-4 flex items-center">
                            <Checkbox
                              checkId="Video"
                              handleChange={() => setChecked(!checked)}
                              labelText=""
                            />
                            <label
                              htmlFor="Video"
                              className="font-bold text-lg cursor-pointer pl-[5px] text-[#F68B29]"
                            >
                              Video Editing
                            </label>
                          </div>
                        </div>

                        <div className="checkbox-wrapper flex flex-col flex-row flex-wrap">
                          <div className="col-md-12 col-lg-4 flex items-center">
                            <Checkbox
                              checkId="Coding"
                              handleChange={() => setChecked(!checked)}
                              labelText=""
                            />
                            <label
                              htmlFor="Coding"
                              className="font-bold text-lg cursor-pointer pl-[5px] text-[#F68B29]"
                            >
                              Coding
                            </label>
                          </div>
                          <div className="col-md-12 col-lg-4 flex items-center">
                            <Checkbox
                              checkId="Accounts"
                              handleChange={() => setChecked(!checked)}
                              labelText=""
                            />
                            <label
                              htmlFor="Accounts"
                              className="font-bold text-lg cursor-pointer pl-[5px] text-[#F68B29]"
                            >
                              Accounts
                            </label>
                          </div>
                        </div>

                        <label htmlFor="availability" className="mgt10">
                          Please tell us your availability for a call or meeting <sup>*</sup>
                        </label>
                        <div className="checkbox-wrapper flex flex-col flex-row flex-wrap">
                          <div className="col-md-12 col-lg-4 col-lg-4 flex items-center">
                            <Checkbox
                              checkId="All"
                              handleChange={() => setChecked(!checked)}
                              labelText=""
                            />
                            <label
                              htmlFor="All"
                              className="font-bold text-lg cursor-pointer pl-[5px] text-[#F68B29]"
                            >
                              All
                            </label>
                          </div>
                          <div className="col-md-12 col-lg-4 col-lg-4 flex items-center">
                            <Checkbox
                              checkId="Monday"
                              handleChange={() => setChecked(!checked)}
                              labelText=""
                            />
                            <label
                              htmlFor="Monday"
                              className="font-bold text-lg cursor-pointer pl-[5px] text-[#F68B29]"
                            >
                              Monday
                            </label>
                          </div>
                          <div className="col-md-12 col-lg-4 col-lg-4 flex items-center">
                            <Checkbox
                              checkId="Tuesday"
                              handleChange={() => setChecked(!checked)}
                              labelText=""
                            />
                            <label
                              htmlFor="Tuesday"
                              className="font-bold text-lg cursor-pointer pl-[5px] text-[#F68B29]"
                            >
                              Tuesday
                            </label>
                          </div>
                        </div>
                        <div className="checkbox-wrapper flex flex-col flex-row flex-wrap">
                          <div className="col-md-12 col-lg-4 col-lg-4 flex items-center">
                            <Checkbox
                              checkId="Wednesday"
                              handleChange={() => setChecked(!checked)}
                              labelText=""
                            />
                            <label
                              htmlFor="Wednesday"
                              className="font-bold text-lg cursor-pointer pl-[5px] text-[#F68B29]"
                            >
                              Wednesday
                            </label>
                          </div>
                          <div className="col-md-12 col-lg-4 col-lg-4 flex items-center">
                            <Checkbox
                              checkId="Thursday"
                              handleChange={() => setChecked(!checked)}
                              labelText=""
                            />
                            <label
                              htmlFor="Thursday"
                              className="font-bold text-lg cursor-pointer pl-[5px] text-[#F68B29]"
                            >
                              Thursday
                            </label>
                          </div>
                          <div className="col-md-12 col-lg-4 col-lg-4 flex items-center">
                            <Checkbox
                              checkId="Friday"
                              handleChange={() => setChecked(!checked)}
                              labelText=""
                            />
                            <label
                              htmlFor="Friday"
                              className="font-bold text-lg cursor-pointer pl-[5px] text-[#F68B29]"
                            >
                              Friday
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <TextInput
                          type="input"
                          placeholderText="Any specific time to contact"
                          inputName="specific contact"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="qualifications">
                          Briefly describe your experience or qualifications in education
                        </label>
                        <TextInput
                          type="input"
                          placeholderText=""
                          inputName="qualifications"
                        />
                      </div>
                      <div className="form-group flex items-center">
                        <Checkbox
                          checkId="offerings"
                          handleChange={() => setChecked(!checked)}
                          labelText=""
                        />
                        <label
                          htmlFor="offerings"
                          className="text-sm cursor-pointer pl-[5px] text-[#F68B29]"
                        >
                          Sign up for updates on new offerings and opportunities (via email or WhatsApp
                          group).
                        </label>
                      </div>

                    </div>


                    <div className="flex flex-col">
                      <div className="text-[#f00] text-xs h-3 mb-1">
                        {/* {otherError} */}
                      </div>
                      <Button
                        version="secondary"
                        text="Book an Appointment"
                        type="button"
                        className="appointment-btn"
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
            {/* modal-body */}

          </div>
        </div>
      </div>

    </>
  )
}

export default ParentLandingPage;
