import React, { useState } from "react";
import { Button } from "../../../components";
import { FaGreaterThan } from "react-icons/fa6";
import Instructions from "./Instructions";
import { IoClose } from "react-icons/io5";
import envConfig from "../../../config/envConfig";

const CommonBanner = () => {
  const [instructionsOpen, setInstructionsOpen] = useState(false);
  return (
    <>
      <section className="common-banner">
        <div className="container">
          <div className="row">
            <div className="col-lg-9 col-md-12 space-y-8">
              <nav className="flex flex-col md:flex-row justify-center md:justify-start md:items-center text-base">
                <span className="underline flex items-center">
                  Practido Challenge
                  <FaGreaterThan className="w-3 h-3 mx-2" />
                </span>
                <span></span>
                <span className="font-bold">
                  {envConfig.appFullName} Registration
                </span>
              </nav>
              <div className="space-y-2">
                <p className="font-medium">
                  Register your kid for Practido Online Olympiad
                </p>
                <Button
                  version="black"
                  text="View Full Instructions"
                  width="220px"
                  handleClick={() => setInstructionsOpen(true)}
                />
              </div>

              {/* <ol>
              <li>Join in 15 min before Challenge Start time.</li>
              <li>
                It's an online competition open to all the students of class 1
                to 6.
              </li>
              <li>
                You can participate in this competition from the comfort of your
                home. You need a laptop/desktop/tablet with web-camera,
                microphone and live good internet connection.
              </li>
              <li>
                Participants should ensure that the entries they are submitting
                are correct.
              </li>
              <li>
                School Name & Participant Name should be mentioned as you would
                like them to appear on the e-certificate, if applicable.
              </li>
            </ol>
            <h3>Online Young Mind Competition Exam Dates:</h3>
            <p>
              October onwards.
              <br />
              Last Date for Registration: Jan 15th 2023 (Limited seats
              available).
            </p> */}
            </div>
          </div>
        </div>
      </section>
      {instructionsOpen && (
        <dialog className="fixed top-0 left-0 w-screen bg-black/20 backdrop-blur-[2px] h-screen overflow-y-scroll z-50 flex justify-center pt-24">
          <div className="relative detail-section instructions-section">
            <button
              className="absolute top-3 right-4 bg-gray-300 rounded-full p-2"
              onClick={() => setInstructionsOpen(false)}
            >
              <IoClose className="w-5 h-5" />
            </button>
            <Instructions />
          </div>
        </dialog>
      )}
    </>
  );
};

export default CommonBanner;
