import React, { useEffect, useRef, useState } from "react";
import { Button, SelectInput, TextInput } from "../../../components";
import {
  homeDetailsFields,
  participantDetailsfeilds,
  schoolDetailsFields,
} from "../constants/editStudentFields";
import { doc, serverTimestamp, updateDoc } from "firebase/firestore";
import { auth, db, storage } from "../../../config/firebaseConfig";
import { MdDelete } from "react-icons/md";
import { FiCamera } from "react-icons/fi";
import { deleteObject, getMetadata, ref } from "firebase/storage";
import ImageCapture from "../../auth/onboarding/ImageCapture";
import FileUpload from "../../auth/onboarding/FileUpload";
import { useNavigate } from "react-router-dom";
import {
  allCities,
  allCountries,
  allStates,
} from "../../../constant/countryStateCityList";
import { GAEventTrack } from "../../../hooks/GA_events";

const EditStudent = ({ studentDetails }) => {
  const navigate = useNavigate();
  const [participantDetails, setParticipantDetails] = useState(null);
  const [loading, setLoading] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  useEffect(() => {
    if (studentDetails) {
      const participantData = {
        name: studentDetails?.name || "",
        dateOfBirth: studentDetails?.dateOfBirth || "",
        homeAddress: studentDetails?.homeAddress?.address || "",
        homePincode: studentDetails?.homeAddress?.pin || "",
        schoolStudent: studentDetails?.parent?.schoolId ? true : false,
        schoolId: studentDetails?.schoolDetails?.schoolId || "",
        schoolName: studentDetails?.schoolDetails?.schoolName || "",
        schoolAddress:
          studentDetails?.schoolDetails?.schoolAddress?.address || "",
        schoolPincode: studentDetails?.schoolDetails?.schoolAddress?.pin || "",
        imageUrl: studentDetails?.imageUrl || "",
        idProofUrl: studentDetails?.idProofUrl || "",
      };
      setParticipantDetails(participantData);
    }
  }, [studentDetails, setParticipantDetails]);

  // address states
  const [homeCountry, setHomeCountry] = useState();
  const [homeState, setHomeState] = useState("");
  const [homeCity, setHomeCity] = useState("");
  const [schoolCountry, setSchoolCountry] = useState("");
  const [schoolState, setSchoolState] = useState("");
  const [schoolCity, setSchoolCity] = useState("");
  useEffect(() => {
    setHomeCountry(
      allCountries.find(
        (country) => country.value === studentDetails?.homeAddress?.country
      )
    );
    setSchoolCountry(
      allCountries.find(
        (country) =>
          country.value ===
          studentDetails?.schoolDetails?.schoolAddress?.country
      )
    );
  }, [studentDetails]);
  useEffect(() => {
    setHomeState(
      allStates(homeCountry?.id).find(
        (state) => state.value === studentDetails?.homeAddress?.state
      )
    );
    setSchoolState(
      allStates(schoolCountry?.id).find(
        (state) =>
          state.value === studentDetails?.schoolDetails?.schoolAddress?.state
      )
    );
  }, [studentDetails, homeCountry, schoolCountry]);
  useEffect(() => {
    setHomeCity(
      allCities(homeCountry?.id, homeState?.id).find(
        (city) => city.value === studentDetails?.homeAddress?.city
      )
    );
    setSchoolCity(
      allCities(schoolCountry?.id, schoolState?.id).find(
        (city) =>
          city.value === studentDetails?.schoolDetails?.schoolAddress?.city
      )
    );
  }, [studentDetails, homeCountry, homeState, schoolCountry, schoolState]);

  useEffect(() => {
    setHomeState(
      allStates(homeCountry?.id).find(
        (state) => state.value === studentDetails?.homeAddress?.state
      )
    );
  }, [studentDetails, homeCountry]);
  useEffect(() => {
    setHomeCity(
      allCities(homeCountry?.id, homeState?.id).find(
        (city) => city.value === studentDetails?.homeAddress?.city
      )
    );
  }, [studentDetails, homeCountry, homeState]);

  const [imageCaptureModule, setImageCaptureModule] = useState(false);
  const [uploadImage, setUploadImage] = useState(null);
  const [imageUploading, setImageUploading] = useState(false);
  const [idProofUploading, setIdProofUploading] = useState(false);
  const [imageUploadProgress, setImageUploadProgress] = useState(0);

  const [studentDocRef, setStudentDocRef] = useState(null);
  useEffect(() => {
    if (studentDetails) {
      const ref = doc(db, "participants", studentDetails?.enrollmentNo);
      setStudentDocRef(ref);
    }
  }, [studentDetails]);

  const updateUrl = async (type, url) => {
    if (type === "image") {
      await handleImageDelete();
      setError((prev) => ({ ...prev, image: "" }));
      setParticipantDetails((prev) => ({ ...prev, imageUrl: url }));
      await updateDoc(studentDocRef, { imageUrl: url });
    }
    if (type === "idProof") {
      setError((prev) => ({ ...prev, idProof: "" }));
      setParticipantDetails((prev) => ({ ...prev, idProofUrl: url }));
      await updateDoc(studentDocRef, { idProofUrl: url });
    }
  };

  const updateRef = (type, ref) => {
    if (type === "image") {
      setParticipantDetails((prev) => ({ ...prev, imageRef: ref }));
    } else if (type === "idProof") {
      setParticipantDetails((prev) => ({ ...prev, idProofRef: ref }));
    }
  };

  const handleImageDelete = async () => {
    let imageRef = "";
    if (participantDetails?.imageRef) {
      imageRef = participantDetails?.imageRef;
    } else {
      const fileUrl = participantDetails?.imageUrl;
      if (fileUrl) {
        const storageRef = ref(storage, fileUrl);
        try {
          const metadata = await getMetadata(storageRef);
          imageRef = ref(storage, metadata.fullPath);
        } catch (error) {
          console.log(error.code, "image data not found");
        }
      }
    }

    try {
      await deleteObject(imageRef);
      //GA event tracking
      GAEventTrack({
        event: "edit_student_remove_image",
        data: {
          uid: auth?.currentUser?.uid,
          email: auth?.currentUser?.email,
          studentId: studentDetails?.id,
        },
      });
      setParticipantDetails((prev) => ({ ...prev, imageUrl: "" }));
      // update on db
      await updateDoc(studentDocRef, { imageUrl: "" });
    } catch (error) {
      console.log(error.code, "unable to delete");
      setError((prev) => ({ ...prev, image: error.code }));
    }
  };

  const handleIdProofDelete = async () => {
    let idProofRef = "";
    if (participantDetails?.idProofRef) {
      idProofRef = participantDetails?.idProofRef;
    } else {
      const fileUrl = participantDetails?.idProofUrl;
      if (fileUrl) {
        const storageRef = ref(storage, fileUrl);
        try {
          const metadata = await getMetadata(storageRef);
          idProofRef = ref(storage, metadata.fullPath);
        } catch (error) {
          console.log(error.code, "document data not found");
          setError((prev) => ({
            ...prev,
            image: error.code + ": documnet data not found",
          }));
        }
      }
    }
    try {
      await deleteObject(idProofRef);
      //GA event tracking
      GAEventTrack({
        event: "edit_student_remove_idproof",
        data: {
          uid: auth?.currentUser?.uid,
          email: auth?.currentUser?.email,
          studentId: studentDetails?.id,
        },
      });
      setParticipantDetails((prev) => ({ ...prev, idProofUrl: "" }));

      // update on db
      await updateDoc(studentDocRef, { idProofUrl: "" });
      console.log("idProof deleted successfully");
    } catch (error) {
      console.log(error.code, "unable to delete");
      setError((prev) => ({ ...prev, image: error.code }));
    }
  };

  const imageContainerRef = useRef();
  const idProofContainerRef = useRef();

  const handleSubmit = async (e) => {
    e.preventDefault();

    // validations
    if (!participantDetails?.imageUrl) {
      setError((prev) => ({ ...prev, image: "Please upload student image" }));
      imageContainerRef?.current?.scrollIntoView({ behavior: "smooth" });
      return;
    }
    if (!participantDetails?.idProofUrl) {
      setError((prev) => ({ ...prev, idProof: "Please upload id proof" }));
      idProofContainerRef?.current?.scrollIntoView({ behavior: "smooth" });
      return;
    }

    if (participantDetails?.homePincode?.length < 5) {
      setError((prev) => ({
        ...prev,
        submit: "Please enter valid home pincode",
      }));
      return;
    }

    //GA event tracking
    GAEventTrack({
      event: "edit_student_form_submit",
      data: {
        uid: auth?.currentUser?.uid,
        email: auth?.currentUser?.email,
        studentId: studentDetails?.id,
      },
    });

    // studentData
    const studentData = {
      name: participantDetails?.name,
      dateOfBirth: participantDetails?.dateOfBirth,
      homeAddress: {
        address: participantDetails?.homeAddress,
        country: homeCountry?.value,
        state: homeState?.value,
        city: homeCity?.value,
        pin: participantDetails?.homePincode,
      },

      schoolDetails: {
        schoolName: participantDetails?.schoolName,
        schoolId: participantDetails?.schoolId,
        schoolAddress: {
          address: participantDetails?.schoolAddress,
          country: schoolCountry?.value,
          state: schoolState?.value,
          city: schoolCity?.value,
          pin: participantDetails?.schoolPincode,
        },
      },
      imageUrl: participantDetails?.imageUrl,
      idProofUrl: participantDetails?.idProofUrl,
      updatedAt: serverTimestamp(),
    };
    setLoading((prev) => ({ ...prev, submit: true }));
    try {
      // update to firebase document
      await updateDoc(studentDocRef, studentData);
      //GA event tracking
      GAEventTrack({
        event: "edit_student_data_update",
        data: {
          uid: auth?.currentUser?.uid,
          email: auth?.currentUser?.email,
          studentId: studentDetails?.id,
        },
      });
      navigate(-1);
    } catch (error) {
      console.log(error);
      setError((prev) => ({
        ...prev,
        submit: error.code,
      }));
    } finally {
      setLoading((prev) => ({ ...prev, submit: false }));
    }
  };

  return (
    <div className="space-y-8 container step3-edit-container">
      <div className="space-y-1">
        <h1 className="text-2xl font-bold">
          Keep Information Up-to-Date: Edit Student Details
        </h1>
        <p className="text-base font-medium">
          Ensure your child's information is accurate! Having the correct
          details helps us create a personalized certificate recognizing their
          participation in the Olympiad.
        </p>
      </div>
      <form
        className="step3-edit-content space-y-6 pb-8"
        onSubmit={handleSubmit}
      >
        <div className="edit-form-box bg-[#FFE9C8] rounded-xl space-y-4">
          <div className="space-y-4">
            <h2 className="text-lg font-bold">Participant Details</h2>
            <div className="grid md:grid-cols-2 gap-4 gap-y-2">
              {participantDetailsfeilds.map((field) => (
                <div key={field.name}>
                  <label>
                    {field.label}
                    {field.required ? "*" : ""}
                  </label>
                  <TextInput
                    type={field.type}
                    inputName={field.name}
                    placeholderText={field.placeholder}
                    isRequired={field.required}
                    inputValue={
                      participantDetails && participantDetails[field.name]
                    }
                    handleChange={(e) => {
                      setParticipantDetails({
                        ...participantDetails,
                        [field.name]: e.target.value,
                      });
                    }}
                  />
                </div>
              ))}
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group space-y-2" ref={imageContainerRef}>
                  <label>
                    Capture a Picture of the Participant
                    <div className="tooltip-box">
                      <img
                        src="/img/info-icon.png"
                        className="mx-1"
                        alt="info"
                      />
                      <span className="tooltiptext">
                        To keep the competition fair and prevent cheating, we
                        ask for a photo of your child. This helps us verify
                        their identity during the event and ensures the same
                        child who registered is participating. Your cooperation
                        in providing a recent, clear picture is much
                        appreciated. Thank you!
                      </span>
                    </div>
                  </label>
                  {!participantDetails?.imageUrl && !imageUploading ? (
                    <>
                      <div className="flex items-center justify-center gap-4 w-40 h-24 object-cover rounded-xl bg-gray-500">
                        <FiCamera className="w-8 h-8 text-white" />
                      </div>
                    </>
                  ) : (
                    <div className="flex gap-7 items-center">
                      <div className="w-[250px] h-[150px] rounded-2xl overflow-hidden">
                        {imageUploading ? (
                          <div className="w-full h-full relative flex items-center justify-center">
                            <img
                              src={uploadImage}
                              alt="uploading"
                              className="object-cover w-full h-full"
                            />
                            <div className="absolute w-full h-[3px] bottom-0 left-0">
                              <div
                                style={{
                                  width: `${imageUploadProgress}%`,
                                }}
                                className="h-full bg-red-700 transition-width duration-300 ease-in-out z-50"
                              />
                            </div>
                          </div>
                        ) : (
                          <img
                            src={participantDetails?.imageUrl}
                            alt="Participant Pic"
                            className="object-cover w-full h-full"
                          />
                        )}
                      </div>
                      <div
                        className="flex items-center gap-2 cursor-pointer"
                        onClick={handleImageDelete}
                      >
                        <MdDelete className="w-8 h-8 text-red-500 bg-white p-1 rounded-full" />
                        <span className="text-base font-medium">Remove</span>
                      </div>
                    </div>
                  )}
                  <Button
                    className="take-photo"
                    version="black"
                    handleClick={() => {
                      setImageCaptureModule("image");
                    }}
                  >
                    <div className="flex items-center gap-2">
                      <span>
                        {participantDetails?.imageUrl ? "Retake" : "Capture"}
                      </span>
                    </div>
                  </Button>

                  {error?.image && (
                    <div className="text-[#f00] text-xs mt-1 h-3">
                      {error?.image}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div
                  className="form-group flex flex-col"
                  ref={idProofContainerRef}
                >
                  <label>
                    Student’s Id Proof<sup>*</sup>
                  </label>
                  <FileUpload
                    updateUrl={updateUrl}
                    openImageCapture={() => setImageCaptureModule("idProof")}
                    uploadedImageUrl={participantDetails?.idProofUrl}
                    handleDelete={handleIdProofDelete}
                    updateRef={updateRef}
                    participantName={participantDetails?.name}
                    idProofUploading={idProofUploading}
                    imageUploadProgress={imageUploadProgress}
                  />

                  {error?.idProof && (
                    <div className="text-[#f00] text-xs mt-1 h-3">
                      {error?.idProof}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="space-y-4">
            <h2 className="text-lg font-bold">Home Details</h2>
            <div className="grid md:grid-cols-2 gap-4 gap-y-2">
              {homeDetailsFields?.map((field) => (
                <div key={field.name}>
                  <label>
                    {field.label} {field.required ? "*" : ""}
                  </label>
                  {field.name === "homeCountry" ? (
                    <SelectInput
                      placeholderText="Select Home Country"
                      selectOptions={allCountries}
                      // error={errorArray[index].stateError}
                      value={homeCountry}
                      noOptionsMessage={() => "No Country Found"}
                      isRequired={field.required}
                      handleChange={(selectedOptions) =>
                        setHomeCountry(selectedOptions)
                      }
                    />
                  ) : field.name === "homeState" ? (
                    <SelectInput
                      placeholderText="Select Home State"
                      selectOptions={allStates(homeCountry?.id)}
                      // error={errorArray[index].stateError}
                      value={homeState}
                      isRequired={field.required}
                      noOptionsMessage={() =>
                        homeCountry
                          ? "Please Select Home Country First"
                          : "No State Found"
                      }
                      handleChange={(selectedOptions) =>
                        setHomeState(selectedOptions)
                      }
                    />
                  ) : field.name === "homeCity" ? (
                    <SelectInput
                      placeholderText="Select Home City"
                      selectOptions={allCities(homeCountry?.id, homeState?.id)}
                      // error={errorArray[index].stateError}
                      value={homeCity}
                      isRequired={field.required}
                      noOptionsMessage={() =>
                        homeCountry
                          ? "Please Select Home Country First"
                          : homeState
                          ? "Please Select Home State First"
                          : "No City Found"
                      }
                      handleChange={(selectedOptions) =>
                        setHomeCity(selectedOptions)
                      }
                    />
                  ) : (
                    <TextInput
                      type={field.type}
                      inputName={field.name}
                      placeholderText={field.placeholder}
                      isRequired={field.required}
                      inputValue={
                        participantDetails && participantDetails[field.name]
                      }
                      handleChange={(e) => {
                        setParticipantDetails({
                          ...participantDetails,
                          [field.name]: e.target.value,
                        });
                      }}
                    />
                  )}
                </div>
              ))}
            </div>
          </div>
          {!participantDetails?.schoolStudent && (
            <div className="space-y-4">
              <h2 className="text-lg font-bold">School Details</h2>
              <div className="grid md:grid-cols-2 gap-4 gap-y-2">
                {schoolDetailsFields?.map((field) => (
                  <div key={field.name}>
                    <label>
                      {field.label} {field.required ? "*" : ""}
                    </label>
                    {field.name === "schoolCountry" ? (
                      <SelectInput
                        placeholderText="Select School Country"
                        selectOptions={allCountries}
                        // error={errorArray[index].stateError}
                        value={schoolCountry}
                        isRequired={field.required}
                        noOptionsMessage={() => "No Country Found"}
                        handleChange={(selectedOptions) =>
                          setSchoolCountry(selectedOptions)
                        }
                      />
                    ) : field.name === "schoolState" ? (
                      <SelectInput
                        placeholderText="Select School State"
                        selectOptions={allStates(schoolCountry?.id)}
                        // error={errorArray[index].stateError}
                        value={schoolState}
                        isRequired={field.required}
                        noOptionsMessage={() =>
                          schoolCountry
                            ? "Please Select School Country First"
                            : "No State Found"
                        }
                        handleChange={(selectedOptions) =>
                          setSchoolState(selectedOptions)
                        }
                      />
                    ) : field.name === "schoolCity" ? (
                      <SelectInput
                        placeholderText="Select School City"
                        selectOptions={allCities(
                          schoolCountry?.id,
                          schoolState?.id
                        )}
                        // error={errorArray[index].stateError}
                        value={schoolCity}
                        isRequired={field.required}
                        noOptionsMessage={() =>
                          schoolCountry
                            ? "Please Select Home Country First"
                            : schoolState
                            ? "Please Select Home State First"
                            : "No City Found"
                        }
                        handleChange={(selectedOptions) =>
                          setSchoolCity(selectedOptions)
                        }
                      />
                    ) : (
                      <TextInput
                        type={field.type}
                        inputName={field.name}
                        placeholderText={field.placeholder}
                        inputValue={
                          participantDetails && participantDetails[field.name]
                        }
                        handleChange={(e) => {
                          setParticipantDetails({
                            ...participantDetails,
                            [field.name]: e.target.value,
                          });
                        }}
                      />
                    )}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
        <div className="space-y-2">
          {error?.submit && (
            <p className="text-red-500 text-sm">{error?.submit}</p>
          )}
          <Button
            version="secondary"
            text="Update"
            type="submit"
            loading={loading?.submit}
          />
        </div>
      </form>
      {imageCaptureModule && (
        <div className="capture-modal">
          <div className="inner-div flex w-full h-full bg-[#000]/20 backdrop-blur-sm fixed !top-20 left-0 items-center justify-center">
            <ImageCapture
              updateUrl={updateUrl}
              setImageCaptureModule={setImageCaptureModule}
              imageCaptureModule={imageCaptureModule}
              participantName={participantDetails?.name}
              updateRef={updateRef}
              setUploadImage={setUploadImage}
              setLoading={
                imageCaptureModule === "idProof"
                  ? setIdProofUploading
                  : setImageUploading
              }
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default EditStudent;
