import React, { useEffect, useState } from "react";

const Cart = ({
  country,
  participantDetailArray,
  amount,
  setAmount,
  participantName,
}) => {
  const subjectFee = country === "IN" ? 99 : 4.99;
  const tax = 0;
  const [discount, setDiscount] = useState(0);
  const [discountPercent, setDiscountpercent] = useState(0);

  useEffect(() => {
    const participantSubjects = participantDetailArray.map(
      (participant) => participant?.subjects?.length
    );

    const total = participantSubjects?.reduce(
      (amount, item) => amount + item * subjectFee,
      0
    );
    const discount = participantSubjects?.reduce(
      (amount, item) =>
        country === "IN"
          ? amount + (item === 3 ? 98 : 0)
          : amount + (item === 3 ? 4.98 : 0),
      0
    );
    setDiscount(discount);
    setDiscountpercent(Math.round((discount / total) * 100));
    setAmount(total - discount);
  }, [participantDetailArray, setAmount, country, subjectFee]);

  return (
    <div className="img-box flex flex-col gap-8 items-center justify-center ">
      <img
        src={
          country === "IN"
            ? "/img/offer-india.png"
            : "/img/offer-international.png"
        }
        alt="offer"
        className="w-[280px]"
      />
      <div className="yellow-box bg-[#FFE9C8] p-4 pt-4 flex flex-col gap-2 rounded-[14px] w-full">
        {discount ? (
          <div className="green-row rounded-lg">
            <img src="/img/carbon_ticket.png" alt="offer-Sucess" />
            Congratulations! Offer Applied, you saved {discountPercent}%
          </div>
        ) : null}
        <div className="bg-[#F39C12] p-2 px-4 flex flex-col gap-2 rounded-lg text-white text-[15px] font-semibold">
          <div className="w-full flex justify-between">
            <span>Total Participants</span>
            <span>{participantDetailArray?.length}</span>
          </div>
          <div className="w-full flex justify-between">
            <span>Per Subject Fee</span>
            <span>
              {country === "IN" ? "₹" : "$"} {subjectFee?.toFixed(2)}
            </span>
          </div>
        </div>

        <div className="px-4 mt-2 flex flex-col gap-3 text-[#34495E]">
          <div className="font-bold underline text-base">Order Summary</div>
          <div className="flex flex-col justify-center gap-3">
            {participantDetailArray?.map((item, index) => {
              return (
                <div key={index} className="flex justify-between">
                  <div className="flex flex-col">
                    <div className="flex gap-2 items-end">
                      <span className="font-semibold">
                        Participant{" "}
                        {participantName ? " : " + participantName : index + 1}
                      </span>
                    </div>
                    <div className="text-sm capitalize">
                      {item?.subjects?.join(", ")}
                    </div>
                  </div>
                  <div className="flex flex-col text-right">
                    <span className="text-sm">
                      {country === "IN" ? "₹" : "$"} {subjectFee}
                      {" × "}
                      {item?.subjects?.length}
                    </span>
                    <span className="text-sm font-bold">
                      {country === "IN" ? "₹" : "$"}{" "}
                      {(subjectFee * item?.subjects?.length)?.toFixed(2)}
                    </span>
                  </div>
                </div>
              );
            })}

            {discount ? (
              <div className="flex justify-between text-[#2cb237] font-medium">
                <span>Discount Applied</span>
                <span>
                  - {country === "IN" ? "₹" : "$"} {discount?.toFixed(2)}
                </span>
              </div>
            ) : null}

            <div className="flex justify-between">
              <span>Est. Tax</span>
              <span>
                {country === "IN" ? "₹" : "$"} {tax?.toFixed(2)}
              </span>
            </div>
          </div>

          <div className="h-[2px] bg-[#888]" />
          <div className="flex justify-between font-bold text-base">
            <span className="">Order Total</span>
            <span className="">
              {country === "IN" ? "₹" : "$"} {amount?.toFixed(2)}{" "}
            </span>
          </div>
        </div>
      </div>
      {/* <div className="flex justify-end w-full">
        <Button version="secondary" text="Pay Now" />
      </div> */}
    </div>
  );
};

export default Cart;
