import React from "react";
import Select from "react-select";
import { customStyles } from "./selectStyles";

const SelectInput = ({
  placeholderText,
  selectOptions,
  handleChange,
  value,
  defaultValue,
  inputName,
  isMulti,
  isClearable,
  isSearchable,
  noOptionsMessage,
  disabled,
  disableText,
  error,
  isRequired,
}) => {
  const formatOptionLabel = ({ label, isDisabled }) => (
    <div className="w-full flex justify-between">
      {label}
      {isDisabled && <span style={{ color: "red" }}>{disableText}</span>}
    </div>
  );

  return (
    <div className={disabled ? "opacity-60 cursor-not-allowed" : ""}>
      <Select
        placeholder={placeholderText}
        options={selectOptions}
        styles={customStyles}
        defaultValue={defaultValue}
        value={value}
        name={inputName}
        onChange={handleChange}
        isMulti={isMulti}
        isClearable={isClearable}
        isSearchable={isSearchable}
        className="select-custom"
        classNamePrefix="select"
        noOptionsMessage={noOptionsMessage}
        formatOptionLabel={formatOptionLabel}
        required={isRequired}
        isDisabled={disabled}
      />
      {error && (
        <div className="error-txt text-[#f00] text-xs mt-1 h-3">{error}</div>
      )}
    </div>
  );
};

export default SelectInput;
