import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import SliderRating from "./SliderRating";
import MointerStudent from "./MointerStudent";
import ContactForm from './ContactForm'

import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(useGSAP);
gsap.registerPlugin(ScrollTrigger);


const SchoolLandingPage = () => {

  const navigate = useNavigate();

  //jump to the contact form
  const contactFormRef = useRef(null);
  const setFormRef = (ref) => {
    contactFormRef.current = ref;
  };

  const scrollToContactForm = () => {
    if (contactFormRef.current) {
      contactFormRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  //banner Animation
  useEffect(() => {
    // Animate the #banner-boy element
    gsap.to("#card1", {
      duration: 1,
      y: "-=12",
      ease: "Power1.easeInOut",
      yoyo: true,
      repeat: -1,
      delay: 0.5,
    });
    gsap.to("#card2", {
      duration: 1,
      y: "-=12",
      ease: "Power1.easeInOut",
      yoyo: true,
      repeat: -1,
      delay: 0.75,
    });
    gsap.to("#card3", {
      duration: 1,
      y: "-=12",
      ease: "Power1.easeInOut",
      yoyo: true,
      repeat: -1,
      delay: 1,
    });
  }, []);

  // save time animation

  // useEffect(() => {
  //   const thirdTextPosi = 0;
  //   const mobileTextPosi = -50;
  //   const thirdImgPosi = -100;

  //   gsap.from(".ui_image", {
  //     x: thirdImgPosi,
  //     opacity: -1,
  //     duration: 6,
  //     scrollTrigger: {
  //       start: "top 40%",
  //       end: "top 0%",
  //       scrub: true,
  //       trigger: "#services",
  //       toggleActions: "restart none none none"
  //     }
  //   });

  //   gsap.from(".ani-text-1", {
  //     x: thirdTextPosi,
  //     opacity: -1,
  //     duration: 6,
  //     scrollTrigger: {
  //       start: "top 40%",
  //       end: "top 0%",
  //       scrub: true,
  //       trigger: "#services",
  //       toggleActions: "restart none none none"
  //     }
  //   });

  //   gsap.from(".mobile_text", {
  //     x: mobileTextPosi,
  //     opacity: -1,
  //     duration: 6,
  //     scrollTrigger: {
  //       start: "top 90%",
  //       end: "top 0%",
  //       scrub: true,
  //       trigger: ".mobile_container",
  //       toggleActions: "restart none none none"
  //     }
  //   });

  //   gsap.from(".web_text", {
  //     x: thirdTextPosi,
  //     opacity: -1,
  //     duration: 6,
  //     scrollTrigger: {
  //       start: "top 90%",
  //       end: "top 0%",
  //       scrub: true,
  //       trigger: ".web_container",
  //       toggleActions: "restart none none none"
  //     }
  //   });

  //   gsap.from(".main_text", {
  //     x: mobileTextPosi,
  //     opacity: -1,
  //     duration: 6,
  //     scrollTrigger: {
  //       start: "top 90%",
  //       end: "top 0%",
  //       scrub: true,
  //       trigger: ".main_container",
  //       toggleActions: "restart none none none"
  //     }
  //   });
  // }, []);


  const services2Ref = useRef(null);
  const lineRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const services = services2Ref.current;
      const line = lineRef.current;

      if (services && line) {
        const scrollHeight = Math.abs(services.getBoundingClientRect().top).toFixed();
        const scrollTop = services.getBoundingClientRect().top + window.pageYOffset;

        if (window.pageYOffset > scrollTop && scrollHeight < services.clientHeight) {
          line.style.height = `${scrollHeight - 10}px`;
        } else {
          line.style.height = '0px';
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  // back to top button set
  useEffect(() => {
    const handleScroll = () => {
      const scrollToTopBtn = document.querySelector(".scrollToTopBtn");
      const rootElement = document.documentElement;

      if (rootElement.scrollTop >= 300) {
        scrollToTopBtn.classList.add("showBtn");
      } else {
        scrollToTopBtn.classList.remove("showBtn");
      }
    };

    const scrollToTop = () => {
      document.documentElement.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };

    const scrollToTopBtn = document.querySelector(".scrollToTopBtn");
    scrollToTopBtn.addEventListener("click", scrollToTop);
    document.addEventListener("scroll", handleScroll);

    return () => {
      scrollToTopBtn.removeEventListener("click", scrollToTop);
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);


  //  explore btn
  const section1Ref = useRef(null);
  const section2Ref = useRef(null);
  const section3Ref = useRef(null);

  const scrollToSection = (sectionRef) => {
    if (sectionRef.current) {
      sectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="js-nask-home-animations">
      <div className="back-to-top">
        <button className="scrollToTopBtn">
          <img src="/img/new-home/back-to-top.png" alt="back-to-top" />
        </button>
      </div>

      <section className="banner-section school-back">
        <div className='container'>
          <div className='row'>
            <div className='col-md-12 col-lg-6 order-change' data-aos="fade-right" data-aos-delay="50" data-aos-duration="500">
              <h3>Empowering Schools, Beyond Textbooks</h3>
              <p>We understand the pivotal role you play in shaping young minds, and we’re here to support you every step of the way. At Practido, we’re dedicated to providing tools that empower schools and educators thereby enhancing the learning experience for students.</p>
              <div className='btn-inline'>
                <a href="https://calendly.com/shilpi-naskay/30min?month=2023-05" target="_blank" type='btn' className='btn primary-btn'>Book an Appointment</a>
              </div>
            </div>
            <div className='col-md-12 col-lg-6' data-aos="fade-left" data-aos-delay="50" data-aos-duration="500">
              <div className="educator-img">
                <img className="educator-banner" src="/img/school-home/g8.png" alt="banner" />
                <img id="card1" className="card-1" src="/img/school-home/card-1.png" alt="banner" />
                <img id="card3" className="card-2" src="/img/school-home/card-2.png" alt="banner" />
                <img id="card2" className="card-3" src="/img/school-home/card-3.png" alt="banner" />
                <img id="card1" className="card-4" src="/img/school-home/card-4.png" alt="banner" />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* discover  */}
      <section className='parent-practido school-practido'>
        <div className='container'>
          <div className="container aos-animate" data-aos="fade-up">
            <div
              className="head"
              data-aos="fade-up"
              data-aos-delay="150"
              data-aos-duration="500"
            >
              <h3>Discover Our Powerful Features</h3>
            </div>
            <div className="row">
              <div className="col-sm-12 col-md-4">
                <div className="img-box">
                  <img src="/img/school-home/practido-1.png" alt="Online Exams" />
                  <img className="zoom-in-zoom-out sub-image first-img" src="/img/school-home/practido-1-1.png" alt="Online Exams" />
                  <img className="zoom-in-zoom-out sub-image sec-img" src="/img/school-home/practido-1-2.png" alt="Online Exams" />
                  <img className="zoom-in-zoom-out sub-image third-img" src="/img/school-home/practido-1-3.png" alt="Online Exams" />
                  <img className="zoom-in-zoom-out sub-image fourth-img" src="/img/school-home/practido-1-4.png" alt="Online Exams" />
                  <img className="zoom-in-zoom-out sub-image fifth-img" src="/img/school-home/practido-1-5.png" alt="Online Exams" />
                </div>
                <h4>Conduct Online Exams with Ease</h4>
                <p>AI-Powered, Fully Proctored Exams: Conduct online exams and tests with our AI-powered platform that ensures a secure, cheating-proof environment.</p>
                <button type="button" className="btn link-btn" onClick={() => scrollToSection(section1Ref)}>Explore Now</button>
              </div>
              <div className="col-sm-12 col-md-4">
                <div className="img-box">
                  <img src="/img/school-home/practido-2.png" alt="Save Time" />
                  <img className="zoom-in-zoom-out sub-image practido-2-first-img" src="/img/school-home/practido-2-1.png" alt="Online Exams" />
                  <img className="zoom-in-zoom-out sub-image practido-2-sec-img" src="/img/school-home/practido-2-2.png" alt="Online Exams" />
                </div>
                <h4>Save Time with Our Platform</h4>
                <p>Teachers Can Save Time: Say goodbye to the hassle of preparing test papers with our unlimited practice tests and automated grading system.</p>
                <button type="button" className="btn link-btn" onClick={() => scrollToSection(services2Ref)}>Explore Now</button>
              </div>
              <div className="col-sm-12 col-md-4">
                <div className="img-box">
                  <img src="/img/school-home/practido-3.png" alt="Real-Time" />
                  <img className="zoom-in-zoom-out sub-image practido-3-first-img" src="/img/school-home/practido-3-1.png" alt="Online Exams" />
                  <img className="zoom-in-zoom-out sub-image practido-3-sec-img" src="/img/school-home/practido-3-2.png" alt="Online Exams" />
                  <img className="zoom-in-zoom-out sub-image practido-3-third-img" src="/img/school-home/practido-3-3.png" alt="Online Exams" />
                  <img className="zoom-in-zoom-out sub-image practido-3-fourth-img" src="/img/school-home/practido-3-4.png" alt="Online Exams" />
                </div>
                <h4>Real-Time Monitoring & Motivational Rewards</h4>
                <p>Track student progress in real-time with detailed reports. Motivate students with points, coins, trophies, and rewards for consistent practice and achievements.</p>
                <button type="button" className="btn link-btn" onClick={() => scrollToSection(section3Ref)}>Explore Now</button>
              </div>
            </div>
            {/* <div className='center-btn'><button type="button" className="btn blue-btn" onClick={() => window.open("/register", "_blank")}>Register Now</button></div> */}
          </div>
        </div>
      </section>

      {/* conduct-online-exam */}
      <section class="conduct-online-exam" ref={section1Ref}>
        <div className="container">
          <div className="row">
            <div className="col-xl-6">
              <h2>Conduct Online Exams with Ease
              </h2>
              <p><span>AI-Powered, Fully Proctored Exams</span>: Conduct online exams and tests effortlessly with our AI-powered platform that ensures a secure, cheating-proof environment.</p>
              <p><span>Comprehensive Coverage</span>: Be it session exams, practice exams, or entrance exams, we’ve got you covered.</p>
              <a type='btn' className='btn demo-btn' href="https://calendly.com/shilpi-naskay/30min?month=2023-05" target="_blank">Book a Demo</a>
            </div>
            <div className="col-xl-6">
              <div className="row">
                <div className="col-md-6">
                  <div className="card-box first-box">
                    <img src="/img/school-home/ai-img.png" alt="ai-img" />
                    <h3>AI Proctoring</h3>
                    <p>Ensure exam integrity with AI-powered proctoring that monitors student behavior and prevents cheating.</p>
                    <div className="conect-strip d-none d-md-flex">
                      <div className="first-strip"></div>
                      <div className="secount-strip"></div>
                    </div>
                  </div>
                  <div className="card-box">
                    <img src="/img/school-home/ease-to-move.png" alt="ease-to-move" />
                    <h3>Ease of Use</h3>
                    <p>Simplify the administration of online exams with an intuitive platform that is easy to navigate.</p>
                    <div className="conect-strip right-side d-none d-md-flex">
                      <div className="first-strip"></div>
                      <div className="secount-strip"></div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card-box">
                    <img src="/img/school-home/secure-environment.png" alt="secure-environment" />
                    <h3>Secure Environment</h3>
                    <p>Create a secure online exam center that protects the integrity of the examination process.</p>
                    <div className="conect-strip left-side d-none d-md-flex">
                      <div className="first-strip"></div>
                      <div className="secount-strip"></div>
                    </div>
                    <div className="conect-strip bottom-right-side d-none d-md-flex">
                      <div className="first-strip"></div>
                      <div className="secount-strip"></div>
                    </div>
                  </div>
                  <div className="card-box">
                    <img src="/img/school-home/monitiring.png" alt="monitiring" />
                    <h3>Comprehensive Monitoring</h3>
                    <p>Track student activity and behavior during exams to ensure fairness and security.</p>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>


      </section>

      {/* save-time-section */}
      <section className="parent-our-feature save-time-section" ref={services2Ref}>
        <div className="container main-container">
          <div className="container top-head">
            <h3>Save Time with Our Platform</h3>
            <p>Say goodbye to the hassle of preparing test papers with our unlimited practice tests and automated grading system.</p>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="img-box"><img src="/img/school-home/assessment.png" alt="Dedicated Assessment" /></div>
            </div>
            <div className="col-md-6">
              <div className="content">
                <h3>Dedicated Assessment Section</h3>
                <p>Create a secure online exam center where only your students are assessed, streamlining the examination process.</p>
              </div>
            </div>
          </div>
          {/* row */}
          <div className="row order-change row-2">
            <div className="col-md-6">
              <div className="content">
                <h3>Single Digital Platform</h3>
                <p>Conduct all internal tests on one platform, simplifying test administration and student progress tracking.</p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="img-box"><img src="/img/school-home/digital.png" /></div>
            </div>
          </div>
          {/* row */}
          <div className="row row-3">
            <div className="col-md-6">
              <div className="img-box"><img src="/img/school-home/automate.png" /></div>
            </div>
            <div className="col-md-6">
              <div className="content">
                <h3>Automated Grading</h3>
                <p>Our system automatically checks answer sheets and generates detailed reports, saving you time and effort.</p>
              </div>
            </div>
          </div>
          {/* row */}
          <div className="row order-change row-4">
            <div className="col-md-6">
              <div className="content">
                <h3>Detailed Analysis</h3>
                <p>Receive comprehensive, personalized analysis for each student, helping you tailor your teaching strategies.</p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="img-box"><img src="/img/school-home/details.png" /></div>
            </div>
          </div>
          {/* row */}
          <div className="row row-5">
            <div className="col-md-6">
              <div className="img-box"><img src="/img/school-home/ranking.png" /></div>
            </div>
            <div className="col-md-6">
              <div className="content">
                <h3>International Competitive Ranking</h3>
                <p>Understand where your students stand not only nationally but internationally , motivating them to improve and identifying top performers.</p>
              </div>
            </div>
          </div>
          {/* row */}
          <div className="row order-change row-6">
            <div className="col-md-6">
              <div className="content">
                <h3>Objective Reports</h3>
                <p>Generate detailed analysis reports for parent discussions, demonstrating student progress and building confidence in your methods.</p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="img-box"><img src="/img/school-home/object.png" /></div>
            </div>
          </div>
          {/* row */}
          <div className="row row-7">
            <div className="col-md-6">
              <div className="img-box"><img src="/img/school-home/dg-assessment.png" /></div>
            </div>
            <div className="col-md-6">
              <div className="content">
                <h3>Digital Assessments</h3>
                <p>Eliminate paper-pen methods with digital assessments, reducing administrative burden.</p>
              </div>
            </div>
          </div>
          {/* row */}
          <div className="row order-change row-8">
            <div className="col-md-6">
              <div className="content">
                <h3>Increased Engagement</h3>
                <p>Use fun quizzes and interactive activities to keep students motivated and excited about their studies.</p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="img-box"><img src="/img/school-home/ingagement.png" /></div>
            </div>
          </div>
          {/* row */}
          <div className="row row-9">
            <div className="col-md-6">
              <div className="img-box"><img src="/img/school-home/feedback.png" /></div>
            </div>
            <div className="col-md-6">
              <div className="content">
                <h3>Instant Feedback</h3>
                <p>Provide students with immediate feedback, promoting a responsive learning environment.</p>
              </div>
            </div>
          </div>
          {/* row */}
          <div className="row order-change row-10">
            <div className="col-md-6">
              <div className="content">
                <h3>Flexible Practice Opportunities</h3>
                <p>Allow students to practice at their own pace, from anywhere, and fit practice into their schedules.</p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="img-box"><img src="/img/school-home/practice.png" /></div>
            </div>
          </div>
          {/* row */}
          <div class="dashed-line" ref={lineRef} style={{ height: '0px' }}>
            <svg width="730" height="6273" viewBox="0 0 730 6273" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M4.47149 0.634521C4.47149 411.447 600.99 132.882 719.169 597.157C837.348 1061.43 -99.7237 826.335 10.0952 1328.74C121.857 1840.03 607.616 1613.63 716.355 2060.32C825.094 2507.01 45.2505 2366.08 10.0916 2760.75C-47.4409 3406.58 716.355 3025.7 716.355 3495.77C716.355 3892.49 61.1521 3826.82 26.9744 4198.8C-19.7625 4707.47 807.516 4559.85 693.841 4965.36C593.398 5323.67 92.5034 5275.35 26.9744 5645.99C-56.6671 6119.07 586.088 5891.49 693.841 6272.84" stroke="white" stroke-dasharray="4 4" />
            </svg>
          </div>
          {/* dashed-line */}
        </div>
      </section>

      <MointerStudent ref={section3Ref} />

      {/* reward-active */}
      <section className="reward-active">
        <div className="container-fluid">
          <div className="col-md-12 col-lg-4 left-section">
            <div className="floating-img">
              <img
                className="floating-animation reward-0"
                src="/img/student-home/build-back-sm-0.png"
                alt="images"
              />
              <img
                className="floating-animation reward-1"
                src="/img/student-home/build-back-sm-1.png"
                alt="images"
              />
              <img
                className="floating-animation reward-2"
                src="/img/student-home/build-back-sm-5.png"
                alt="images"
              />
              <img
                className="floating-animation reward-4"
                src="/img/student-home/build-back-sm-4.png"
                alt="images"
              />
              <img
                className="floating-animation reward-6"
                src="/img/student-home/build-back-sm-4.png"
                alt="images"
              />
              <img
                className="floating-animation reward-7"
                src="/img/student-home/build-back-sm-2.png"
                alt="images"
              />
              <img
                className="floating-animation reward-10"
                src="/img/student-home/build-back-sm-7.png"
                alt="images"
              />
            </div>
            <h3>Motivating Rewards for Active Learning</h3>
          </div>
          <div className="col-md-12 col-lg-8 right-section">
            <div className="col-md-6">
              <div className="shimmer-wrapper"><img src="/img/student-home/build-sm-3.png" alt="Points" /></div>
              <h4>Practice Points</h4>
              <p>Earn points by taking practice tests, climb the leaderboard, and collect trophies for major milestones.</p>
            </div>
            <div className="col-md-6 blue-back">
              <div className="shimmer-wrapper"><img src="/img/student-home/build-sm-2.png" alt="Coins" /></div>
              <h4>Coins of Consistency</h4>
              <p>Earn coins through learning activities and unlock exclusive content and features.</p>
            </div>
            <div className="col-md-6 blue-back">
              <div className="shimmer-wrapper"><img src="/img/student-home/build-sm-4.png" alt="Trophies" /></div>
              <h4>Earn Trophies</h4>
              <p>Scale the leaderboard with Practido Points, earn trophies for achievements, and excel in your learning journey.</p>
            </div>
            <div className="col-md-6">
              <div className="shimmer-wrapper"><img src="/img/student-home/build-sm-1.png" alt="Streaks" /></div>
              <h4>Practice Streaks</h4>
              <p>Build consistency and boost success with our streak feature, rewarding daily engagement.</p>
            </div>
          </div>
        </div>

      </section>

      <SliderRating contactFormRef={contactFormRef} />
      <ContactForm contactFormRef={setFormRef} />
    </div>
  );
};

export default SchoolLandingPage;
