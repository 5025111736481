import { onSnapshot, orderBy, query, where } from "firebase/firestore";
import { useEffect, useReducer } from "react";
import { useNavigate } from "react-router-dom";
import { questionsCollectionRef } from "../../../config/firebaseConfig";
import { Button } from "../../../components";
import { FaPlus } from "react-icons/fa6";
import QuestionTable from "./QuestionTable";

const initial = {
  loading: true,
  questions: [],
  error: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_SUCCESS":
      return {
        loading: false,
        questions: action.payload,
        error: null,
      };
    case "FETCH_ERROR":
      return {
        loading: false,
        questions: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const AllQuestions = () => {
  const navigate = useNavigate();
  const [fetchState, dispatch] = useReducer(reducer, initial);
  const { loading, questions, error } = fetchState;

  useEffect(() => {
    const fetchQuestions = async () => {
      const q = query(
        questionsCollectionRef,
        where("isDeleted", "==", false),
        orderBy("createdAt", "desc")
      );
      try {
        onSnapshot(q, (snap) => {
          let data = snap.docs.map((doc) => {
            return { ...doc.data(), id: doc.id };
          });
          dispatch({ type: "FETCH_SUCCESS", payload: data });
        });
      } catch (error) {
        console.log(error);
        dispatch({ type: "FETCH_ERROR", payload: error.message });
      }
    };
    fetchQuestions();
  }, []);

  return (
    <div className="w-full h-full bg-white rounded-2xl p-6 px-10 overflow-auto border-2 border-gray-300">
      <div className="flex items-center justify-between">
        <h2 className="text-2xl font-bold py-2">All Questions</h2>

        <div className="">
          <Button
            version="secondary"
            width="240px"
            handleClick={() => navigate("upload")}
          >
            <div className="flex gap-2 items-center justify-center text-lg font-semibold">
              <FaPlus />
              Upload Question
            </div>
          </Button>
        </div>
      </div>
      <div className="mt-3">
        {questions?.length > 0 ? (
          <QuestionTable questions={questions} />
        ) : loading ? (
          <div className="font-extrabold text-3xl text-gray-400 flex justify-center mt-12">
            Loading ...
          </div>
        ) : error ? (
          <div className="font-extrabold text-3xl text-gray-400 flex justify-center mt-12">
            {error}
          </div>
        ) : (
          <div className="font-extrabold text-3xl text-gray-400 flex justify-center mt-12">
            No Questions Added
          </div>
        )}
      </div>
    </div>
  );
};

export default AllQuestions;
