import React from "react";
import { Checkbox } from "../../../components";
import { Link } from "react-router-dom";
import { auth } from "../../../config/firebaseConfig";
import { GAEventTrack } from "../../../hooks/GA_events";
import { examDuration } from "../../../constant/Constants";

const Step1Instructions = ({
  checked,
  setChecked,
  acknowledgeErrRef,
  error,
}) => {
  return (
    <>
      <h1 className="text-2xl font-bold">Important Instructions:</h1>
      <ul className="space-y-2 mt-2 mb-6 list-disc list-outside ml-4 offset text-base font-medium">
        <li>
          Ensure all personal details (name, class, school) and School details
          are correct during registration. These will appear on Certificates.
        </li>
        <li>
          Ensure your child has a laptop, or desktop with a functioning web
          camera and microphone, and a stable internet connection. The students
          will have to take the exams from their residence or any other place
          where they have access to computer with good Internet connectivity and
          latest version of the browser (preferably Google Chrome). If schools
          have subscribed for online mode of exam, then they may decide to
          conduct these exams using their computer lab
        </li>
        <li>Ensure the camera and microphone are enabled on your browser.</li>
        <li>
          Any cheating detected will lead to disqualification. The decision of
          the jury is final.
        </li>
        <li>
          Log in 15 minutes before the exam to complete verifications and setup.
        </li>
        <li>
          The exam duration is {examDuration} minutes. Participants cannot go
          back to previous questions once they move forward.
        </li>
        <li>
          Attempt as many questions correctly as possible within {examDuration}{" "}
          minutes.
        </li>
        <li>
          This Olympiad is online mode, the exam can't be taken using Mobile
          device.
        </li>
        <li>
          To know about the exam and answer key dates, please check the <br />
          <Link
            to="/examLanding"
            target="_blank"
            className="text-blue-600 hover:underline"
          >
            exam dates
          </Link>{" "}
          page.
        </li>
        <li>
          Do read the{" "}
          <Link
            to="/faqLanding"
            target="_blank"
            className="text-blue-700 font-bold"
          >
            Frequently Asked Questions (FAQs)
          </Link>{" "}
          thoroughly.
        </li>
        <li>
          Kindly make payment only after you have read and accepted the above
          statements. Do note that the fees once paid is non-refundable. For any
          queries or suggestions, do write to us at{" "}
          <a
            href="mailto:info@olympiads.com"
            className="text-blue-700 font-bold"
            onClick={() => {
              //GA event tracking
              GAEventTrack({
                event: "click_on_info@olympiads.com",
                data: {
                  uid: auth?.currentUser?.uid,
                  email: auth?.currentUser?.email,
                },
              });
            }}
          >
            info@olympiads.com
          </a>
          .
        </li>
      </ul>
      <p className="mgt20">
        <strong>Disclaimer:</strong> The advertised price of ₹199 for all
        subjects applies to one participant only. You cannot split the package.
        If you need access for multiple participants, you can purchase separate
        ₹199 packages for each participant.
      </p>
      <div className="checkbox-wrapper">
        <div>
          <div className="flex items-center">
            <Checkbox
              checkId="acknowledge"
              handleChange={() => setChecked(!checked)}
            />
            <label
              htmlFor="acknowledge"
              className="text-sm cursor-pointer pl-[5px] text-[#757575]"
            >
              I acknowledge that I have read and agree to the above Instructions
              Carefully.
            </label>
          </div>
          <div className="text-red-500 -mt-6 ml-5" ref={acknowledgeErrRef}>
            {error || null}
          </div>
        </div>
      </div>
    </>
  );
};

export default Step1Instructions;
