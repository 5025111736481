import React from "react";
const PrivacyPolicy = () => {
  return (
    <>
      <div className="privacy-container mt-20">
        <div className="container">
          <h3>Privacy Policy</h3>
          <p>
            Olympiad Practice respects your privacy and is committed to
            protecting your personal information. This privacy notice for
            Olympiad Practice App (“Company,” “we,” “us,” or “our”), describes
            how and why we might collect, store, use, and/or share (“process”)
            your information when you use our services (“Services”), such as
            when you:
          </p>
          <ul>
            <li>
              Download and use our mobile application (Olympiad Practice), or
              any other application of ours that links to this privacy notice.
            </li>
            <li>
              Engage with us in other related ways, including any sales,
              marking, or events
            </li>
          </ul>
          <h4>Information Collection and Use</h4>
          <p>
            We collect information from our users to provide and improve our
            services. This information may include
          </p>
          <ul>
            <li>
              Personal information, such as name, age, phone number and email
              address, which users may voluntarily provide when creating an
              account or contacting us for support.
            </li>
            <li>
              You may also register for an Olympiad Practice account using
              certain social logins, such as Google and Facebook. If you
              register for Olympiad Practice using a social login, Olympiad
              Practice may receive information about you from your social login
              provider, including your email address and contacts.
            </li>
            <li>
              Usage data, such as quiz scores and progress, is automatically
              collected as users interact with the app.
            </li>
            <li>Application data</li>
          </ul>
          <p>We use this information to:</p>
          <ul>
            <li>
              Provide and improve our services, including personalizing the app
              experience for each user.
            </li>
            <li>Respond to user inquiries and support request.</li>
            <li>Comply with legal obligations.</li>
            <li>
              Request Access or permission to certain features from your mobile
              device, including your mobile device storage, and other features.
              If you wish to change our access to permissions, you may do so in
              your device’s settings.
            </li>
          </ul>
          <p>
            This information is primarily needed to maintain the security and
            operation of our application(s), for troubleshooting, and for our
            internal analytics and reporting purposes.
          </p>
          <p>
            All personal information that you provide to us must be true,
            complete, and accurate, and you must notify us of any changes to
            such personal information.
          </p>
          <h4>Subscription or paid service data:</h4>
          <p>
            When you choose any subscription or paid service, we or our
            third-party payment gateway provider may collect your purchase,
            address or billing information, including your credit card number
            and expiration date etc. However when you purchase a subscription or
            paid service through the Olympiad Practice play/app store, same are
            handled by such platform providers. Subscriptions and paid services
            are automatically renewed unless you cancel them. If at any point
            you do not wish to auto-renew your subscription, you may cancel your
            subscription before the end of the subscription term.
          </p>
          <h4>Cookies</h4>
          <p>
            To improve the responsiveness of the “Application” for our users, we
            may use “cookies”, or similar electronic tools to collect
            information to assign each visitor a unique, random number as a User
            Identification (User ID) to understand the user’s individual
            interests using the identified computer. Unless you voluntarily
            identify yourself (through registration, for example), we will have
            no way of knowing who you are, even if we assign a cookie to your
            computer. The only personal information a cookie can contain is
            information you supply. A cookie cannot read data off your hard
            drive. Our advertisers may also assign their own cookies to your
            browser (if you click on their ads), a process that we do not
            control. We receive and store certain types of information whenever
            you interact with us via website, Application or Service though your
            computer/laptop/netbook or mobile/tablet/pad/handheld device etc.
          </p>
          <h4>Demographic and purchase information:</h4>
          <p>
            We may reference other sources of demographic and other information
            in order to provide you with more targeted communications and
            promotions. We use Google Analytics, among others, to track the user
            behavior on our website. Google Analytics specifically has been
            enabled to support display advertising towards helping us gain
            understanding of our users’ Demographics and Interests. The reports
            are anonymous and cannot be associated with any individual
            personally identifiable information that you may have shared with
            us.
          </p>
          <h4>How do we Process Your Information</h4>
          <p>
            We process your personal information for a variety of reasons,
            depending on how you interact with our Services, including:
          </p>
          <ul>
            <li>
              <b>
                To facilitate account creation and authentication and otherwise
                manage your accounts.
              </b>{" "}
              We may process your information so you can create and log in to
              your account, as well as keep your account in work.
            </li>
            <li>
              <b>To save or protect an individual’s vital interest.</b> We may
              process your information when necessary to save or protect an
              individual’s vital interest, such as to prevent them.
            </li>
            <li>
              Olympiad practice may use your personal information for
              personalized advertising:
              <ul>
                <li>
                  To show you ads that are more relevant to your interests.
                  Olympiad practice may use your personal information to show
                  you ads that are more likely to be of interest to you. For
                  example, if you have been viewing websites about science,
                  Olympiad practice may show you ads for science-related
                  products or services.
                </li>
                <li>
                  To measure the effectiveness of our advertising. Olympiad
                  practice may use your personal information to measure the
                  effectiveness of our advertising campaigns. This information
                  helps us to understand how people are interacting with our ads
                  and to improve the overall quality of our advertising.
                </li>
                <li>
                  To target our advertising to specific audiences. Olympiad
                  practice may use your personal information to target our
                  advertising to specific audiences. For example, we may show
                  ads for science-related products or services to people who
                  have shown an interest in science in the past.
                </li>
              </ul>
            </li>
          </ul>
          <h4>Information Sharing</h4>
          <p>
            We prioritize the protection of your personal information and do not
            sell or share it with third parties, except when necessary for our
            services or required by law. We may share usage data with trusted
            providers to improve our services, and any information you choose to
            share from other sources will be treated in accordance with our
            policy. If you engage with us through a partner, your account and
            order details may be passed on for a better experience with our
            services. Your privacy and data security are our top priorities.
          </p>
          <h4>Data Storage and Security</h4>
          <p>
            We take appropriate measures to protect the information we collect
            and store, including using secure servers and encryption technology
          </p>
          <p>
            We retain personal information for as long as necessary to provide
            our services or as required by law. Usage data may be retained for
            an indefinite period to improve our services.
          </p>

          <h4>Data Retention</h4>
          <p>
            Olympiad Practice will generally retain your personal information
            until your account is deleted. However, Olympiad Practice may retain
            certain information longer if necessary to provide our Service,
            defend our legitimate interests or those of third parties, comply
            with legal requirements, resolve or defend ourselves in disputes,
            investigate misuse or disruption of the Service, or perform
            agreements. We may also retain anonymous data indefinitely.
          </p>
          <h4>User Rights</h4>
          <p>Users have the right to:</p>
          <ul>
            <li>Access and request a copy of their personal information.</li>
            <li>
              Request that their personal information be corrected or deleted.
            </li>
            <li>Object to the processing of their personal information.</li>
          </ul>
          <p>
            To exercise these rights, users can contact us using the information
            provided below.
          </p>
          <h4>Privacy Rights</h4>
          <p>
            In some regions (like EEA, UK, and Canada), you have certain rights
            under applicable data protection laws. These may include the right
            (i) to request access and obtain a copy of your personal
            information, (ii) to request rectification or erasure; (iii) to
            restrict the processing of your personal information, and (iv) If
            applicable, to data portability. In certain circumstances, you may
            also have the right to object to the processing of your personal
            information. You can make such a request by contacting us by using
            the contact details provided in the section “Contact Us” below.
          </p>
          <h4>Do California Residents have Specific Rights?</h4>
          <p>
            If you are under 18 years of age, reside in California, and have a
            registered account with Services, you have the right to request
            removal of unwanted data that you publicly post on the Services.To
            request removal of such data, please contact us using the contact
            information provided below and include the email address associated
            with your account and a statement that you reside in California. We
            will make sure the data is not publicly displayed on the Services,
            but please be aware that the data may not be completely or
            comprehensively removed from all of our systems (e.g., backups,
            etc.).
          </p>
          <h4>Review, Update, or Delete the Data we Collect from You</h4>
          <p>
            Based on the applicable laws of your country, you may have the right
            to request access to the personal information we collect from you,
            change that information, or delete it. To request to review, update,
            or delete your personal information, please contact us at{" "}
            <a href="http://olympiadpractice@naskay.in">
              olympiadpractice@naskay.in
            </a>
            .
          </p>
          <h4>Links to Third-Party Websites</h4>
          <p>
            The Olympiad practice service may contain links to other websites.
            Olympiad practice is not responsible for the content or privacy
            practices of other websites, including any personal or financial
            information collected by third-party payment processors. We
            encourage users to be aware of the privacy policies of other
            websites they visit. This Privacy Policy applies only to information
            collected by Olympiad practice. Any time you click on a link
            (including advertising banners) or submit your personal information
            to a third-party website, you will be subject to that third party’s
            privacy policies.
          </p>
          <h4>Changes to This Policy</h4>
          <p>
            We may update this Privacy Policy from time to time by posting a new
            version on our website, Google Play Store, and App Store. Users
            should review this policy periodically for any changes.
          </p>
        </div>
        <div className="container">
          <h3>Terms and Conditions</h3>
          <p>
            Please read these terms and conditions carefully before using{" "}
            <b>Olympiad practice Application.</b> Using this app, you agree to
            be bound by these terms and conditions.
          </p>
          <h4>Use of the App</h4>
          <p>
            The Olympiad practice Application is designed for personal and
            non-commercial use only. You may not use the app for any illegal or
            unauthorized purpose. Using the app, you represent and warrant that
            you comply with all applicable laws and regulations.
          </p>
          <h4>User Content</h4>
          <p>
            The Olympiad practice Application allows users to take quizzes on
            already created content, including quiz questions and answers. By
            taking the quiz, you grant us a non-exclusive, royalty-free,
            transferable, sub-licensable, worldwide license to use, modify,
            reproduce, distribute, and display the content in connection with
            the app. You represent and warrant that you have the necessary
            rights to grant this license.
          </p>
          <h4>Intellectual Property</h4>
          <p>
            All content and materials included in the Olympiad practice
            Application, including but not limited to text, graphics, logos,
            images, and software, are the property of the Olympiad practice
            Application or its licensors and are protected by copyright and
            other intellectual property laws. You may not use, copy, modify,
            distribute, or reproduce any content or materials from the app
            without our prior written consent.
          </p>
          <h4>Disclaimer of Warranties</h4>
          <p>
            The Olympiad practice Application is provided on an "as is" and "as
            available" basis, without warranties of any kind, express or
            implied. We make no warranty that the app will meet your
            requirements or that it will be uninterrupted, timely, secure, or
            error-free. We are not responsible for any damage to your device or
            loss of data that results from using the app.
          </p>
          <h4>Limitation of Liability</h4>
          <p>
            To the extent permitted by law, we are not liable for any direct,
            indirect, incidental, special, consequential, or punitive damages
            arising from or related to the use of the Olympiad practice
            Application. This includes damages for loss of profits, goodwill,
            use, data, or other intangible losses.
          </p>
          <h4>Governing Law</h4>
          <p>
            These terms and conditions are governed by and constructed in
            accordance with the laws of India, without giving effect to any
            principles of conflicts of law.
          </p>
          <h4>Changes to These Terms and Conditions</h4>
          <p>
            We reserve the right to modify these terms and conditions at any
            time, without prior notice. Your continued use of the Olympiad
            practice Application following any changes to these terms and
            conditions constitutes your acceptance of the changes.
          </p>
          <h4>Termination</h4>
          <p>
            We reserve the right to terminate your use of the Olympiad practice
            Application at any time, without prior notice, for any reason,
            including but not limited to violating these terms and conditions.
          </p>
          <h4>Contact Us</h4>
          <p>
            If you have any questions or concerns about these terms and
            conditions, please contact us at
            <a href="mailto:olympiadpractice@naskay.in">
              {" "}
              olympiadpractice@naskay.in
            </a>
            .
          </p>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
