import envConfig from "../../config/envConfig";

export const parentRegisterSuccess = ({ parentName, userId }) => {
  const subject =
    "Welcome to Practido Online Olympiad 2024! Your Registration is Successful 🎉";
  const emailBody = `
Dear <strong>${parentName}</strong>,

<p>
We are thrilled to welcome you and your child to the Practido community! <br>
Your registration was successful, and we're excited to support your child's academic journey.
</p>
<p>
Important Information:<br>
User ID: <strong>${userId}</strong><br>
Support: If you have any questions or need assistance, our support team is here to help. 
You can reach us at <strong>${envConfig.supportEmail}</strong>.
</p>

<p>
We're committed to providing the best educational experience for your child. <br>
Stay tuned for updates, tips, and exclusive content to make the most out of Practido Online Olympiad 2024.<br>
Thank you for choosing us. Together, let's unlock your child's full potential!
</p>

<p>
Best regards,<br>
Team Practido.
</p>

<p>
Follow us on social media : <br>
<a href="${envConfig.facebookLink}">
  <img
    src="${envConfig.appLink}img/social/fb.png"
    alt="practido facebook link"
  />
</a>
<a href="${envConfig.instagramLink}">
  <img
    src="${envConfig.appLink}img/social/insta.png"
    alt="practido instagram link"
  />
</a>
<a href="${envConfig.twitterLink}">
  <img
    src="${envConfig.appLink}img/social/tw.png"
    alt="practido twitter link"
  />
</a>
</p>
<p>
Download the App:<br>
<a href = "${envConfig.googlePlayLink}">
  <img
    src="${envConfig.appLink}img/googlePlay.png"
    alt="practido google play link"
  />
  <strong>Google Play</strong>
</a>
</p>
  `;

  return { subject, emailBody };
};
