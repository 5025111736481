import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import Header from './Header';
import SliderRating from "./SliderRating";
import ContactForm from './ContactForm'
import Footer from './Footer'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(useGSAP);
gsap.registerPlugin(ScrollTrigger);


const ParentLandingPage = () => {
  const navigate = useNavigate();


  //jump to the contact form
  const contactFormRef = useRef(null);
  const setFormRef = (ref) => {
    contactFormRef.current = ref;
  };

  const scrollToContactForm = () => {
    if (contactFormRef.current) {
      contactFormRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };


  const [activeTab, setActiveTab] = useState(
    localStorage.getItem("activeTab") || ""
  );
  const [showSection, setShowSection] = useState(
    localStorage.getItem("showSection") || ""
  );

  useEffect(() => {
    const tabObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const newActiveTab = entry.target.id;
            setActiveTab(newActiveTab);
            localStorage.setItem("activeTab", newActiveTab);
            document
              .querySelector(".feature-section")
              .classList.add("active-mode");
          }
        });
      }
    );

    const secObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const newShowSection = entry.target.id;
            setShowSection(newShowSection);
            localStorage.setItem("showSection", newShowSection);
          }
        });
      },
      { rootMargin: "-50px 0px 0px 0px" } // Adjust rootMargin to trigger earlier when scrolling up
    );

    // Observe each tab-section
    document.querySelectorAll(".tab-section").forEach((tab) => {
      tabObserver.observe(tab);
    });

    // Observe each section
    document.querySelectorAll(".section").forEach((sec) => {
      secObserver.observe(sec);
    });

    // Clean up observer on component unmount
    return () => {
      tabObserver.disconnect();
      secObserver.disconnect();
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset === 0) {
        document
          .querySelector(".feature-section")
          .classList.remove("active-mode");
      }
    };

    setTimeout(function () {
      ScrollTrigger.refresh();
    }, 2000);

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);


  function handleTabClick(name = '', e = null) {
    if (e) {
      e.preventDefault();
    }
    let el = document.getElementById(name);
    if (el) {
      let top = el.getBoundingClientRect().top;
      let y = top + window.scrollY;
      if (top < 0) {
        y = y - 10;
      } else {
        y = y + 10;
      }
      // console.log(name, y, top, window.scrollY);
      window.scroll({
        top: y,
        behavior: 'smooth'
      })
    }
  }

  const studentsSlider = {
    dots: true,
    arrows: false,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
  };

  const buildSlider = {
    dots: false,
    arrows: true,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
          autoplay: true,
        },
      },
    ],
  };

  useEffect(() => {
    // Animate the #banner-cloud element
    gsap.to("#book-1", {
      duration: 1,
      y: "-=12",
      ease: "Power1.easeInOut",
      yoyo: true,
      repeat: -1,
      delay: 0.5,
    });

    // Animate the #banner-heading element
    gsap.to("#book-2", {
      duration: 1,
      y: "-=12",
      ease: "Power1.easeInOut",
      yoyo: true,
      repeat: -1,
      delay: 0.75,
    });
    gsap.to("#book-3", {
      duration: 1,
      y: "-=12",
      ease: "Power1.easeInOut",
      yoyo: true,
      repeat: -1,
      delay: 1,
    });
  }, []);

  // back to top button set
  useEffect(() => {
    const handleScroll = () => {
      const scrollToTopBtn = document.querySelector(".scrollToTopBtn");
      const rootElement = document.documentElement;

      if (rootElement.scrollTop >= 300) {
        scrollToTopBtn.classList.add("showBtn");
      } else {
        scrollToTopBtn.classList.remove("showBtn");
      }
    };

    const scrollToTop = () => {
      document.documentElement.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };

    const scrollToTopBtn = document.querySelector(".scrollToTopBtn");
    scrollToTopBtn.addEventListener("click", scrollToTop);
    document.addEventListener("scroll", handleScroll);

    return () => {
      scrollToTopBtn.removeEventListener("click", scrollToTop);
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div className="back-to-top">
        <button className="scrollToTopBtn">
          <img src="/img/new-home/back-to-top.png" alt="back-to-top" />
        </button>
      </div>


      <section className='parent-banner'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12 col-lg-6 order-change' data-aos="zoom-in" data-aos-delay="200">
              <h3>Feeling overwhelmed by your child's academic journey? </h3>
              <p>We understand. With our app, track your child's progress effortlessly, identify strengths and weaknesses, and bridge the gap between learning and mastery. No more stress, just straightforward support for your child's academic success—right at your fingertips</p>
              <div className='btn-inline d-none' data-aos="zoom-in" data-aos-delay="200" >
                <button type='btn' className='btn primary-btn'>Get Started</button>
                <button type='btn' className='btn blue-btn'>Already have an Account</button>
              </div>
              <p className='bold'>Download App:</p>
              <div className="banner-icon">
                <span>
                  <a target="_blank" href="https://play.google.com/store/apps/details?id=com.naskay.olympiadpractice" type="button" className="btn btn-link">
                    <img
                      src="/img/student-home/google-play.png"
                      alt="google-play"
                    />
                  </a>
                </span>
              </div>

            </div>
            <div className='col-md-12 col-lg-6 right-item' data-aos="zoom-in" data-aos-delay="200">
              <div className="img-box">
                <img className="center-img" src="/img/parent-home/parent-banner.png" alt="banner" />
                <img id="book-1" className="item-1" src="/img/parent-home/book-1.png" alt="banner" />
                <img id="book-2" className="item-2" src="/img/parent-home/book-2.png" alt="banner" />
                <img id="book-3" className="item-3" src="/img/parent-home/book-3.png" alt="banner" />
              </div>
            </div>
          </div>
        </div>
      </section>


      <section className="parent-our-feature" id="features">
        <div className="container" data-aos="fade-up">
          <div className="container top-head" data-aos="fade-up" data-aos-delay="150" data-aos-duration="500">
            <h3>Parent's Dashboard and Granular Performance Monitoring<span>Upcoming Features in our App</span></h3>
            <p>Our Parent's Dashboard provides you with a real-time insight into your child's progress and achievements. With just a few clicks, you can track their learning journey, view completed tasks, and monitor their overall performance.</p>
          </div>
          <div className="row aos-init aos-animate" data-aos="fade-up">
            <div className="col-md-6 aos-init" data-aos="fade-right" data-aos-delay="150">
              <div className="img-box"><img src="/img/parent-home/parent-feature-1.png" /></div>
            </div>
            <div className="col-md-6 aos-init" data-aos="fade-left" data-aos-delay="150">
              <div className="content">
                <h3>Average Score of Subjects</h3>
                <p>Gain insights into your child's academic performance across subjects with our dashboard, identifying strengths and areas for improvement to tailor their learning through practice effectively. We analyze performance data, highlighting where they excel and need support, ensuring a maximized learning through practice experience.</p>
              </div>
            </div>
          </div>
          {/* row */}
          <div className="row order-change aos-init aos-animate" data-aos="fade-up">
            <div className="col-md-6 aos-init" data-aos="fade-right" data-aos-delay="150">
              <div className="content">
                <h3>Practice Habits: Tracking Learning Consistency</h3>
                <p>Track your child's study habits with our Practice Habits feature, monitoring days practiced and missed for consistent learning. Stay updated on their attendance and engagement, empowering you to provide necessary support for academic success.</p>
              </div>
            </div>
            <div className="col-md-6 aos-init" data-aos="fade-left" data-aos-delay="150">
              <div className="img-box"><img src="/img/parent-home/parent-feature-2.png" /></div>
            </div>
          </div>
          {/* row */}
          <div className="row aos-init aos-animate" data-aos="fade-up">
            <div className="col-md-6 aos-init" data-aos="fade-right" data-aos-delay="150">
              <div className="img-box"><img src="/img/parent-home/parent-feature-3.png" /></div>
            </div>
            <div className="col-md-6 aos-init" data-aos="fade-left" data-aos-delay="150">
              <div className="content">
                <h3>Detail Analysis of Average Score</h3>
                <p>Get quick insights into your child's performance across subjects. Identify their strengths and areas needing improvement to provide targeted support.</p>
              </div>
            </div>
          </div>
          {/* row */}
          <div className="row order-change aos-init aos-animate" data-aos="fade-up">
            <div className="col-md-6 aos-init" data-aos="fade-right" data-aos-delay="150">
              <div className="content">
                <h3>Topic-wise Performance Insights</h3>
                <p>Easily track your child's performance across different topics. Identify strengths and areas for improvement to provide tailored support and guidance.</p>
              </div>
            </div>
            <div className="col-md-6 aos-init" data-aos="fade-left" data-aos-delay="150">
              <div className="img-box"><img src="/img/parent-home/parent-feature-4.png" /></div>
            </div>
          </div>
          {/* row */}
          <div className="row aos-init aos-animate" data-aos="fade-up">
            <div className="col-md-6 aos-init" data-aos="fade-right" data-aos-delay="150">
              <div className="img-box"><img src="/img/parent-home/parent-feature-5.png" /></div>
            </div>
            <div className="col-md-6 aos-init" data-aos="fade-left" data-aos-delay="150">
              <div className="content">
                <h3>Track Performance Over Time</h3>
                <p>Analyze performance data across daily, weekly, monthly, quarterly, and yearly intervals for any subject. Make informed decisions to enhance student learning and growth.</p>
              </div>
            </div>
          </div>
          {/* row */}
          <div className="row order-change aos-init aos-animate" data-aos="fade-up">
            <div className="col-md-6 aos-init" data-aos="fade-right" data-aos-delay="150">
              <div className="content">
                <h3>Peer Grade Comparison</h3>
                <p>Easily compare your child's grades with their peers, ranging from Grade A to Grade F, along with percentage scores. This feature provides valuable insight into your child's academic standing, allowing you to track their progress and identify areas for improvement.</p>
              </div>
            </div>
            <div className="col-md-6 aos-init" data-aos="fade-left" data-aos-delay="150">
              <div className="img-box"><img src="/img/parent-home/parent-feature-6.png" /></div>
            </div>
          </div>
          {/* row */}
          <div className="row aos-init aos-animate" data-aos="fade-up">
            <div className="col-md-6 aos-init" data-aos="fade-right" data-aos-delay="150">
              <div className="img-box"><img src="/img/parent-home/parent-feature-7.png" /></div>
            </div>
            <div className="col-md-6 aos-init" data-aos="fade-left" data-aos-delay="150">
              <div className="content">
                <h3>Detailed Topic Analytics</h3>
                <p>Access comprehensive insights into your child's learning: view average scores, test counts, peer comparisons, and top performers. This data empowers targeted support, fostering continuous growth in learning and development.</p>
              </div>
            </div>
          </div>
          {/* row */}
          <div className="row order-change aos-init aos-animate" data-aos="fade-up">
            <div className="col-md-6 aos-init" data-aos="fade-right" data-aos-delay="150">
              <div className="content">
                <h3>Practice Test History</h3>
                <p>Explore the journey of learning, practice and growth with our Practice Test History feature. Easily access a comprehensive record of your past practice tests, allowing you to track your progress over time. Review your performance, identify areas of improvement, and celebrate your achievements as you strive towards academic excellence. With Practice Test History, your learning journey is in your hands.</p>
              </div>
            </div>
            <div className="col-md-6 aos-init" data-aos="fade-left" data-aos-delay="150">
              <div className="img-box"><img src="/img/parent-home/parent-feature-8.png" /></div>
            </div>
          </div>
          {/* row */}

        </div>
      </section>

      <section className='student-slider-wrapper'>
        <div className='container'>
          <div className='row order-change'>
            <div class="col-lg-6">
              <div className="student-slider-container">
                <Slider {...studentsSlider}>
                  <div className='slider-item'>
                    <img src="/img/student-home/moniter-slider-1.png" alt="slider-images" />
                  </div>
                  <div className='slider-item'>
                    <img src="/img/student-home/moniter-slider-2.png" alt="slider-images" />
                  </div>
                  <div className='slider-item'>
                    <img src="/img/student-home/moniter-slider-3.png" alt="slider-images" />
                  </div>
                </Slider>
              </div>
            </div>
            <div class="col-lg-6">
              <h3>Monitor Student Performance in Real-Time</h3>
              <p>With <span className='bold'>Practido,</span> monitoring your students' daily performance is effortless. Our intuitive dashboard provides real-time insights into each student's progress, allowing you to identify areas of strength and areas for improvement promptly.</p>
              <p>By staying informed, you can take timely actions to tailor your teaching strategies and support individual student needs effectively.</p>
              {/* <button type='button' className='btn blue-btn' onClick={scrollToContactForm}>Book a Demo</button> */}
              <a href="https://calendly.com/shilpi-naskay/30min?month=2023-05" target="_blank" type='button' className='btn blue-btn' onClick={scrollToContactForm}>Book a Demo</a>
            </div>
          </div>
        </div>
      </section>

      <section className="feature-section">
        <div className="tab-section">
          <div
            className="section pink-back mb-0 feature-wrapper build-section"
          >
            <div
              id="reward"
              className="build-wrapper-box"
            >
              <div className="floating-img">
                <img
                  className="floating-animation reward-0"
                  src="/img/student-home/build-back-sm-0.png"
                  alt="images"
                />
                <img
                  className="floating-animation reward-1"
                  src="/img/student-home/build-back-sm-1.png"
                  alt="images"
                />
                <img
                  className="floating-animation reward-2"
                  src="/img/student-home/build-back-sm-2.png"
                  alt="images"
                />
                <img
                  className="floating-animation reward-3"
                  src="/img/student-home/build-back-sm-3.png"
                  alt="images"
                />
                <img
                  className="floating-animation reward-4"
                  src="/img/student-home/build-back-sm-4.png"
                  alt="images"
                />
                <img
                  className="floating-animation reward-5"
                  src="/img/student-home/build-back-sm-5.png"
                  alt="images"
                />
                <img
                  className="floating-animation reward-6"
                  src="/img/student-home/build-back-sm-4.png"
                  alt="images"
                />
                <img
                  className="floating-animation reward-7"
                  src="/img/student-home/build-back-sm-2.png"
                  alt="images"
                />
                <img
                  className="floating-animation reward-8"
                  src="/img/student-home/build-back-sm-1.png"
                  alt="images"
                />
                <img
                  className="floating-animation reward-9"
                  src="/img/student-home/build-back-sm-6.png"
                  alt="images"
                />
                <img
                  className="floating-animation reward-10"
                  src="/img/student-home/build-back-sm-7.png"
                  alt="images"
                />
              </div>
              <div className="build-wrapper">
                <div className="container">
                  <img
                    className="img-1"
                    data-aos="fade-up"
                    src="/img/student-home/build-mobile-3.png"
                    alt="streaks"
                  />
                  <img
                    className="img-1 img-2"
                    data-aos="fade-up"
                    src="/img/student-home/build-mobile-2.png"
                    alt="streaks"
                  />
                  <img
                    className="img-1 img-3"
                    data-aos="fade-up"
                    src="/img/student-home/build-mobile-1.png"
                    alt="streaks"
                  />
                  <img
                    className="img-1 img-4"
                    data-aos="fade-up"
                    src="/img/student-home/build-mobile-4.png"
                    alt="streaks"
                  />
                  <div className="data">
                    <h3>Key Features to Enhance Your Learning Journey</h3>
                    <div className="row">
                      <div className="col-md-3">
                        <div className="shimmer-wrapper"><img src="/img/student-home/build-sm-3.png" alt="Points" /></div>
                        <h4>Practice Points</h4>
                        <p>Earn points by taking practice tests, climb the leaderboard, and collect trophies for major milestones.</p>
                      </div>
                      <div className="col-md-3">
                        <div className="shimmer-wrapper"><img src="/img/student-home/build-sm-2.png" alt="Consistency" /></div>
                        <h4>Coins of Consistency</h4>
                        <p>Earn coins through learning activities and unlock exclusive content and features.</p>
                      </div>
                      <div className="col-md-3">
                        <div className="shimmer-wrapper"><img src="/img/student-home/build-sm-4.png" alt="Trophies" /></div>
                        <h4>Earn Trophies</h4>
                        <p>Scale the leaderboard with Practido Points, earn trophies for achievements, and excel in your learning journey.</p>
                      </div>
                      <div className="col-md-3">
                        <div className="shimmer-wrapper"><img src="/img/student-home/build-sm-1.png" alt="streaks" /></div>
                        <h4>Practice Streaks</h4>
                        <p>Build consistency and boost success with our streak feature, rewarding daily engagement.</p>
                      </div>
                    </div>

                  </div>
                </div>
              </div>

              <div className="mobile-build-box">
                <div className="build-wrapper-mobile">
                  <div className="data">

                    <div className="row">
                      <Slider className="col-lg-12" {...buildSlider}>
                        <div className="slider-item">
                          <img
                            className="img-1"
                            src="/img/student-home/build-mobile-3.png"
                            alt="Points"
                          />
                          <div className="slider-data">
                            <h3>Key Features to Enhance Your Learning Journey</h3>
                            <div className="shimmer-wrapper"><img src="/img/student-home/build-sm-3.png" alt="Points" /></div>
                            <h4>Practice Points</h4>
                            <p>Earn points by taking practice tests, climb the leaderboard, and collect trophies for major milestones.</p>
                          </div>
                        </div>
                        <div className="slider-item item-1">
                          <img
                            className="img-1 img-2"
                            src="/img/student-home/build-mobile-2.png"
                            alt="Consistency"
                          />
                          <div className="slider-data">
                            <h3>Key Features to Enhance Your Learning Journey</h3>
                            <div className="shimmer-wrapper"><img src="/img/student-home/build-sm-2.png" alt="Consistency" /></div>
                            <h4>Coins of Consistency</h4>
                            <p>Earn coins through learning activities and unlock exclusive content and features.</p>
                          </div>
                        </div>
                        <div className="slider-item item-2">
                          <img
                            className="img-1 img-4"
                            src="/img/student-home/build-mobile-4.png"
                            alt="streaks"
                          />
                          <div className="slider-data">
                            <h3>Key Features to Enhance Your Learning Journey</h3>
                            <div className="shimmer-wrapper"><img src="/img/student-home/build-sm-4.png" alt="Trophies" /></div>
                            <h4>Earn Trophies</h4>
                            <p>Scale the leaderboard with Practido Points, earn trophies for achievements, and excel in your learning journey.</p>
                          </div>
                        </div>
                        <div className="slider-item">
                          <img
                            className="img-1 img-3"
                            src="/img/student-home/build-mobile-1.png"
                            alt="Trophies"
                          />
                          <div className="slider-data">
                            <h3>Key Features to Enhance Your Learning Journey</h3>
                            <div className="shimmer-wrapper"><img src="/img/student-home/build-sm-1.png" alt="streaks" /></div>
                            <h4>Practice Streaks</h4>
                            <p>Build consistency and boost success with our streak feature, rewarding daily engagement.</p>
                          </div>
                        </div>
                      </Slider>
                    </div>


                  </div>
                </div>
              </div>


            </div>
          </div>

        </div>
      </section>


      <section className='parent-pdf-section'>
        <div className='feature-wrapper'>
          <div className='container'>
            <div className='feature-box row'>
              <div className='col-md-12 col-lg-7'>
                <div className="img-box">
                  <img className="feature-img" src="/img/parent-home/parent-pdf.png" alt="pdf-downloads" />
                  <img id="book-2" className="cloud" src="/img/parent-home/pdf-1.png" alt="cloud" />
                  <img id="book-3" className="folder" src="/img/parent-home/pdf-2.png" alt="folder" />
                </div>
              </div>
              <div className='col-md-12 col-lg-5 para-box'>
                <h3>Offline PDF Downloads</h3>
                <p>Students can now enjoy the flexibility of learning without screen time on the app! With our offline PDF downloads, they can access practice tests wherever they are – whether on the go, during travels, or in areas with limited internet access.</p>
                <p className='bold'>Download App:</p>
                <div className="banner-icon">
                  <span>
                    <a target="_blank" href="https://play.google.com/store/apps/details?id=com.naskay.olympiadpractice" type="button" className="btn btn-link">
                      <img
                        src="/img/student-home/google-play.png"
                        alt="google-play"
                      />
                    </a>
                  </span>
                </div>
              </div>
            </div>
            {/* feature-box */}
          </div>
        </div>
      </section>

      <section className='parent-practido'>
        <div className='container'>
          <div className="container aos-animate" data-aos="fade-up">
            <div
              className="head"
              data-aos="fade-up"
              data-aos-delay="150"
              data-aos-duration="500"
            >
              <h3>Join Worldwide Practido Online Olympiad
                <span>Join our exciting Practido Online Olympiad, 2024 from home! Engage in friendly competition, showcase your skills, and embark on thrilling learning adventures.  Here's why Practido is the perfect platform:</span>
              </h3>
            </div>
            <div className="row">
              <div className="col-sm-12 col-md-4">
                <div className="img-box">
                  <img src="/img/parent-home/practido-1.png" alt="focused practice" />
                  <img class="first-img zoom-in-zoom-out" src="/img/parent-home/practido-1_1.png" alt="focused practice" />
                  <img class="sec-img zoom-in-zoom-out" src="/img/parent-home/practido-1_2.png" alt="focused practice" />
                </div>
                <h4>Focused Practice</h4>
                <p>We prioritize providing practice questions that solidify your understanding of concepts, not just memorization.</p>
              </div>
              <div className="col-sm-12 col-md-4">
                <div className="img-box">
                  <img src="/img/parent-home/practido-2.png" alt="Engaging Participation" />
                  <img class="bulb-img zoom-in-zoom-out" src="/img/parent-home/practido-2_1.png" alt="focused practice" />
                </div>
                <h4>Engaging Participation</h4>
                <p>Compete with others in a fun and motivating environment, turning practice into a rewarding experience.</p>
              </div>
              <div className="col-sm-12 col-md-4">
                <div className="img-box">
                  <img src="/img/parent-home/practido-3.png" alt="Relevant Questions" />
                  <img class="first-qus zoom-in-zoom-out" src="/img/parent-home/Question_Mark-left.png" alt="Relevant Questions" />
                  <img class="sec-qus zoom-in-zoom-out" src="/img/parent-home/Question_Mark-left.png" alt="Relevant Questions" />
                  <img class="left-first-qus zoom-in-zoom-out" src="/img/parent-home/Question_Mark-right.png" alt="Relevant Questions" />
                  <img class="left-sec-qus zoom-in-zoom-out" src="/img/parent-home/Question_Mark-right-2.png" alt="Relevant Questions" />
                </div>
                <h4>Relevant Questions</h4>
                <p>Our challenges focus on comprehension and application, not just tricky questions, ensuring valuable learning.</p>
              </div>
            </div>
            <div className='center-btn'><button type="button" className="btn blue-btn" onClick={() => window.open("/register", "_blank")}>Register Now</button></div>
          </div>
        </div>
      </section>


      <SliderRating contactFormRef={contactFormRef} />
      <ContactForm contactFormRef={setFormRef} />


    </>
  )
}

export default ParentLandingPage;
