import React from 'react'

const AboutSection = () => {
    return (
        <section className="about-section olympiad-about" id="about">
            <div className="container" data-aos="fade-up">
                <div className="row order-change aos-init aos-animate" data-aos="fade-up">
                    <div className="col-md-6" data-aos="zoom-in" data-aos-delay="100" data-aos-duration="500">
                        <div className="img-box"><img src="/img/new-home/about.png" alt="About" /></div>
                    </div>
                    <div className="col-md-5 offset-md-1" data-aos="zoom-in" data-aos-delay="200" data-aos-duration="600">
                        <div className='data'>
                            <h3><span>About</span><br />Learning is Fun Now</h3>
                            <p>At Practido, we believe in transforming learning into an engaging adventure. We understand that while learning new concepts can be exciting, practicing and mastering them can often feel like a daunting task. That's why we're dedicated to bridging this gap by offering a unique platform that makes practice not only effective but also enjoyable. Unlike traditional methods, Practido integrates fun and learning seamlessly, ensuring students stay motivated and achieve lasting results.</p>
                        </div>
                    </div>
                    {/* row */}
                </div>
            </div>
        </section>
    )
}

export default AboutSection