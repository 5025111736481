import React from "react";
import envConfig from "../../config/envConfig";
import { examDuration } from "../../constant/Constants";

const DetailsSection = ({ setInstructionsModuleOpen }) => {
  return (
    <section className="-mt-5">
      <div className="container">
        <h3>{envConfig.appFullName} Challenge Details</h3>
        <ul className="space-y-3">
          <li>
            Attempt maximum questions with correct answers in {examDuration}{" "}
            mins to become a winner.
          </li>
          <li>E-Certificate to all participants</li>
          <li>
            If cheating is detected, participants could be disqualified, and the
            ultimate decision rests with the juries. Our system includes
            anti-cheating software to ensure fair play.
            {setInstructionsModuleOpen && (
              <button
                type="button"
                className="text-xs p-1 px-2 rounded-full border-2 border-gray-500 ml-2"
                onClick={() => setInstructionsModuleOpen(true)}
              >
                Click here to know more
              </button>
            )}
          </li>
          <li>October, 2024 Onwards</li>
          <li>
            <span className="bold">INR 100</span> only per participant per
            subject
          </li>
          <li>Book your slots now as we have limited seats</li>
          <li>
            Please contact us for any help on our customer support email us to{" "}
            <a className="link" href="mailto:olympiadpractice@naskay.in">
              olympiadpractice@naskay.in
            </a>
          </li>
        </ul>
        <img className="hand" src="/img/new-home/hands.png" alt="img" />
      </div>
    </section>
  );
};

export default DetailsSection;
