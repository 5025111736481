import React, { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { logEvent } from "firebase/analytics";
import { analytics } from "../../config/firebaseConfig";
import { useLocation } from "react-router-dom";
import { GAEventTrack } from "../../hooks/GA_events";

const SliderRating = ({ contactFormRef }) => {
  const location = useLocation();
  const scrollToContactForm = () => {
    if (contactFormRef.current) {
      contactFormRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  const userSlider = {
    dots: false,
    arrows: true,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 1.5,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: false,
        },
      },
    ],
  };

  return (
    <section className="our-user-wrapper">
      <div className="container-fluid">
        <div className="row student-slider-container">
          <Slider className="left-item col-lg-7 col-xl-6" {...userSlider}>
            <div className="slider-item">
              <div className="slider-box blue">
                <img
                  className="star"
                  src="/img/student-home/white-star.png"
                  alt="star"
                />
                <p className="quote">
                  I have 3 kids in class 6, 4 and 2. This app has really helped
                  me as a parent to make sure they practice daily. Thanks for
                  making this app. I suggest if you could add gamification in
                  it. Like making kids earn coins or stickers while practicing
                  on the app. Thanks once again!!
                </p>
                <div className="user-profile-inline">
                  <div className="profile-pic">
                    <img src="/img/profile.png" alt="profile-pic" />
                  </div>
                  <div className="profile-content">soniya Devi</div>
                </div>
              </div>
            </div>
            <div className="slider-item">
              <div className="slider-box orange">
                <img
                  className="star"
                  src="/img/student-home/white-star.png"
                  alt="star"
                />
                <p className="quote">
                  I have been using this app from last 3 months. Saved lot of
                  money for my kid that we spent on books last year. Please
                  provide a parent dashboard with more information to track my
                  kids progress and performance. Thanks once again for
                  developing this app.
                </p>
                <div className="user-profile-inline">
                  <div className="profile-pic">
                    <img src="/img/profile.png" alt="profile-pic" />
                  </div>
                  <div className="profile-content">jetander khurana</div>
                </div>
              </div>
            </div>
            <div className="slider-item">
              <div className="slider-box blue">
                <img
                  className="star"
                  src="/img/student-home/white-star.png"
                  alt="star"
                />
                <p className="quote">
                  Very attractive , colorful and intuitive ui. My children are
                  in 5th and 2nd standards , they find the questions fairly
                  challenging but not too hard. Overall they had positive
                  response towards the app. Different subjects other than math
                  and selectable difficulty would be great additions to the app.
                </p>
                <div className="user-profile-inline">
                  <div className="profile-pic">
                    <img src="/img/profile.png" alt="profile-pic" />
                  </div>
                  <div className="profile-content">Avik Baidya</div>
                </div>
              </div>
            </div>
            <div className="slider-item">
              <div className="slider-box orange">
                <img
                  className="star"
                  src="/img/student-home/white-star.png"
                  alt="star"
                />
                <p className="quote">
                  According to me, this is the best preparation app which
                  delivers quality knowledge to students. Here you can find
                  Maths, Science, English,etc Olympiad study material along with
                  quizzes and answers with explanations. The best part about it,
                  is that it rewards and provides an advantage for the Olympiad
                  aspirants.
                </p>
                <div className="user-profile-inline">
                  <div className="profile-pic">
                    <img src="/img/profile.png" alt="profile-pic" />
                  </div>
                  <div className="profile-content">Sudha Dhawan</div>
                </div>
              </div>
            </div>
            <div className="slider-item">
              <div className="slider-box blue">
                <img
                  className="star"
                  src="/img/student-home/white-star.png"
                  alt="star"
                />
                <p className="quote">
                  This app is one such opportunity to go into the challenging
                  world of problem solving questions. The questions are well
                  designed to test the intellectual knowledge of a student.
                </p>
                <div className="user-profile-inline">
                  <div className="profile-pic">
                    <img src="/img/profile.png" alt="profile-pic" />
                  </div>
                  <div className="profile-content">Reena Purty</div>
                </div>
              </div>
            </div>
            <div className="slider-item">
              <div className="slider-box orange">
                <img
                  className="star"
                  src="/img/student-home/white-star.png"
                  alt="star"
                />
                <p className="quote">
                  Amazing app for preparation and practice.
                </p>
                <div className="user-profile-inline">
                  <div className="profile-pic">
                    <img src="/img/profile.png" alt="profile-pic" />
                  </div>
                  <div className="profile-content">Priti Patel</div>
                </div>
              </div>
            </div>
          </Slider>

          <div className="col-lg-5 col-xl-offset-2 col-xl-4 right-item">
            <div className="slider-item">
              <div className="slider-box right-content">
                <h3>What Our Users Say About Us!</h3>
                <p>
                  Experience the positive impact of{" "}
                  <span className="bold">Practido</span> firsthand today! From
                  interactive challenges to personalized practice tests,
                  Practido is here to support your educational journey every
                  step of the way.{" "}
                </p>
                {/* <button type="button" className="btn blue-btn" onClick={scrollToContactForm}>
                                    Book a Demo
                                </button> */}
                <a
                  rel="noreferrer"
                  href="https://calendly.com/shilpi-naskay/30min?month=2023-05"
                  target="_blank"
                  type="button"
                  className="btn blue-btn"
                  onClick={() => {
                    GAEventTrack({
                      event: `book_demo_btn`,
                    });
                  }}
                >
                  Book a Demo
                </a>
              </div>
            </div>
          </div>
        </div>

        <a
          rel="noreferrer"
          href="https://calendly.com/shilpi-naskay/30min?month=2023-05"
          target="_blank"
          type="button"
          className="btn blue-btn mb-btn"
          onClick={() => {
            GAEventTrack({
              event: `book_demo_btn`,
            });
          }}
        >
          Book a Demo
        </a>
      </div>
    </section>
  );
};

export default SliderRating;
