export const SyllabusTopics = [
    {
        subjectName: 'math',
        subjectId: 'mt',
        classTopics: [
            {
                class: 'class 1',
                classId: 'mt-01',
                topics: [],
                content: 'Coming Soon'
            },
            {
                class: 'class 2',
                classId: 'mt-02',
                topics: [

                ],
                content: 'Coming Soon'
            },
            {
                class: 'class 3',
                classId: 'mt-03',
                topics: [

                ],
                content: 'Coming Soon'
            },
            {
                class: 'class 4',
                classId: 'mt-04',
                topics: [

                ],
                content: 'Coming Soon'
            },
            {
                class: 'class 5',
                classId: 'mt-05',
                topics: [

                ],
                content: 'Coming Soon'
            },
            {
                class: 'class 6',
                classId: 'mt-06',
                topics: [

                ],
                content: 'Coming Soon'
            },
            {
                class: 'class 7',
                classId: 'mt-07',
                topics: [

                ],
                content: 'Coming Soon'
            },
            {
                class: 'class 8',
                classId: 'mt-08',
                topics: [

                ],
                content: 'Coming Soon'
            }
        ]
    },
    {
        subjectName: 'english',
        subjectId: 'en',
        classTopics: [
            {
                class: 'class 1',
                classId: 'en-01',
                topics: [],
                content: 'Coming Soon'
            },
            {
                class: 'class 2',
                classId: 'en-02',
                topics: [

                ],
                content: 'Coming Soon'
            },
            {
                class: 'class 3',
                classId: 'en-03',
                topics: [

                ],
                content: 'Coming Soon'
            },
            {
                class: 'class 4',
                classId: 'en-04',
                topics: [

                ],
                content: 'Coming Soon'
            },
            {
                class: 'class 5',
                classId: 'en-05',
                topics: [],
                content: 'Coming Soon'
            },
            {
                class: 'class 6',
                classId: 'en-06',
                topics: [
                ],
                content: 'Coming Soon'

            }
        ]
    },
    {
        subjectName: 'Science',
        subjectId: 'sc',
        classTopics: [
            {
                class: 'class 1',
                classId: 'en-01',
                topics: [],
                content: 'Coming Soon'
            },
            {
                class: 'class 2',
                classId: 'en-02',
                topics: [

                ],
                content: 'Coming Soon'
            },
            {
                class: 'class 3',
                classId: 'en-03',
                topics: [

                ],
                content: 'Coming Soon'
            },
            {
                class: 'class 4',
                classId: 'en-04',
                topics: [

                ],
                content: 'Coming Soon'
            },
            {
                class: 'class 5',
                classId: 'en-05',
                topics: [],
                content: 'Coming Soon'
            },
            {
                class: 'class 6',
                classId: 'en-06',
                topics: [
                ],
                content: 'Coming Soon'

            }
        ]
    },

]
