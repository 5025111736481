import React, { useEffect, useState } from "react";

const SchoolLogo = ({ schoolDetails }) => {
  const [logoLoading, setLogoLoading] = useState(true);
  useEffect(() => {
    const img = new Image();
    img.src = schoolDetails?.schoolLogoUrl;
    img.onload = () => setLogoLoading(false);
  }, [schoolDetails]);
  if (schoolDetails)
    return (
      <div className="col-span-2 flex items-center justify-center gap-5">
        <div className="bg-gray-400 w-20 h-20 overflow-hidden flex items-center justify-center rounded-full flex-shrink-0">
          {logoLoading || !schoolDetails?.schoolLogoUrl ? (
            <div className="w-full h-full bg-gray-600 flex items-center justify-center">
              <span className="text-5xl font-bold text-white capitalize">
                {schoolDetails?.schoolName[0]}
              </span>
            </div>
          ) : (
            <img
              src={schoolDetails?.schoolLogoUrl}
              alt="schoolLogo"
              className="h-full w-full"
            />
          )}
        </div>

        <div className="flex flex-col">
          <h2 className="text-2xl font-semibold">
            {schoolDetails?.schoolName}
          </h2>
          <p className="text-sm">
            {schoolDetails?.schoolAddress.address},{" "}
            {schoolDetails?.schoolAddress.city},{" "}
            {schoolDetails?.schoolAddress.state},{" "}
            {schoolDetails?.schoolAddress.country},{" "}
            {schoolDetails?.schoolAddress.pin}
          </p>
        </div>
      </div>
    );
};

export default SchoolLogo;
