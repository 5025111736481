import React, { useEffect, useState } from "react";
import { Button, TextInput } from "../../../../components";
import {
  EmailAuthProvider,
  linkWithCredential,
  onAuthStateChanged,
  sendEmailVerification,
} from "firebase/auth";
import { auth } from "../../../../config/firebaseConfig";
import { useNavigate } from "react-router-dom";
import { validatePassword } from "../../../../assets/js/validation";
import envConfig from "../../../../config/envConfig";
import { schoolRegisterSuccess } from "../../../../constant/emailTemplates/schoolRegisterSuccess";
import { sendEmail } from "../../../../constant/sendEmail";
import { GAEventTrack } from "../../../../hooks/GA_events";

const PasswordModule = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(null);

  const [error, setError] = useState(null);
  const [emailId, setEmailId] = useState("");
  const [verificationEmailSent, setVerificationEmailSent] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        const localStorageEmail = localStorage.getItem(
          "emailForSettingPassword"
        );
        if (localStorageEmail) return setEmailId(localStorageEmail);
      } else {
        navigate("/register/school");
      }
    });

    return () => unsubscribe(); // Cleanup when component unmounts
  }, [navigate]);

  const sendVerificationEmail = async ({ resend }) => {
    const actionSettings = {
      url: `${envConfig?.appUrl}schoolDashboard`,
      handleCodeInApp: true,
    };
    setLoading((prev) => ({ ...prev, resend: true }));
    sendEmailVerification(auth.currentUser, actionSettings)
      .then(() => {
        // setVerificationEmailSent(true);
        navigate("/schoolDashboard");

        //GA event tracking
        GAEventTrack({
          event: resend ? "sch_verif_email_resend" : "sch_verif_email_sent",
          data: {
            uid: auth?.currentUser?.uid,
            userEmail: emailId,
          },
        });
      })
      .catch((error) => {
        console.log(error.message);
        throw error;
      })
      .finally(() => setLoading((prev) => ({ ...prev, resend: false })));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    const formData = e.target;
    const password = formData.pass.value;
    const rePassword = formData.repass.value;

    // validate password - length, match
    if (!validatePassword(password)) {
      setError((prev) => ({
        ...prev,
        password: "Please fill a strong password with the below conditions",
      }));
      console.log("password error");
      return;
    }
    if (password !== rePassword) {
      return setError((prev) => ({
        ...prev,
        repassword: "Passwords do not match",
      }));
    }

    // 1. create credential with email and password
    const emailCredential = EmailAuthProvider.credential(emailId, password);

    //GA event tracking
    GAEventTrack({
      event: "sch_password_submit",
      data: {
        uid: auth?.currentUser?.uid,
        userEmail: emailId,
      },
    });

    setLoading((prev) => ({ ...prev, submit: true }));
    try {
      // 2. Link the phone authentication credential to the email/password account
      await linkWithCredential(auth.currentUser, emailCredential);

      //GA event tracking
      GAEventTrack({
        event: "sch_auth_linked",
        data: {
          uid: auth?.currentUser?.uid,
          userEmail: emailId,
        },
      });

      // 3. send verification email
      await sendVerificationEmail();

      // 4. send success email
      const { subject, emailBody } = schoolRegisterSuccess({
        schoolName: auth?.currentUser?.displayName,
        emailId,
      });
      const emailResponse = await sendEmail({ emailId, subject, emailBody });
      console.log(emailResponse);

      // 5. remove the email from local storage
      localStorage.removeItem("emailForSettingPassword");
    } catch (error) {
      setError((prev) => ({
        ...prev,
        other: error.code,
      }));
    } finally {
      setLoading((prev) => ({ ...prev, submit: false }));
    }
  };

  return (
    <div className="">
      {verificationEmailSent ? (
        <div className="space-y-10">
          <div className="flex items-center justify-center gap-4 w-full bg-green-100 p-2 rounded-xl">
            <img src="/img/success.png" alt="" className="w-12 h-12" />

            <p className="!font-semibold !text-lg text-center">
              Verification Email sent.
            </p>
          </div>
          <div className="space-y-6">
            <h1 className="text-2xl font-bold">Verify your email address</h1>
            <div className="space-y-2">
              <p>
                Please confirm your email address by clicking on the link in the
                email we sent to :{" "}
                <span className="!font-semibold">
                  {emailId.split("@")[0]?.slice(0, 3)}
                  {"•".repeat(emailId.split("@")[0].length - 3)}@
                  {emailId.split("@")[1]}
                </span>
              </p>
            </div>

            <Button
              version="secondary"
              text="Resend Email"
              width="200px"
              handleClick={() => sendVerificationEmail({ resend: true })}
              loading={loading?.resend}
            />
            <p>
              Not the correct email?{" "}
              <span
                className="text-[#009DFF] font-semibold cursor-pointer hover:underline"
                onClick={() => {
                  //GA event tracking
                  GAEventTrack({
                    event: "back_to_register_sch",
                  });
                  navigate("/register/school");
                }}
              >
                Back to Register
              </span>
            </p>
          </div>
        </div>
      ) : (
        <>
          <h2 className="text-xl font-bold mb-4">Set your password</h2>
          <form onSubmit={(e) => handleSubmit(e)} className="space-y-4">
            <div>
              <label className="text-sm font-semibold" htmlFor="email">
                Email
              </label>

              <span className="block font-bold text-base">{emailId}</span>
            </div>
            <div>
              <label className="text-sm font-semibold" htmlFor="pass">
                Enter your Password*
              </label>
              <TextInput
                type="password"
                idName="pass"
                inputName="pass"
                placeholderText="• • • • • • • •"
                error={error?.password}
                isRequired={true}
              />
            </div>
            <div>
              <label className="text-sm font-semibold" htmlFor="repass">
                Confirm your Password*
              </label>
              <TextInput
                type="password"
                idName="repass"
                inputName="repass"
                placeholderText="• • • • • • • •"
                error={error?.repassword}
                isRequired={true}
              />
            </div>
            <div>
              <h2 className="text-lg font-bold">Creating a Strong Password:</h2>
              <ul className="list-disc ml-4 space-y-2">
                <li>
                  Use a combination of uppercase and lowercase letters, numbers,
                  and symbols (e.g., !@#$%^&*).
                </li>
                <li>
                  Avoid using personal information like your name, birthday, or
                  address.
                </li>
                <li>Don't reuse passwords from other accounts.</li>
                <li>Make it at least 8 characters long.</li>
              </ul>
            </div>

            {error && <div className="text-red-500">{error?.other}</div>}
            <Button
              version="secondary"
              text="Submit"
              type="submit"
              loading={loading?.submit}
            />
          </form>
        </>
      )}
    </div>
  );
};

export default PasswordModule;
