import React, { useEffect, useRef } from "react";
import { Outlet } from "react-router-dom";
import Header from "../../landingPage/Header";
import Footer from "../../landingPage/Footer";
import ContactForm from "../../landingPage/ContactForm";
import SliderRating from "../../landingPage/SliderRating";
import OlympiadExams from "../OlympiadExams";

const RegisterLayout = () => {
  const contactFormRef = useRef(null);
  const setFormRef = (ref) => {
    contactFormRef.current = ref;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Header placement="register" />
      <>
        <Outlet />
      </>
      <OlympiadExams />
      <SliderRating contactFormRef={contactFormRef} />
      <ContactForm contactFormRef={setFormRef} />
      <Footer />
    </div>
  );
};

export default RegisterLayout;
