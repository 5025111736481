import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Instructions from "../auth/onboarding/Instructions";
import DetailsSection from "./DetailsSection";
import ContactForm from "./ContactForm";
import gsap from "gsap";

const OlympiadLanding = () => {
  const navigate = useNavigate();
  const [instructionModuleOpen, setInstructionsModuleOpen] = useState(false);

  useEffect(() => {
    // Animate the #banner-cloud element
    gsap.to("#banner-cloud", {
      duration: 1,
      y: "-=12",
      ease: "Power1.easeInOut",
      yoyo: true,
      repeat: -1,
      delay: 0.5,
    });

    // Animate the #banner-heading element
    gsap.to("#banner-heading", {
      duration: 1,
      y: "-=12",
      ease: "Power1.easeInOut",
      yoyo: true,
      repeat: -1,
      delay: 0.75,
    });
  }, []);

  return (
    <>
      <section className="banner-section home-banner-section student-banner">
        <div className="container">
          <div className="row order-change register-section">
            <div className="col-md-12 col-lg-12">
              <div className="content">
                <div className="banner-img">
                  <img
                    id="banner-cloud"
                    className="banner-cloud"
                    src="/img/new-home/banner-cloud.png"
                    alt="banner"
                  />
                  <img
                    id="banner-heading"
                    className="banner-heading"
                    src="/img/new-home/banner-heading.png"
                    alt="banner"
                  />
                </div>

                <div className="btn-space">
                  <div className="d-none olympiad-date">
                    <img src="/img/new-home/calendar.png" alt="calendar" />
                    <span>October 2024 Onwards</span>
                  </div>
                  <button
                    type="button"
                    className="btn primary-btn"
                    onClick={() => navigate("/register")}
                  >
                    Register Now
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="relative detail-section">
        <DetailsSection setInstructionsModuleOpen={setInstructionsModuleOpen} />
      </div>
      <div>{/* past challenges */}</div>
      <ContactForm />
      {instructionModuleOpen && (
        <div className="detail-section instructions-modal">
          <>
            <Instructions
              setInstructionsModuleOpen={setInstructionsModuleOpen}
            />
          </>
        </div>
      )}
    </>
  );
};

export default OlympiadLanding;
