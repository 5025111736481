import envConfig from "../../config/envConfig";

export const schoolRegisterSuccess = ({ schoolName, emailId }) => {
  const subject =
    "Welcome to Practido Online Olympiad 2024! Your Registration is Successful";
  const emailBody = `
Dear <strong>${schoolName}</strong> Team,<br>
<p>We are thrilled to welcome you to Practido Online Olympiad 2024!<br>
Your registration has been successfully completed, and you are now part of our growing community dedicated to academic excellence.
</p> 
<p>
<strong>Account Details:</strong><br>
School Name: <strong>${schoolName}</strong><br>
Registered Email: <strong>${emailId}</strong></p>

<p>Need Help? Our support team is here to assist you with any questions or concerns. You can reach out to us at 
<strong>${envConfig.supportEmail}</strong>.</p>

<p>Thank you for choosing Practido Online Olympiad 2024.<br> 
We are excited to support your journey towards academic success and look forward to seeing your students thrive!</p>

Warm regards,<br>
<strong>Team Practido</strong>

<p>
Follow us on social media:<br>
Facebook : ${envConfig.facebookLink}<br>
Twitter : ${envConfig.twitterLink}<br>
Instagram : ${envConfig.instagramLink}<br>
</p>
`;

  return { subject, emailBody };
};
