import React, { useState } from "react";
import { GAEventTrack } from "../../../../hooks/GA_events";

const SchoolRegistrationInstructions = ({ placement }) => {
  const [isFullDataVisible, setIsFullDataVisible] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const toggleFullDataVisibility = () => {
    setIsFullDataVisible(!isFullDataVisible);
    setIsActive(!isActive);
  };
  return (
    <>
      <img
        className="banner-cloud"
        src="/img/new-home/cloud-banner-common.png"
        alt="banner"
      />
      <div className="reg-data">
        <div
          className={`w-full mobile-heading ${isActive ? "active" : ""}`}
          onClick={toggleFullDataVisibility}
        >
          <h3>Why Practido Online Olympiad?</h3>
        </div>

        <div
          className={`full-data show ${
            placement === "dashboard" || isFullDataVisible
              ? ""
              : "!hidden md:flex"
          }`}
        >
          <p>
            Understanding the benefits of the Practido Online Olympiad is
            essential before offering these exams to your students. Here’s why
            it’s a valuable opportunity:
          </p>
          <ol className="order-list-data">
            <li>
              <span className="bold">Expert-Driven Assessment:</span> The
              Practido Online Olympiad is crafted by leading education experts,
              focusing on enhancing students' practical knowledge and their
              ability to apply skills across various subjects.
            </li>
            <li>
              <span className="bold">First-of-Its-Kind Online Format:</span> We
              are one of the pioneers in offering a fully online proctored
              Olympiad, allowing students to participate from the safety and
              comfort of their homes, ensuring a convenient and secure
              examination process.
            </li>
            <li>
              <span className="bold">
                No Special Preparation Material Needed:
              </span>{" "}
              While we do not provide specific preparation materials or sample
              papers, students can effectively prepare using their school
              textbooks and our dedicated Olympiad Practice App.{" "}
              <a
                target="blank"
                href="https://play.google.com/store/apps/details?id=com.naskay.olympiadpractice"
                onClick={() => {
                  GAEventTrack({
                    event: "download_app",
                  });
                }}
              >
                Download the app here
              </a>{" "}
              from the Play Store.
            </li>
            <li>
              <span className="bold">Flexible Scheduling:</span> Unlike
              traditional Olympiads, students have the flexibility to choose
              their preferred time slot, making it easier to accommodate
              different schedules and time zones.
            </li>
            <li>
              <span className="bold">Subject Diversity:</span> Our Olympiads
              cover three key subjects:{" "}
              <span className="bold">English, Maths, and Science,</span>{" "}
              catering to a broad range of academic interests and strengths.
            </li>
            <li>
              <span className="bold">International Participation:</span> The
              Olympiad provides a platform for students to compete on an
              international level, expanding their horizons and exposure to a
              diverse set of competitors.
            </li>
          </ol>
          <h3>Registration and Participation</h3>
          <ol className="order-list-data mgt0">
            <li>
              <span className="bold">Open to Classes 1 to 8:</span> The Olympiad
              is open to all students from{" "}
              <span className="bold">Class 1 to 8,</span> enabling participation
              from the comfort of their homes.
            </li>
            <li>
              <span className="bold">Technical Requirements:</span> Participants
              need access to a <span className="bold">laptop</span>, or{" "}
              <span className="bold">desktop</span> equipped with a web camera,
              microphone, and a stable internet connection.
            </li>
            <li>
              <span className="bold">Accurate Information:</span> It is crucial
              to provide accurate details such as the school name and
              participant’s name, as these will appear on the certificates.
            </li>
          </ol>
          <h3>Fees and Payment Details</h3>
          <ul className="bullet-list-data mgt0 ">
            <li>Participation Fee:</li>
            <ul className="bullet-list-data mgb0 mgt0">
              <li>
                <span className="bold">Rs. 99</span> for one subject or{" "}
                <span className="bold">Rs. 199</span> for all three subjects{" "}
                <span className="bold">(Maths, Science, English)</span> for
                Indian students.
              </li>
              <li>
                <span className="bold">$5</span> for one subject or{" "}
                <span className="bold">$10</span> for all three subjects{" "}
                <span className="bold">(Maths, Science, English)</span> for
                international students.
              </li>
            </ul>
            <li>Payment Policy: The fee is non-refundable once paid.</li>
          </ul>
          <h3>Benefits for Schools</h3>
          <ol className="order-list-data mgt0">
            <li>
              Recognition Awards:
              <ul className="bullet-list-data mgb0 mgt0">
                <li>Schools receive a Trophy for Academic Excellence.</li>
                <li>
                  Top 5 Schools and Principals will be awarded with cash prizes
                  and appreciation awards.
                </li>
              </ul>
            </li>
            <li>
              <span className="bold">Participation Requirement:</span> A minimum
              of 50 students from a school must participate to qualify for these
              benefits.
            </li>
          </ol>
          <h3>Important Dates</h3>
          <ul className="bullet-list-data mgt0">
            <li>
              The Practido Online Olympiad dates will be announced in{" "}
              <span className="bold">October 2024</span>.
            </li>
            <li>
              Registration starts from <span className="bold">August 2024</span>{" "}
              to <span className="bold">September 2024</span>.
            </li>
          </ul>
          <h3>Contact Information</h3>
          <p>
            For assistance, please contact our customer support at{" "}
            <a
              className="blue"
              href="mailto:olympiadpractice@naskay.in"
              onClick={() => {
                //GA event tracking
                GAEventTrack({
                  event: "support_email_click",
                });
              }}
            >
              olympiadpractice@naskay.in
            </a>
            .
          </p>
        </div>
      </div>
    </>
  );
};

export default SchoolRegistrationInstructions;
