import React, { useEffect, useState } from "react";
import Header from "../landingPage/Header";
import { Outlet } from "react-router-dom";
import Footer from "../landingPage/Footer";
import { onAuthStateChanged } from "firebase/auth";
import {
  auth,
  parentsCollectionRef,
  schoolCollectionRef,
} from "../../config/firebaseConfig";
import { getDocs, query, where } from "firebase/firestore";

const DashobardLinks = () => {
  const [AuthUser, setAuthUser] = useState(null);
  const [userData, setUserData] = useState(null);
  const [userType, setUserType] = useState(null);
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setAuthUser(user);
        getUserData(user?.uid);
      }
    });
    return () => unsubscribe(); // Cleanup when component unmounts
  }, []);

  const getUserData = async (userId) => {
    let userData = [];
    try {
      const schoolSnapshot = await getDocs(
        query(schoolCollectionRef, where("uid", "==", userId))
      );
      schoolSnapshot.docs.map((doc) =>
        userData.push({
          id: doc.id,
          ...doc.data(),
        })
      );

      const parentsSnapshot = await getDocs(
        query(parentsCollectionRef, where("uid", "==", userId))
      );
      parentsSnapshot.docs.map((doc) =>
        userData.push({
          id: doc.id,
          ...doc.data(),
        })
      );

      setUserData(userData[0]);
      setUserType(userData[0]?.accountType);
    } catch (error) {
      throw ("Error fetching data:", error);
    }
  };

  return (
    <>
      <Header
        placement={AuthUser && userType === "parent" ? "dashboard" : "register"}
        // dashboard={userType}
      />
      <Outlet />
      <Footer />
    </>
  );
};

export default DashobardLinks;
