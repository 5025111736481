import {
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  auth,
  db,
  parentsCollectionRef,
  schoolCollectionRef,
} from "../../../config/firebaseConfig";
import SchoolLogo from "../school/SchoolLogo";
import { onAuthStateChanged } from "firebase/auth";
import StudentView from "./StudentView";
import EditStudent from "./EditStudent";
import AddSubjects from "./AddSubjects";

const StudentLayout = ({ page }) => {
  const { studentId } = useParams("studentId");
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [studentDetails, setStudentDetails] = useState(null);
  const [schoolDetails, setSchoolDetails] = useState(null);
  const [parentDetails, setParentDetails] = useState(null);
  const [accountType, setAccountType] = useState();

  useEffect(() => {
    const fetchUserDetails = async () => {
      const uid = auth.currentUser.uid;
      let userData = [];
      try {
        const schoolSnapshot = await getDocs(
          query(schoolCollectionRef, where("uid", "==", uid))
        );
        schoolSnapshot.docs.map((doc) =>
          userData.push({
            id: doc.id,
            ...doc.data(),
          })
        );

        // Fetch parents
        const parentsSnapshot = await getDocs(
          query(parentsCollectionRef, where("uid", "==", uid))
        );

        parentsSnapshot.docs.map((doc) =>
          userData?.push({ id: doc.id, ...doc.data() })
        );

        setAccountType(userData[0]?.accountType);
        if (userData[0]?.accountType === "school")
          return setSchoolDetails(userData[0]);
        if (userData[0]?.accountType === "parent")
          return setParentDetails(userData[0]);
        else navigate("/login");
      } catch (error) {
        throw ("Error fetching data:", error);
      }
    };

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        fetchUserDetails();
      } else {
        navigate("/login");
      }
    });
    return () => unsubscribe(); // Cleanup when component unmounts
  }, [navigate]);

  useEffect(() => {
    const schoolId = parentDetails?.schoolId;
    const fetchSchooolDetails = async () => {
      const schoolData = await getDoc(doc(schoolCollectionRef, schoolId));
      setSchoolDetails({
        schoolId: schoolData.id,
        ...schoolData.data(),
      });
    };
    schoolId && fetchSchooolDetails();
  }, [parentDetails]);

  useEffect(() => {
    const fetchStudentDetails = async () => {
      try {
        const studentDocRef = doc(db, "participants", studentId);
        onSnapshot(studentDocRef, async (document) => {
          if (!document.exists()) {
            console.log("No student document!");
            return setError((prev) => ({
              ...prev,
              studentDetails: "No student",
            }));
          }
          const parentId = await document?.data()?.parentDocId;
          if (parentId) {
            const parentDocRef = doc(db, "parents", parentId);
            const parentDoc = await getDoc(parentDocRef);
            setStudentDetails({
              ...document.data(),
              parent: { ...parentDoc?.data(), id: parentDoc?.id },
            });
          }
        });
      } catch (error) {
        console.log(error);
      }
    };
    studentId && fetchStudentDetails();
  }, [studentId]);

  return (
    <div className="student-view-container container space-y-8">
      {schoolDetails && (
        <div className="flex items-center justify-center p-4">
          <SchoolLogo schoolDetails={schoolDetails} />
        </div>
      )}

      {page === "view" && (
        <StudentView
          studentDetails={studentDetails}
          accountType={accountType}
        />
      )}

      {page === "edit" && <EditStudent studentDetails={studentDetails} />}

      {page === "addSubjects" && (
        <AddSubjects studentDetails={studentDetails} />
      )}
    </div>
  );
};

export default StudentLayout;
