import React, { useRef } from "react";
import ContactForm from './ContactForm';


const Contactlanding = () => {
    const contactFormRef = useRef(null);
    const setFormRef = (ref) => {
        contactFormRef.current = ref;
    };

    return (
        <div className="contact-container">
            <ContactForm contactFormRef={setFormRef} />
        </div>

    )
}

export default Contactlanding