import React from "react";
import { Outlet } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";

const LandingLayout = () => {
  return (
    <>
      <Header placement="landingPage" />
      <Outlet />
      <Footer placement="landingPage" />
    </>
  );
};

export default LandingLayout;
