import React from "react";
import DisplayTheOutput from "rich-text-editor-for-react/display-output";
import { RxCross2 } from "react-icons/rx";

const Preview = ({
  formData,
  statement,
  options,
  setShowPreview,
  answerExplanation,
}) => {
  const opt = ["A", "B", "C", "D"];
  return (
    <div className="w-full md:w-2/3 lg:w-1/2 h-full bg-white rounded-2xl shadow-md pl-6 pr-3 pt-4 space-y-4 relative ">
      <div
        className="absolute top-4 right-4 w-8 h-8 flex items-center justify-center bg-gray-200 rounded-full cursor-pointer z-10"
        onClick={() => setShowPreview(false)}
      >
        <RxCross2 className="w-5 h-5" />
      </div>
      <h2 className="text-2xl font-bold">Preview</h2>

      <div className="flex gap-4 text-gray-700">
        <p className="p-1 px-3 bg-gray-100 border-2 border-gray-200 rounded-full">
          Class :{" "}
          <span className="font-semibold">
            {formData?.class?.value || "Class"}
          </span>
        </p>
        <p className="capitalize p-1 px-3 bg-gray-100 border-2 border-gray-200 rounded-full">
          Subject :{" "}
          <span className="font-semibold">
            {formData?.subject?.value || "Subject"}
          </span>
        </p>
      </div>
      <div className="flex flex-wrap gap-2 items-center text-gray-700">
        <span className="text-base font-semibold">Topics :</span>
        <div className="flex flex-wrap gap-2 items-center">
          {formData?.topics?.value
            ? formData?.topics?.value
                ?.split(", ")
                ?.map((topic) => (
                  <span className="capitalize p-1 px-3 bg-gray-100 border-2 border-gray-200  rounded-full">
                    {topic}
                  </span>
                ))
            : "Topics"}
        </div>
      </div>
      <div className="flex flex-wrap gap-2 items-center text-gray-700">
        <span className="text-base font-semibold">Sub Topics :</span>
        <div className="flex flex-wrap gap-2 items-center">
          {formData?.subTopics?.value
            ? formData?.subTopics?.value
                ?.split(", ")
                ?.map((topic) => (
                  <span className="capitalize p-1 px-3 bg-gray-100 border-2 border-gray-200  rounded-full">
                    {topic}
                  </span>
                ))
            : "Topics"}
        </div>
      </div>

      <div className="w-full h-[75%] overflow-y-scroll scrollable pr-3">
        <div className="h-full text-[#34495E] mt-2 space-y-2">
          <p className="text-lg font-bold">
            {formData?.questionName?.value || "Question Name"}
          </p>
          <div className="rounded-xl overflow-hidden bg-[#eee] flex gap-2 pl-3 items-center">
            <DisplayTheOutput
              html={
                statement === "<p><br></p>" || statement === "<p></p>"
                  ? "<p>Question Statement</p>"
                  : statement
              }
              backgroundColor="#eee"
              primaryColor="#20464b"
            />
          </div>

          <div className="space-y-2">
            <p className="text-lg font-bold">Options :</p>
            <div className="grid grid-cols-2 gap-2">
              {options.map((option, index) => {
                return (
                  <div
                    key={index}
                    className={`rounded-xl overflow-hidden flex gap-2 pl-3 items-center ${
                      opt[formData?.correct?.value - 1] === opt[index]
                        ? "bg-[#cfd]"
                        : "bg-[#eee]"
                    }`}
                  >
                    <span className="text-lg font-semibold">
                      {opt[index]}.{" "}
                    </span>
                    <DisplayTheOutput
                      html={
                        option === "<p><br></p>" || option === "<p></p>"
                          ? `<p>Option ${opt[index]} </p>`
                          : option
                      }
                      backgroundColor={
                        opt[formData?.correct?.value - 1] === opt[index]
                          ? "#cfd"
                          : "#eee"
                      }
                      primaryColor="#20464b"
                    />
                  </div>
                );
              })}
            </div>
          </div>
          <div className="rounded-xl overflow-hidden bg-[#eee] flex flex-col p-3">
            <p className="text-base font-semibold">Answer Explanation :</p>
            <DisplayTheOutput
              html={
                answerExplanation === "<p><br></p>" ||
                answerExplanation === "<p></p>"
                  ? "<p>Answer Explanation</p>"
                  : answerExplanation
              }
              backgroundColor="#eee"
              primaryColor="#20464b"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Preview;
