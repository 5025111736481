import React, { useEffect, useRef, useState } from "react";
import { Button, SelectInput, TextInput } from "../../../../components";
import RichTextInput from "../../../../components/formComponents/richTextInput/RichTextInput";
import { questionsCollectionRef } from "../../../../config/firebaseConfig";
import { Timestamp, addDoc, doc, getDoc } from "firebase/firestore";
import Preview from "./Preview";
import { useNavigate, useParams } from "react-router-dom";

const UploadQuestion = () => {
  const [loading, setLoading] = useState(false);

  const [statement, setStatement] = useState("");
  const [optionA, setOptionA] = useState("");
  const [optionB, setOptionB] = useState("");
  const [optionC, setOptionC] = useState("");
  const [optionD, setOptionD] = useState("");
  const [answerExplanation, setAnswerExplanation] = useState("");
  const [editQuesData, setEditQuesData] = useState({});

  const [showPreview, setShowPreview] = useState(false);

  const navigate = useNavigate();
  const formRef = useRef();
  const { id } = useParams();
  const [previewData, setPreviewData] = useState({});

  useEffect(() => {
    const fetchEditQuesData = async () => {
      try {
        const qusRef = doc(questionsCollectionRef, id);
        const question = await getDoc(qusRef);
        setEditQuesData({ ...question.data(), id: question.id });
      } catch (error) {
        console.log(error);
      }
    };
    fetchEditQuesData();
  }, [id]);

  const openPreview = (e) => {
    setPreviewData(formRef.current);
    setShowPreview(true);
  };

  const fields = [
    {
      name: "class",
      label: "Class",
      placeholder: "Select Class",
      type: "select",
      options: [
        { id: 1, label: `Class 1st`, value: 1 },
        { id: 2, label: `Class 2nd`, value: 2 },
        { id: 3, label: `Class 3rd`, value: 3 },
        { id: 4, label: `Class 4th`, value: 4 },
        { id: 5, label: `Class 5th`, value: 5 },
        { id: 6, label: `Class 6th`, value: 6 },
        { id: 7, label: `Class 7th`, value: 7 },
        { id: 8, label: `Class 8th`, value: 8 },
      ],
      required: true,
      colspan: 1,
    },
    {
      name: "subject",
      label: "Subject",
      placeholder: "Select Subject",
      type: "select",
      options: [
        { id: 1, label: `Maths`, value: "maths" },
        { id: 2, label: `English`, value: "english" },
        { id: 3, label: `Science`, value: "science" },
      ],
      required: true,
      colspan: 1,
    },
    {
      name: "topics",
      label: "Topics",
      placeholder: "eg. topic1, topic 2, topic 3",
      type: "text",
      required: true,
      colspan: 1,
    },
    {
      name: "subTopics",
      label: "Sub-topics",
      placeholder: "eg. sub topic 1, sub topic 2, sub topic 3",
      type: "text",
      required: true,
      colspan: 1,
    },

    {
      name: "questionName",
      label: "Question Name",
      placeholder: "Enter Question Name",
      type: "text",
      required: true,
      colspan: 2,
    },
    {
      name: "statement",
      label: "Question Statement",
      placeholder: "Enter Question Statement",
      type: "richText",
      setOutput: setStatement,
      toolbarOptions: [
        "bold",
        "italic",
        "underline",
        "sub_script",
        "super_script",
        "numbered_list",
        "bulleted_list",
        "image_base64",
        "format_media",
      ],
      required: true,
      colspan: 2,
    },
    {
      name: "optionA",
      label: "Option A",
      placeholder: "Option A",
      type: "richText",
      setOutput: setOptionA,
      toolbarOptions: ["image_base64", "format_media"],
      required: true,
      colspan: 1,
    },
    {
      name: "optionB",
      label: "Option B",
      placeholder: "Option B",
      type: "richText",
      setOutput: setOptionB,
      toolbarOptions: ["image_base64", "format_media"],
      required: true,
      colspan: 1,
    },
    {
      name: "optionC",
      label: "Option C",
      placeholder: "Option C",
      type: "richText",
      setOutput: setOptionC,
      toolbarOptions: ["image_base64", "format_media"],
      required: true,
      colspan: 1,
    },
    {
      name: "optionD",
      label: "Option D",
      placeholder: "Option D",
      type: "richText",
      setOutput: setOptionD,
      toolbarOptions: ["image_base64", "format_media"],
      required: true,
      colspan: 1,
    },
    {
      name: "difficulty",
      label: "Difficulty Level",
      placeholder: "Select Difficulty Level",
      type: "select",
      required: true,
      options: [
        { id: 1, label: `Easy`, value: "easy" },
        { id: 2, label: `Medium`, value: "medium" },
        { id: 3, label: `Hard`, value: "hard" },
      ],
      colspan: 1,
    },
    {
      name: "correct",
      label: "Correct Option",
      placeholder: "Select Correct Option",
      type: "select",
      required: true,
      options: [
        { id: 1, label: `Option A`, value: 1 },
        { id: 2, label: `Option B`, value: 2 },
        { id: 3, label: `Option C`, value: 3 },
        { id: 4, label: `Option D`, value: 4 },
      ],
      colspan: 1,
    },
    {
      name: "answerExplanation",
      label: "Answer Explanation",
      placeholder: "Enter Answer Explanation",
      type: "richText",
      setOutput: setAnswerExplanation,
      toolbarOptions: [
        "bold",
        "italic",
        "underline",
        "sub_script",
        "super_script",
        "numbered_list",
        "bulleted_list",
        "image_base64",
        "format_media",
      ],
      required: true,
      colspan: 2,
    },
  ];

  const onSubmit = async (e) => {
    e.preventDefault();
    const formData = e.target;
    setLoading(true);
    const questionData = {
      className: Number(formData.class?.value),
      subject: formData.subject?.value,
      topics: formData.topics?.value.split(",").map((topic) => topic.trim()),
      subtopics: formData.subTopics?.value
        .split(",")
        .map((subtopic) => subtopic.trim()),
      difficulty: formData.difficulty?.value,
      questionName: formData.questionName?.value,
      statement: statement,
      options: [optionA, optionB, optionC, optionD],
      correct: formData.correct?.value,
      answerExplanation: answerExplanation,
      duration: Number(formData.duration?.value),
      isDeleted: false,
      // createdBy: "TeacherName",
      createdAt: Timestamp.now(),
      updatedAt: Timestamp.now(),
    };

    try {
      console.log(questionData);
      const uploadRef = await addDoc(questionsCollectionRef, questionData);

      // get questionData from localStorage
      let localStorageData =
        JSON.parse(localStorage.getItem("addedQuestionIds")) || [];

      // add question to localStorage
      localStorage.setItem(
        "addedQuestionIds",
        JSON.stringify([...localStorageData, uploadRef.id])
      );
      navigate("/contentCreation/questions");
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="w-full mx-auto h-full bg-white rounded-2xl p-6 px-10 border-2 border-gray-300 ">
      <h2 className="text-2xl font-bold py-2">
        {id ? "Edit Question" : "Add Qusetion"}
      </h2>

      <form
        className="mt-2 grid lg:grid-cols-2 gap-4"
        onSubmit={onSubmit}
        ref={formRef}
      >
        {fields.map((field) => (
          <div
            className={field.colspan && `col-span-${field.colspan}`}
            key={field.name}
          >
            <label htmlFor={field.name} className="font-bold">
              {field.label}
              {field.required && <sup>*</sup>}
            </label>
            {field.type === "select" ? (
              <SelectInput
                placeholderText={field.placeholder}
                selectOptions={field.options}
                isRequired={field.required}
                inputName={field.name}
              />
            ) : field.type === "richText" ? (
              <RichTextInput
                toolbarOptions={field.toolbarOptions}
                setOut={field.setOutput}
                defaultValue={statement}
              />
            ) : (
              <TextInput
                type={field.type}
                placeholderText={field.placeholder}
                inputName={field.name}
                isRequired={field.required}
              />
            )}
          </div>
        ))}

        <div className="col-span-2">
          {/* {error && <div className="text-red-500 mb-2">{error}</div>} */}
          <div className="flex gap-6 items-center justify-end">
            {" "}
            <button
              type="button"
              className="btn-gray"
              onClick={() => navigate("/contentCreation/questions")}
            >
              Cancel
            </button>
            <button type="button" className="btn-gray" onClick={openPreview}>
              Preview
            </button>
            <Button
              loading={loading}
              text={id ? "Update" : "Upload"}
              version="secondary"
              type="submit"
            />
          </div>
        </div>
      </form>

      {showPreview && (
        <div className="fixed top-0 left-0 w-full h-full bg-black/20 backdrop-blur-[3px] z-[999] flex items-center justify-center p-10">
          <Preview
            formData={previewData}
            statement={statement}
            answerExplanation={answerExplanation}
            options={[optionA, optionB, optionC, optionD]}
            setShowPreview={setShowPreview}
          />
        </div>
      )}
    </div>
  );
};

export default UploadQuestion;
