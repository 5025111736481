import React, { useEffect, useRef, useState } from "react";
import SchoolLogo from "./SchoolLogo";
import { doc, getDoc, getDocs, query, where } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import {
  auth,
  db,
  participantsCollectionRef,
  schoolCollectionRef,
} from "../../../config/firebaseConfig";
import { onAuthStateChanged } from "firebase/auth";
import { Button, TextInput } from "../../../components";
import StudentsTable from "./StudentsTable";

const RegisteredStudents = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(null);
  const [error, setError] = useState(null);
  const [schoolDetails, setSchoolDetails] = useState(null);
  const [participants, setParticipants] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    const fetchSchoolDetails = async () => {
      setLoading((prev) => ({ ...prev, schoolData: true }));
      const uid = auth.currentUser.uid;
      const q = query(schoolCollectionRef, where("uid", "==", uid));

      try {
        const querySnapshot = await getDocs(q);
        if (querySnapshot.empty) {
          return navigate("/login");
        }
        querySnapshot.forEach((doc) => {
          setSchoolDetails({ ...doc.data(), id: doc.id });
        });
        setLoading((prev) => ({ ...prev, schoolData: false }));
      } catch (error) {
        console.log(error);
      }
    };

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        fetchSchoolDetails();
      } else {
        navigate("/login");
      }
    });

    return () => unsubscribe(); // Cleanup when component unmounts
  }, [navigate]);

  useEffect(() => {
    const fetchParticipants = async () => {
      let q = query(
        participantsCollectionRef,
        where("schoolDetails.schoolId", "==", schoolDetails?.id)
      );

      try {
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach(async (document) => {
          const parentId = document.data().parentDocId;
          const parentDocRef = doc(db, "parents", parentId);
          const parentDoc = await getDoc(parentDocRef);
          setParticipants((prev) => [
            ...prev,
            {
              id: document.id,
              ...document.data(),
              parent: parentDoc?.data(),
            },
          ]);
        });
      } catch (err) {
        console.log(err);
      }
    };

    schoolDetails && fetchParticipants();
  }, [schoolDetails]);

  return (
    <div className="registered-students-container container space-y-5">
      <div className="flex items-center justify-center p-4">
        <SchoolLogo schoolDetails={schoolDetails} />
      </div>
      <div className="flex justify-between items-md-center flex-column flex-md-row">
        <div>
          <h2 className="text-3xl font-bold">Registered Students</h2>
          <p className="text-base font-medium mb-2">
            Academic Year {"2024 - 25"}
          </p>
        </div>
        <div className="d-block d-md-flex items-center gap-2">
          <TextInput
            placeholderText="Search Students"
            inputName="search"
            type="search"
            // minWidth={"300px"}
            // inputRef={searchInputRef}
            handleChange={(e) => setSearchQuery(e.target.value)}
          />
          {/* <Button
            version={"secondary"}
            text={"Search"}
            handleClick={() => {
              setSearchQuery(searchInputRef.current.value);
            }}
          /> */}
        </div>
      </div>
      <div className="pb-10">
        {participants.length ? (
          <StudentsTable students={participants} searchQuery={searchQuery} />
        ) : (
          <div className="text-center my-10">
            <h1 className="text-3xl font-bold text-gray-500">
              No Participants Registered
            </h1>
          </div>
        )}
      </div>
    </div>
  );
};

export default RegisteredStudents;
