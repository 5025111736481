export const validateEmail = (email) => {
  const re = /\S+@\S+\.\S+/;
  return re.test(email);
};
export const validateName = (name) => {
  // name must be atleast 3 characters long
  const re = /^[a-zA-Z ]{3,}$/;
  return re.test(name);
};
export const validatePhone = (phoneNumber) => {
  const re = /^\d{10}$/;
  return re.test(phoneNumber);
};
export const validatePassword = (password) => {
  // Require at least 8 characters, one uppercase letter, one lowercase letter, and one digit
  const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{6,}$/;
  return re.test(password);
};
