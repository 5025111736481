import React from "react";
import { NavLink, Outlet } from "react-router-dom";
import Header from "../landingPage/Header";
import Footer from "../landingPage/Footer";

const ContentCreationLayout = () => {
  return (
    <div>
      {/* <Header /> */}
      <div className="w-full h-full">
        <div className="flex gap-3 p-4">
          <div className="h-full w-1/5 bg-white border-2 border-gray-300 rounded-xl p-4 ">
            <ul className="flex flex-col gap-4">
              {[
                { name: "questions", label: "Questions" },
                { name: "text", label: "Text Content" },
                { name: "document", label: "Document Content" },
                { name: "video", label: "Video Content" },
              ].map(({ name, label }) => (
                <li key={name} className="font-bold text-lg">
                  <NavLink
                    to={name}
                    className={({ isActive }) =>
                      isActive
                        ? "text-blue-500 hover:text-blue-600"
                        : "text-gray-600 hover:text-gray-700"
                    }
                  >
                    {label}
                  </NavLink>
                </li>
              ))}
            </ul>
          </div>
          <div className="w-4/5 h-full">
            <Outlet />
          </div>
        </div>
      </div>

      {/* <Footer /> */}
    </div>
  );
};

export default ContentCreationLayout;
